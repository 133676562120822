import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const useUserRolesStore = create(
  persist(
    (set) => ({
      roles: [],
      setUserRoles: (newUserRoles) => {
        set({ roles: newUserRoles });
      },
    }),
    {
      name: "@ada-user-roles",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default useUserRolesStore;
