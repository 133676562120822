import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;
`;

export const Column = styled.div`
  flex: 1;
  padding: 1rem;
`;

export const InfoText = styled.div`
  margin-bottom: 0.5rem;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;
`;

export const Image = styled.img`
  width: 100%;
  max-width: 200px;
  margin-top: 10px;
  cursor: pointer;
`;

export const HeatMapContainer = styled.div`
  margin-top: 10px;
  background-color: #f0f0f0;
  padding: 10px;
  text-align: center;
`;

export const ModalImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 90vh;
  cursor: pointer;
`;

