import styled, { keyframes } from "styled-components";

export const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;


export const LoadingModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Fundo escuro semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Garante que o modal fique acima de outros elementos */
`;

export const LoadingContent = styled.div`
  background: transparent;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
`;

export const Spinner = styled.div`
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #8086C0; /* Cor do spinner */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${spin} 1s linear infinite; /* Aplica a animação */
  margin: 0 auto 1rem;
`;

export const LoadingText = styled.p`
  margin: 0;
  font-size: 1rem;
  color: #FFF;
`;