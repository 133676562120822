import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import InstallPoints from "../pages/InstallPoints";
import InstallPointsPlus from "../pages/InstallPointsPlus";
import ManagerAlarm from "../pages/ManagerAlarm";
import CreateAlarm from "../pages/CreateAlarm";
import ManagerSector from "../pages/ManagerSector";
import CreateSector from "../pages/CreateSector";
import EditSector from "../pages/EditSector";
import EditAlarm from "../pages/EditAlarm";
import ChangePassword from "../pages/ChangePassword";
import MacroSectorManagement from "../pages/MacroSectorManagement";
import EditMacroSector from "../pages/EditMacroSector";
import CreateMacroSector from "../pages/CreateMacroSector";
import Intercept from "../pages/Intercept";
import ManagerAlarmPlus from "../pages/ManagerAlarmPlus";
import Unauthorized from "../pages/Unauthorized";
import ProtectedRoute from "../utils/ProtectedRoute";
import InstallPointsHeatMap from "../pages/InstallPointsHeatMap";
import DashboardPlus from "../pages/DashboardPlus";

const AppRoutes = () => (
    <Routes>
        <Route path="/intercept/:clientId" element={<Intercept />} />
        <Route path="/home" element={<Dashboard />} />
        <Route
            path="/home-plus"
            element={
                <ProtectedRoute>
                    <DashboardPlus />
                </ProtectedRoute>
            }
        />
        <Route path="/install-points" element={<InstallPoints />} />
        <Route
            path="/install-points-heatmap"
            element={
                <ProtectedRoute>
                    <InstallPointsHeatMap />
                </ProtectedRoute>
            } />
        <Route
            path="/install-points-plus"
            element={
                <ProtectedRoute>
                    <InstallPointsPlus />
                </ProtectedRoute>
            }
        />
        <Route path="/manager-alarm/:id?" element={<ManagerAlarm />} />
        <Route
            path="/manager-alarm-plus/:id?"
            element={
                <ProtectedRoute>
                    <ManagerAlarmPlus />
                </ProtectedRoute>
            }
        />
        <Route path="/create-alarm/:id" element={<CreateAlarm />} />
        <Route path="/manager-sector" element={<ManagerSector />} />
        <Route path="/create-sector/:id" element={<CreateSector />} />
        <Route path="/create-macro-sector/:id" element={<CreateMacroSector />} />
        <Route path="/edit-alarm" element={<EditAlarm />} />
        <Route path="/edit-sector" element={<EditSector />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/manager-macro-sector" element={<MacroSectorManagement />} />
        <Route path="/edit-macro-sector" element={<EditMacroSector />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
    </Routes>
);

export default AppRoutes;
