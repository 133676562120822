import React, { useEffect, useRef, useState, useMemo, memo } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import CalHeatMap from "cal-heatmap";
import moment from "moment";
import { subscribe } from "../../utils/events";
import "moment/locale/pt-br";
import "moment/locale/es";
import "moment/locale/en-gb";
import {
  Heatmap,
  ContainerLegend,
  BoxContainer,
  RedBox,
  GreenBox,
  Text,
  NavigationButtons,
  Button,
} from "./styles";

const RenderHeatMap = ({ dateOn, dateTo, value }) => {
  const intl = useIntl();
  const [isReload, setReload] = useState(false);
  const heatmapRef = useRef(null);
  const calRef = useRef(null);
  const heatmapId = `cal-heat-${Math.random().toString(36).substr(2, 9)}`;

  subscribe("emmit-heatMap", (a) => setReload(a));
  subscribe("up-heatMap-selected", (a) => setReload(a));

  // Calcular o primeiro dia do mês atual
  const currentMonthStart = moment().startOf("month").toDate();

  const startDate = new Date(dateOn);
  const endDate = new Date(dateTo);
  const diffTime = Math.abs(endDate - startDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  const formatDate = (date) => date.toISOString().split("T")[0];

  const noComunication = useMemo(() => {
    const result = {};
    for (let i = 0; i < diffDays; i++) {
      const currentDate = new Date(startDate);
      currentDate.setDate(startDate.getDate() + i);
      const formattedDate = formatDate(currentDate);
      const timeStamp = Math.floor(currentDate.getTime() / 1000).toString();
      result[timeStamp] = 0;
    }
    return result;
  }, [diffDays, startDate]);

  const formattedValue = useMemo(() => {
    const result = {};
    for (const [key, val] of Object.entries(value)) {
      const date = new Date(key);
      const timeStamp = Math.floor(date.getTime() / 1000).toString();
      result[timeStamp] = val;
    }
    return result;
  }, [value]);

  const final = useMemo(
    () => ({ ...noComunication, ...formattedValue }),
    [noComunication, formattedValue]
  );

  // Função para avançar 1 mês
  const handleNext = () => {
    if (calRef.current) {
      calRef.current.next(1); // Avança 1 mês
    }
  };

  // Função para retroceder 12 meses
  const handlePrevious = () => {
    if (calRef.current) {
      calRef.current.previous(1); // Retrocede 12 meses
    }
  };

  // Calcular o primeiro dia do mês anterior
  const previousMonthStart = moment()
    .subtract(1, "month")
    .startOf("month")
    .toDate();

  useEffect(() => {
    if (calRef.current) {
      calRef.current.destroy();
    }

    const cal = new CalHeatMap();
    calRef.current = cal;

    cal.init({
      subDomain: "x_day",
      legendMargin: [0, 10, 0, 0],
      legendVerticalPosition: "center",
      domainGutter: 10,
      domainMargin: 0,
      rowLimit: 10,
      legendOrientation: "vertical",
      label: { position: "top" },
      legendColors: { min: "#ff0000", max: "#6eff94", base: "#AEAEAD" },
      itemSelector: `#${heatmapId}`,
      domain: "month", // Domínio definido como "mês"
      cellSize: 15,
      range: 2,
      start: previousMonthStart, // Inicia no primeiro dia do mês anterior
      displayLegend: false,
      legend: [1],
      itemName: [
        intl.formatMessage({ id: "communication" }),
        intl.formatMessage({ id: "communication" }),
      ],
      subDomainTitleFormat: {
        empty: intl.formatMessage({ id: "message_empty" }),
        filled: `${intl.formatMessage({
          id: "there_was",
        })} {count} ${intl.formatMessage({
          id: "communication(s)_in",
        })} {date}`,
      },
      legendTitleFormat: {
        lower: `${intl.formatMessage({ id: "less_than" })} {min} {name}`,
        upper: `${intl.formatMessage({ id: "bigger_then" })} {max} {name}`,
      },
      data: final,
      domainLabelFormat: (date) => moment(date).format("MMMM").toUpperCase(),
      subDomainDateFormat: (date) => moment(date).format("LL"),
    });

    return () => {
      if (calRef.current) {
        calRef.current.destroy();
      }
    };
  }, [isReload, dateOn, dateTo, heatmapId, intl, final, currentMonthStart]);

  return (
    <>
      <NavigationButtons>
        <Button onClick={handlePrevious}>{"<"}</Button>
        <Button onClick={handleNext}>{">"}</Button>
      </NavigationButtons>
      <Heatmap id={heatmapId} ref={heatmapRef} />
      <ContainerLegend>
        <BoxContainer>
          <RedBox />
          <Text>
            <FormattedMessage id="not_communicate" />
          </Text>
        </BoxContainer>
        <BoxContainer>
          <GreenBox />
          <Text>
            <FormattedMessage id="communicated" />
          </Text>
        </BoxContainer>
      </ContainerLegend>
    </>
  );
};

export default memo(RenderHeatMap);
