import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ScrollContainer, EmptyMessage } from './styles';

/**
 * @fileoverview InfiniteScroll component for efficiently loading and displaying large lists of data.
 * @module InfiniteScroll
 */

/**
 * @typedef {Object} InfiniteScrollProps
 * @property {Array} items - Array of items to be rendered in the scroll container
 * @property {Function} renderItem - Function that renders each individual item (receives item and index as params)
 * @property {Function} fetchMore - Async function called when more items need to be loaded
 * @property {boolean} hasMore - Indicates if there are more items to be loaded
 * @property {boolean} loading - Indicates if items are currently being loaded
 * @property {string} [height] - CSS height value for the scroll container
 * @property {string} [width] - CSS width value for the scroll container
 * @property {number} [threshold=100] - Pixel threshold from bottom that triggers loading more items
 * @property {React.ReactNode} [loadingComponent] - Custom component to display during loading state
 * @property {React.ReactNode} [emptyComponent] - Custom component to display when there are no items
 * @property {string} [className] - Additional CSS class for the scroll container
 * @property {Object} [style] - Additional inline styles for the scroll container
 */

/**
 * InfiniteScroll component that automatically loads more items when the user scrolls near the bottom.
 * Handles different states like loading, empty list, and normal scrolling with appropriate UI.
 * 
 * @param {InfiniteScrollProps} props - Component properties
 * @returns {React.ReactElement} Rendered component
 */
const InfiniteScroll = ({
  items,
  renderItem,
  fetchMore,
  hasMore,
  loading,
  height,
  width,
  threshold = 100,
  loadingComponent,
  emptyComponent,
  className,
  style
}) => {
  const containerRef = useRef(null);
  const [isFetching, setIsFetching] = useState(false);

  /**
   * Sets up scroll event listener to detect when user has scrolled near the bottom
   * and triggers loading of more items when appropriate.
   */
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    /**
     * Handles scroll events and triggers loading of more items when near bottom
     * @async
     */
    const handleScroll = async () => {
      if (isFetching || !hasMore || loading) return;

      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollHeight - scrollTop - clientHeight < threshold) {
        setIsFetching(true);
        await fetchMore();
        setIsFetching(false);
      }
    };

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [isFetching, hasMore, loading, fetchMore, threshold]);


  if (loading && (!items || items.length === 0)) {
    return loadingComponent || (
      <ScrollContainer 
        height={height} 
        width={width} 
        className={className}
        style={style}
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          Loading...
        </div>
      </ScrollContainer>
    );
  }


  if (!items?.length && !loading) {
    return emptyComponent || (
      <EmptyMessage>
        No elements to show
      </EmptyMessage>
    );
  }


  return (
    <ScrollContainer 
      ref={containerRef} 
      height={height} 
      width={width} 
      className={className}
      style={style}
    >
      {items.map((item, index) => renderItem(item, index))}
      
      {(loading || isFetching) && loadingComponent}
    </ScrollContainer>
  );
};

InfiniteScroll.propTypes = {
  items: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  fetchMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
  threshold: PropTypes.number,
  loadingComponent: PropTypes.node,
  emptyComponent: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object
};

export default InfiniteScroll; 