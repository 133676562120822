import React, { useState, useEffect, useRef, memo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { GoogleMap, Marker, Polyline, MarkerClusterer, OverlayView, DrawingManagerF } from '@react-google-maps/api';
import { Delaunay } from 'd3-delaunay';
import * as turf from '@turf/turf';
import * as clipper from 'polygon-clipping';
import { getDatetimeFromTimestamp } from "../../../utils/Utils";
import useSliderStore from '../../../store/useSliderStore';
import CreateAlarmModal from '../../ManagerAlarms/CreateAlarmModal';
import { MdOutlineDraw, MdOutlineBackHand } from "react-icons/md";
import { FaEraser } from "react-icons/fa";

import ButtonGroup from '../../ButtonGroup';
import ColorScale from '../../ColorScale';
import Legend from '../../Legend';
import ButtonCapture from '../../ButtonCapture';
import CommandBar from '../../CommandBar';
import SliderComponent from '../../SliderComponentAdaPlus';
import InfoWindows from '../../infoWindow';
import FilterDeviceMap from '../../FilterDeviceMap';

import pinConnGreen from '../../../assets/img/pins/pinConnGreen.png';
import pinConnYellow from '../../../assets/img/pins/pinConnYellow.png';
import pinConnRed from '../../../assets/img/pins/pinConnRed.png';

import { mapStyle, mapStyleFeira } from '../../../utils/MapStyles';
import useCompanyStore from '../../../store/useCompanyStore';
import useClientSelectedStore from '../../../store/map/useClientSelectedStore';
import { generateRandomDate, generateSites } from '../../../services/apiDataFake';
import useUserRolesStore from '../../../store/useUserRolesStore';
import { ButtonDraw } from './styles';

import { Toast } from 'primereact/toast';

import macroOnPurpple from '../../../assets/img/icons/macroOnPurpple.png';
import useDateSelectedStore from '../../../store/map/useDateSelectedStore';

const colorsIsolines = [
  'rgb(124, 0, 152)',
  'rgb(170, 0, 0)',
  'rgb(234, 47, 58)',
  'rgb(196, 87, 23)',
  'rgb(255, 140, 0)',
  'rgb(255, 189, 14)',
  'rgb(255, 255, 0)',
  'rgb(155, 165, 0)',
  'rgba(25, 150, 100, 194)',
  'rgb(0, 255, 0)'
];

const TimelapseMap = ({ sliderFrame, period, locations, isLoading, onDecrementDate, onIncrementDate, devices, traces, selectedSensor, onSelectSensor, onSelectSector, layers, info, userType, minMaxValueScale, geoLocation, isMaster, adaPlus, onGetDraw, onSliderDate, isTuring, filteredDates, flagReq }) => {

  const intl = useIntl();
  const { dateSelected } = useDateSelectedStore();

  const { roles } = useUserRolesStore();
  const { currentSlider, setCurrentSlider } = useSliderStore();
  const FRAME_LAT_LNG = 0.0025;
  const [map, setMap] = useState(null);
  const { tradingName } = useCompanyStore();
  const [kindPin, setKindPin] = useState("value");
  const [connectivity, setConnectivity] = useState(false);
  const [isolinhas, setIsolinhas] = useState(true);
  const [slider, setSlider] = useState(0);
  const [frames, setFrames] = useState({});
  const [x, setX] = useState([]);
  const [delta, setDelta] = useState(false);
  const [customMarkerIcon, setCustomMarkerIcon] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [defaultCenter, setDefaultCenter] = useState({ lat: 0, lng: 0 });
  const [defaultZoom, setDefaultZoom] = useState(2);
  const polygonsRef = useRef([]);
  const [buttonGroup, setButtonGroup] = useState(false);
  const { clientSelected } = useClientSelectedStore();
  const [showTraces, setShowTraces] = useState(false);
  const [showStreet, setShowStreet] = useState(null);
  const [maxValueScaleData, setMaxValueScaleData] = useState(null);
  const [minValueScaleData, setMinValueScaleData] = useState(null);
  const [idDeviceChosen, setIdDeviceChosen] = useState(null);
  const [showCreateAlarmModal, setShowCreateAlarmModal] = useState(false);
  const [polygons, setPolygons] = useState([]);
  const [fakeDevices, setFakeDevices] = useState([]);
  const [drawingMode, setDrawingMode] = useState(null);
  const [polygon, setPolygon] = useState(null);
  const drawingManagerRef = useRef(null);
  const toast = useRef(null);

  const [currentZoom, setCurrentZoom] = useState(2);

  useEffect(() => {
    if (filteredDates && filteredDates[0] && filteredDates[1] && flagReq) {
      const startDate = new Date(filteredDates[0]);
      const endDate = new Date(filteredDates[1]);
      const dates = [];

      for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
        dates.push(date.toDateString());
      }
      setX(dates);
    }
  }, [filteredDates]);

  useEffect(() => {
    if (locations && Object.keys(locations).length > 0) {
      buildFrame(locations, period);
    }
  }, [locations, period]);

  useEffect(() => {
    const tradingNameCheck = tradingName.includes('Demonstração') || tradingName.includes('Comercial');
    const selectedMapStyle = tradingNameCheck ? mapStyleFeira : mapStyle;
    const mapOptions = {
      zoomControl: false,
      styles: selectedMapStyle,
      fullscreenControl: true,
      scaleControl: true,
      scaleControlOptions: {
        position: window.google.maps.ControlPosition.BOTTOM_CENTER
      },
    };
    setShowStreet(mapOptions);

  }, [locations, period, info]);

  useEffect(() => {
    setShowTraces(true);
  }, [traces])

  useEffect(() => {
    setTimeout(() => {
      setIsolinhas(!isTuring);
    }, 100);
  }, [isTuring]);

  useEffect(() => {
    if (x && x.length) {
      setSlider(x.length - sliderFrame);
    }
  }, [x, sliderFrame]);

  useEffect(() => {
    // upgradeMap();
    if (!isMaster[0]) {
      upgradeMap();
    };
  }, [slider, frames, delta, isolinhas, maxValueScaleData]);

  useEffect(() => {
    setFakeDevices(generateSites());
  }, [devices]);

  useEffect(() => {
    if (!isMaster[0]) {
      fitBounds(map);
      if (map) {
        map.setZoom(currentZoom);
      }
    }
  }, [customMarkerIcon, currentZoom]);

  useEffect(() => {
    if (buttonGroup) {
      if (x) {
        setCurrentSlider(
          {
            value: x[slider],
            position: slider,
            region: info?.name,
            show: true,
            flag: true
          }
        )
      }
    }
    if (!buttonGroup) {
      if (x) {
        setCurrentSlider(
          {
            value: '',
            position: slider,
            region: info?.name,
            show: false,
            flag: false,
          }
        )
      }
    }
  }, [slider, buttonGroup]);

  useEffect(() => {
    if (currentSlider && currentSlider.show) {
      handleButtonGroup();
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [slider]);

  const buildFrame = (locations, date) => {
    const newFrame = {};
    const dateStr = date.toDateString();

    Object.values(locations).forEach(location => {
      if (!newFrame[dateStr]) {
        newFrame[dateStr] = [];
      }
      newFrame[dateStr].push({
        lat: location.lat,
        lon: location.lon,
        id: location.deviceId,
        device: location.deviceId,
        install_point: location.installPointName,
        pressure: location.pressAvg,
        h_load: location.mvn,
        h_load_diff: location.delta,
        color: '#000000', // Defina a cor conforme necessário
        color_delta: '#000000', // Defina a cor delta conforme necessário
      });
    });
    setFrames(newFrame);
  };



  useEffect(() => {
    if (isMaster && isMaster[0]) {
      setCustomMarkerIcon(null);
      const avgLat = calculateAverageCoordinate(isMaster[1], 'lat');
      const avgLng = calculateAverageCoordinate(isMaster[1], 'long');

      setDefaultCenter({ lat: avgLat, lng: avgLng });

      const maxLatDiff = calculateCoordinateDiff(isMaster[1], 'lat');
      const maxLngDiff = calculateCoordinateDiff(isMaster[1], 'long');

      setDefaultZoom(calculateZoom(maxLatDiff, maxLngDiff) + 1);

      const coordinatesArray = isMaster[1].map(coordsArray => {
        if (Array.isArray(coordsArray)) {
          return coordsArray.map(coords => ({ lat: coords.lat, lng: coords.long }));
        } else {
          return { lat: coordsArray.lat, lng: coordsArray.long };
        }
      });
      dropLayers();

      polygonMap(coordinatesArray, '#007700', 0, false, '');
      if (geoLocation) {
        processGeoLocationMacro(geoLocation, polygonMap);
      }
    }
  }, [isMaster, geoLocation]);

  useEffect(() => {
    if (drawingMode && !drawingManagerRef.current) {
      const drawingManager = new window.google.maps.drawing.DrawingManager({
        drawingMode: drawingMode === 'polygon' ? window.google.maps.drawing.OverlayType.POLYGON : null,
        drawingControl: false,
        polygonOptions: {
          fillColor: '#FF0000',
          fillOpacity: 0.5,
          strokeWeight: 2,
          strokeColor: '#FF0000',
          clickable: true,
          editable: true,
          zIndex: 1,
        },
      });

      drawingManager.setMap(map);
      drawingManagerRef.current = drawingManager;

      window.google.maps.event.addListener(drawingManager, 'polygoncomplete', handlePolygonComplete);
    }

    return () => {
      if (drawingManagerRef.current) {
        drawingManagerRef.current.setMap(null);
        drawingManagerRef.current = null;
      }
    };
  }, [drawingMode, map]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape' || event.keyCode === 27) {
        setDrawingMode(null);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  /*   useEffect(() => {
      if (x && x[slider]) {
        onSliderDate(x[slider]);
      }
    }, [slider, x]); */

  const clearPolygons = useCallback(() => {
    polygonsRef.current.forEach((polygon) => {
      if (polygon) polygon.setMap(null);
    });
    polygonsRef.current = [];
    setPolygons([]);
  }, []);

  const polygonMap = (data, value, index, fill, id) => {
    try {
      const polygon = new window.google.maps.Polygon({
        paths: data,
        strokeColor: value,
        strokeOpacity: 0.5,
        strokeWeight: 1,
        fillColor: value,
        fillOpacity: fill ? 0.2 : 0.1,
        zIndex: index,
      });
      polygon.id = id;
      polygon.addListener('click', () => {
        onSelectSector(polygon.id);
      });

      polygon.setMap(map);
      polygonsRef.current.push(polygon);

      setPolygons(current => {
        const newPolygons = current.filter(p => p.id !== id);
        return [...newPolygons, { id, data, value, fill }];
      });
    } catch (error) {
      console.error("Error creating polygon:", error);
    }
  };

  const calculateAverageCoordinate = (data, type) => {
    if (!Array.isArray(data[0])) {
      return data.reduce((acc, coord) => acc + coord[type], 0) / data.length;
    }

    const total = data.reduce((acc, coordinates) => {
      const sum = coordinates.reduce((sumCoord, coord) => sumCoord + coord[type], 0);
      return acc + sum / coordinates.length;
    }, 0);

    return total / data.length;
  };

  const calculateCoordinateDiff = (data, type) => {
    if (!Array.isArray(data[0])) {
      const maxCoord = Math.max(...data.map(coord => coord[type]));
      const minCoord = Math.min(...data.map(coord => coord[type]));
      return maxCoord - minCoord;
    }

    const diffArray = data.map(coordinates => {
      const maxCoord = Math.max(...coordinates.map(coord => coord[type]));
      const minCoord = Math.min(...coordinates.map(coord => coord[type]));
      return maxCoord - minCoord;
    });

    return diffArray;
  };

  function calculateZoom(maxDiffArrayLng, maxDiffArrayLat) {
    function calculateZoomForArray(maxDiffArray) {
      return Math.min(
        ...maxDiffArray.map(diff => Math.floor(Math.log2(360 / diff)))
      );
    }

    if (Array.isArray(maxDiffArrayLng) && Array.isArray(maxDiffArrayLat)) {
      const zoomLng = calculateZoomForArray(maxDiffArrayLng);
      const zoomLat = calculateZoomForArray(maxDiffArrayLat);

      return Math.min(zoomLng, zoomLat);
    } else {
      const zoomLng = Math.floor(Math.log2(360 / maxDiffArrayLng));
      const zoomLat = Math.floor(Math.log2(180 / maxDiffArrayLat));

      return Math.min(zoomLng, zoomLat);
    }
  }

  const processGeoLocationMacro = (geoLocation, polygonMap) => {
    const result = [];
    let actualColorIndex = 0;

    geoLocation.forEach((innerArray, outerIndex) => {
      if (Array.isArray(innerArray)) {
        const mappedArray = innerArray.map(point => ({
          outerIndex,
          lat: point.lat,
          lng: point.long,
          sectorId: point.sectorId
        }));
        result.push(mappedArray);
        result.forEach((a) => {
          const actualColor = '#6A5ACD';
          const index = a.map(item => item.outerIndex + 1)[0] || a.outerIndex;
          const id = a.map(item => item.sectorId);
          polygonMap(a, actualColor, index, true, id[0]);
        });
      }
    });
  };

  const handleKeyDown = (event) => {
    if (event.code === 'ArrowLeft' && slider > 0) {
      handleDecrement();
    } else if (event.code === 'ArrowRight' && x && slider < x.length - 1) {
      handleIncrement();
    }
  };


  const handleButtonClick = useCallback(() => {
    setShowCreateAlarmModal(true);
  }, []);

  const handleButtonGroup = () => {
    setButtonGroup(prevButtonGroup => !prevButtonGroup);
    if (x && !buttonGroup) {
      setCurrentSlider({
        value: x[slider],
        position: slider,
        region: info?.name,
        show: true,
        flag: true,
      })
    } else {
      setCurrentSlider(
        {
          value: '',
          position: slider,
          region: info?.name,
          show: false,
          flag: false,
        }
      )
    }
  };

  const handleIncrement = () => {
    const newDate = new Date(period);
    newDate.setDate(newDate.getDate() + 1);
    onSliderDate(newDate);
  };

  const handleDecrement = () => {
    const newDate = new Date(period);
    newDate.setDate(newDate.getDate() - 1);
    onSliderDate(newDate);
  };


  const upgradeMap = () => {
    if (!window?.google?.maps || Object.keys(frames).length === 0) {
      return;
    }

    if (isolinhas) {
      renderMap(map, frames[x[slider]], layers, slider);
    } else {
      dropLayers();
    }

    if (minMaxValueScale) {
      const validId = findValidDeviceId(frames[x[slider]], minMaxValueScale);
      setIdDeviceChosen(validId);
      setMaxValueScaleData(getMaxValueScaleData(frames[x[slider]], validId));
      setMinValueScaleData(getMinValueScaleData(devices));
    }

    renderMarkers(frames[x[slider]], devices);
  };

  const isMvnValid = (device) => {
    return device.h_load !== null && device.h_load !== undefined && !isNaN(device.h_load);
  }

  const findValidDeviceId = (devices, minMaxValueScale) => {
    const { DeviceMainId, DeviceSecondaryId, DeviceTertiaryId } = minMaxValueScale;
    const value = [DeviceMainId, DeviceSecondaryId, DeviceTertiaryId];
    for (const id of value) {
      const device = devices?.find(device => device.id === id);
      if (device && isMvnValid(device)) {
        return id;
      }
    }
    return null;
  };

  const getMaxValueScaleData = (devices, validId) => {
    const mainDevice = devices?.find(device => device.id === validId);
    return mainDevice && mainDevice.h_load ? Math.round(mainDevice.h_load) : null;
  };

  const getMinValueScaleData = (devices) => {
    let minorElevation = Infinity;
    for (const deviceId in devices) {
      const device = devices[deviceId];
      if (device.elevation && device.elevation < minorElevation) {
        minorElevation = device.elevation;
      }
    }
    return Math.round(minorElevation);
  };

  const fitBounds = (map) => {
    if (window?.google?.maps && map) {
      const bounds = new window.google.maps.LatLngBounds();
      if (customMarkerIcon != null) {
        customMarkerIcon.forEach((marker) => {
          if (!isNaN(marker.lat) && !isNaN(marker.lng) && marker.install_point !== 'Comissonamento inativo') {
            bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng));
          }
        });
        map.fitBounds(bounds);
        const newCenter = map.getCenter();
        if (x && x.length > 0) {
          if (!isNaN(newCenter.lat()) && !isNaN(newCenter.lng())) {
            setDefaultCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
            setDefaultZoom(map.getZoom());
          }
        } else if (x && x.length === 0) {
          setDefaultCenter({ lat: -15.685068956906802, lng: -47.94552127288126 });
          setDefaultZoom(map.getZoom());
        }
      }
    }
  };

  const dropLayers = () => {

    if (isMaster.length === 0 || !isMaster[0] || !Array.isArray(isMaster[1][0])) {
      if (map) {
        map.data.forEach(function (feature) {
          map.data.remove(feature);
        });
      }
    }


    polygonsRef.current.forEach((polygon) => {
      polygon.setMap(null);
    });
    polygonsRef.current = [];
  }

  function getColor(value) {
    const r = value > 0.5 ? 255 : Math.round(510 * value);
    const g = value < 0.5 ? 255 : Math.round(510 * (1 - value));
    const b = 0;

    const safeR = isNaN(r) ? 0 : r;
    const safeG = isNaN(g) ? 0 : g;

    return `rgb(${safeR}, ${safeG}, ${b})`;
  }

  function getMinMax() {

    let minVal = null;
    let maxVal = null;

    if (minValueScaleData && maxValueScaleData) {
      if (delta) {
        // return { minVal: -10, maxVal: 10 };
        return { minVal: minValueScaleData, maxVal: maxValueScaleData };
      } else {
        return { minVal: minValueScaleData, maxVal: maxValueScaleData };
      }
    }

    return { minVal, maxVal };
  }

  function value2color(value) {
    const hidroLoad = Math.round(value);
    const { minVal, maxVal } = getMinMax();

    if (isNaN(hidroLoad) || isTuring) {
      return "rgb(0, 0, 0)";
    }

    if (maxVal && hidroLoad > maxVal) {
      return "rgb(0, 150, 255)";
    }

    const percentage = (hidroLoad - minVal) / (maxVal - minVal) * 100;

    let index = Math.round(percentage / 10);

    if (percentage < 0) {
      index = 0;
    } else if (index > 0) {
      index -= 1;
    }

    return colorsIsolines[index];
  }


  function value2colorDelta(value) {
    const maxVal = 10;
    const minVal = -10;
    const normalizedValue = Math.log(((Math.abs((maxVal - value)) / (maxVal - minVal)) * 9) + 1) / Math.log(10);
    return getColor(normalizedValue);
  };

  function handleMarkerClick(marker) {
    setSelectedMarker(marker);
  }

  function renderMarkers(positions, devices) {
    var icons = [];
    for (let i in positions) {
      const id = positions[i].id;
      if (devices[id] && devices.installPointName !== 'Comissonamento inativo') {
        const icon = {
          id: id,
          lat: devices[id].lat,
          lng: devices[id].lon,
          device: devices[id].deviceId,
          install_point: devices[id].installPointName,
          pressure: positions[i].pressure == null ? '.' : positions[i]?.pressure,
          h_load: typeof positions[i].h_load !== 'number' ? '.' : positions[i].h_load.toFixed(2),
          h_load_diff: typeof positions[i].h_load_diff !== 'number' ? '.' : positions[i].h_load_diff.toFixed(2),
          scaledSize: new window.google.maps.Size(40, 40),
          elevation: devices[id].elevation,
          last_comm: devices[id].last_comm,
          last_comm_status: devices[id].last_comm_status,
          type: devices[id].type,
        };
        icons.push(icon);
      }
    }
    setCustomMarkerIcon(icons);
  }

  const removerDispositivosInativos = (dispositivos) => {
    if (dispositivos) {
      const dispositivosFiltrados = dispositivos.filter(
        (dispositivo) => dispositivo.install_point !== "Comissonamento inativo"
      );
      return dispositivosFiltrados;
    }
  }

  const renderMap = useCallback((map, points, layers, k) => {
    if (!map || !points) return;

    dropLayers();
    clearPolygons();

    const dispositivosAtivos = removerDispositivosInativos(points);
    if (layers) {
      renderIsobands(map, dispositivosAtivos, layers, k);
    } else {
      renderVoronoi(map, dispositivosAtivos);
    }
  }, [dropLayers, clearPolygons]);

  const renderVoronoi = (map, points) => {
    if (map == null || points == null) {
      console.error("Invalid map or points data.");
      return;
    }

    const [minLng, minLat, maxLng, maxLat] = points.reduce(
      ([minLng, minLat, maxLng, maxLat], { lat, lon }) => [
        Math.min(minLng, lon),
        Math.min(minLat, lat),
        Math.max(maxLng, lon),
        Math.max(maxLat, lat),
      ],
      [Infinity, Infinity, -Infinity, -Infinity]
    );

    points = points.filter(point => point.h_load != null)
    if (points == null)
      return;

    const delaunay = Delaunay.from(
      points.map((point) => [point.lat, point.lon])
    );

    if (minLat == Infinity || minLng == Infinity || maxLat == Infinity || maxLng == Infinity) {
      console.error("Invalid bounds for Voronoi generation.");
      return;
    }

    if (minLat == -Infinity || minLng == -Infinity || maxLat == -Infinity || maxLng == -Infinity) {
      console.error("Invalid bounds for Voronoi generation.");
      return;
    }

    const voronoi = delaunay.voronoi([minLat - FRAME_LAT_LNG, minLng - FRAME_LAT_LNG, maxLat + FRAME_LAT_LNG, maxLng + FRAME_LAT_LNG]);

    const svgPathToLatLngArray = (path) => {
      const coords = path?.replace(/M|Z/g, "")
        .split("L")
        .map((coord) => coord.split(",").map(Number));
      return coords?.map(([lat, lon]) => ({ lat, lng: lon }));
    };

    dropLayers();

    const polygonMap = (data, value, index) => {
      try {
        const polygon = new window.google.maps.Polygon({
          paths: data,
          strokeColor: value2color(value),
          strokeOpacity: 0.3,
          strokeWeight: 0.5,
          fillColor: value2color(value),
          fillOpacity: 0.3,
          zIndex: index,
        });

        polygon.setMap(map);
        polygonsRef.current.push(polygon);
        setPolygons(prev => [...prev, { data, value, index }]);
      } catch (error) {
        console.error("Error creating polygon:", error);
      }
    }

    if (geoLocation && geoLocation.length > 0) {

      let geoLocationAdjust = geoLocation;
      const pointsGeoJSON = turf.featureCollection(
        points.map((point, index) => turf.point([point.lon, point.lat], { id: index }))
      );

      const voronoiPolygons = turf.voronoi(pointsGeoJSON);

      const voronoiCoordinates = voronoiPolygons.features.map(feature =>
        feature.geometry.coordinates[0],
      );

      if (
        geoLocationAdjust.length > 1 &&
        geoLocationAdjust[0].lat !== geoLocationAdjust[geoLocationAdjust.length - 1].lat ||
        geoLocationAdjust[0].long !== geoLocationAdjust[geoLocationAdjust.length - 1].long
      ) {

        geoLocationAdjust.push({
          "lat": geoLocationAdjust[0].lat,
          "long": geoLocationAdjust[0].long
        });
      }

      const boundingBox = turf.polygon([
        geoLocationAdjust.map(coord => [coord.long, coord.lat]),
      ]);

      const clippedPolygons = voronoiCoordinates.map(coordinates =>
        clipper.intersection([coordinates], [boundingBox.geometry.coordinates])
      );

      if (clippedPolygons.length === 0 && points.length === 0) {
        const aux = geoLocationAdjust.map(coords => {
          return {
            lat: coords.lat,
            lng: coords.long
          }
        });
        polygonMap(aux, 200, 1)
      }

      clippedPolygons.forEach((feature, index) => {
        feature.forEach((polygon, polygonIndex) => {
          const coordinates = polygon[0]?.map(coordenada => ({
            lat: coordenada[1],
            lng: coordenada[0]
          }));

          try {
            if (coordinates && points[index]) {
              const hloadValue = points[index].h_load;
              if (coordinates.length >= 3) {
                polygonMap(coordinates, hloadValue, index);
              } else {
                console.warn("Polygon with less than 3 vertices discarded:", coordinates);
              }
            }
          } catch (error) {
            console.error("Error rendering Voronoi polygon:", error);
          }
        });
      });

    } else {
      points.forEach((point, index) => {
        try {
          const svgPath = voronoi.renderCell(index);
          const path = svgPathToLatLngArray(svgPath);
          polygonMap(path, point.h_load, index);
        } catch (error) {
          console.error("Error rendering Voronoi polygon:", error);
        }
      });
    }
    map.data.setStyle({});
  }

  const renderIsobands = (map, points, layers, k) => {
    if (map == null || points == null)
      return;

    dropLayers();

    const dt = getDatetimeFromTimestamp(x[k]);
    const month = dt.getMonth() + 1
    const dts = dt.getFullYear() + "-" + (month < 10 ? '0' + month : month) + "-" + (dt.getDate() < 10 ? '0' + dt.getDate() : dt.getDate());

    let map_layer = layers[dts]

    for (let layer in map_layer) {
      let features = map_layer[layer].source.features;

      for (let idx in features) {
        map.data.addGeoJson(features[idx]);
      }
    }

    map.data.setStyle((feature) => {
      let color = "gray";

      if (feature.getProperty("color")) {
        color = feature.getProperty("color");
      }
      return {
        fillColor: color,
        strokeColor: color,
        strokeWeight: 1,
      };
    });
  }

  const getConnectivityIcon = (lastCommDate) => {
    if (lastCommDate === '24h') return pinConnGreen;
    if (lastCommDate === '24_7d') return pinConnYellow;
    return pinConnRed;
  };

  const { minVal, maxVal } = getMinMax();

  useEffect(() => {
    return () => {
      if (polygon) {
        window.google.maps.event.clearInstanceListeners(polygon);
      }
    };
  }, [polygon]);

  const handlePolygonComplete = (polygon) => {
    setPolygon(polygon);
    const updatePolygon = () => {
      const polygonPath = polygon.getPath().getArray();

      const isPointInsidePolygon = (point, polygonPath) => {
        return window.google.maps.geometry.poly.containsLocation(point, new window.google.maps.Polygon({ paths: polygonPath }));
      };

      const pointsInsidePolygon = customMarkerIcon.filter((marker) => {
        const point = new window.google.maps.LatLng(marker.lat, marker.lng);
        return isPointInsidePolygon(point, polygonPath);
      });

      if (pointsInsidePolygon.length > 0) {
        const result = pointsInsidePolygon.reduce((acc, item) => {
          acc[item.id] = true;
          return acc;
        }, {});

        onGetDraw(result);
        setDrawingMode(null);
      }
    }

    polygon.getPath().addListener('set_at', updatePolygon);
    polygon.getPath().addListener('insert_at', updatePolygon);

    updatePolygon();
  };

  const handleClearDrawing = () => {
    // const data = localStorage.getItem('deviceDataInitial');

    if (polygon) {
      polygon.setMap(null);
      setPolygon(null);
    }
    setDrawingMode(null);

    onGetDraw([]);

    /* if (data) {
      const paternDevices = JSON.parse(data);
      if (paternDevices.length > 0) {
        const result = paternDevices.reduce((acc, curr) => {
          acc[curr] = true;
          return acc;
        }, {});
        onGetDraw(result);
      }
    } */
  };

  const toggleDrawingMode = () => {
    if (polygon) {
      toast.current.show({ severity: 'warn', summary: 'Info', detail: intl.formatMessage({ id: "alert_draw_map_double" }) });
      return;
    }
    setDrawingMode(drawingMode === 'polygon' ? null : 'polygon');
  };

  const onGetValueSlider = (value) => {
    // onGetValueSliderParent(value);
  };

  return (locations && <div id='teste123' style={{ backgroundColor: '#fff', height: '100%', overflow: 'hidden' }}>

    <CreateAlarmModal
      visible={showCreateAlarmModal}
      onHide={() => setShowCreateAlarmModal(false)}
      clientId={clientSelected?.id}
      defaultCenter={defaultCenter}
      showTraces={showTraces}
      customMarkerIcon={customMarkerIcon}
      kindPin={kindPin}
      idDeviceChosen={idDeviceChosen}
      polygonsPros={polygons}
      valueScaleColor={{ minVal: minVal, maxVal: maxVal }}
    />
    <Toast ref={toast} />
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: !connectivity && isMaster && !isMaster[0] ? '73vh' : '81vh' }}
      onLoad={(map) => setMap(map)}
      center={defaultCenter}
      zoom={defaultZoom}
      options={showStreet}
      onZoomChanged={() => {
        if (map) {
          const currentZoomMap = map.getZoom();
          setCurrentZoom(currentZoomMap);
        }
      }}
    >

      {roles.some((role) => role.ada_plus) && <div style={{ position: 'absolute', top: '10px', left: '50%', transform: 'translateX(-30%)', zIndex: 1, display: 'flex' }}>
        <ButtonDraw onClick={toggleDrawingMode}>
          {drawingMode === 'polygon' ? <MdOutlineBackHand /> : <MdOutlineDraw />}
        </ButtonDraw>

        <ButtonDraw onClick={handleClearDrawing}>
          <FaEraser />
        </ButtonDraw>
      </div>}


      {showTraces && traces && traces.map((line, index) => {
        return <Polyline
          key={index}
          path={[{ lat: line.x0, lng: line.y0 }, { lat: line.x1, lng: line.y1 }]}
          options={{
            strokeColor: "#006699",
            strokeOpacity: 1,
            strokeWeight: 1,
          }}
        />
      })}
      {/* {true && geoLocation && geoLocation.map((line, index) => {
        return <Polyline
          key={index}
          path={[{ lat: line.x0, lng: line.y0 }, { lat: line.x1, lng: line.y1 }]}
          options={{
            strokeColor: "#006699",
            strokeOpacity: 1,
            strokeWeight: 1,
          }}
        />
      })} */}
      {roles.some((role) => role.ada_plus) && customMarkerIcon != null && (
        <MarkerClusterer>
          {(clusterer) =>
            customMarkerIcon.map((e, idx) => {
              const iconUrl = connectivity
                ? getConnectivityIcon(e.last_comm_status)
                : e.type === 'macrometer'
                  ? macroOnPurpple
                  : (() => {
                    const canvas = document.createElement('canvas');
                    canvas.width = 100;
                    canvas.height = 100;
                    const ctx = canvas.getContext('2d');

                    if (!ctx) {
                      console.error("Contexto 2D não disponível.");
                      return null;
                    }

                    ctx.clearRect(0, 0, canvas.width, canvas.height);

                    const isSelectedSensor = selectedSensor == null || selectedSensor === e.device;
                    const isChosenDevice = e.device === idDeviceChosen;
                    const fillColor = isSelectedSensor
                      ? delta
                        ? value2colorDelta(e.h_load_diff)
                        : value2color(e.h_load)
                      : "#AAAAAA";

                    const isBlackColor = (color) => {
                      if (typeof color !== 'string') return true;
                      return (
                        color === "#000" ||
                        color === "black" ||
                        color.toLowerCase() === "rgb(0, 0, 0)"
                      );
                    };

                    ctx.fillStyle = fillColor;

                    if (kindPin === "alarm") {
                      ctx.fillRect(0, 0, canvas.width, canvas.height);
                    } else if (isChosenDevice && !isTuring) {
                      ctx.fillRect(10, 10, 80, 80);
                    } else {
                      ctx.beginPath();
                      ctx.arc(50, 50, 40, 0, 2 * Math.PI);
                      ctx.fill();
                    }

                    ctx.fillStyle = isBlackColor(fillColor) ? "#fff" : "#000";
                    ctx.fontWeight = '900';
                    ctx.font = '18px Arial';

                    ctx.fillText(e.id.slice(-4), 30, 30);

                    if (delta) {
                      ctx.fillText(e.h_load_diff !== '.' ? `D:${Math.round(e.h_load_diff)}` : '', 33, 60);
                    } else {
                      if (kindPin === "pressure") {
                        ctx.fillText(`${e.pressure !== '.' && !isNaN(e.pressure) ? `P:${Math.round(e.pressure)}` : ''}`, 30, 58);
                      } else {
                        ctx.fillText(`${e.h_load !== '.' ? `CH:${Math.round(e.h_load)}` : ''}`, 15, 55);
                        ctx.fillText(`${e.pressure !== '.' && !isNaN(e.pressure) ? `P:${Math.round(e.pressure)}` : ''}`, 30, 75);
                      }
                    }

                    return canvas.toDataURL();
                  })();

              if (!iconUrl) return null;

              return (
                <Marker
                  key={"mrk_bm_idx_" + idx}
                  position={{ lat: e.lat, lng: e.lng }}
                  icon={{
                    url: iconUrl,
                    scaledSize: new window.google.maps.Size(50, 50),
                    anchor: connectivity ? new window.google.maps.Point(12, 41) : undefined,
                  }}
                  onClick={() => handleMarkerClick(e)}
                  clusterer={!connectivity ? clusterer : undefined}
                >
                  {!connectivity && selectedMarker == e && (
                    <InfoWindows
                      e={e}
                      selectedMarker={selectedMarker}
                      setSelectedMarker={handleMarkerClick}
                      selectedSensor={selectedSensor}
                      onSelectSensor={onSelectSensor}
                    />
                  )}
                </Marker>
              );
            })
          }
        </MarkerClusterer>
      )}

      {
        x && x.length > 0 && isMaster && !isMaster[0] &&
        <>
          <ColorScale isolinhas={isolinhas} kindPin={kindPin} minVal={minVal} maxVal={maxVal} delta={delta} />
          <Legend kindPin={kindPin} connectivity={connectivity} />
        </>
      }
    </GoogleMap>
    {isMaster && !isMaster[0] && <FilterDeviceMap devices={devices} filteredSensor={onSelectSensor} selectedSensor={selectedSensor} />}
    {x && x.length > 0 && isMaster && !isMaster[0] && <CommandBar kindPin={kindPin} setKindPin={setKindPin} connectivity={connectivity} delta={delta} setDelta={setDelta} isolinhas={isolinhas} setIsolinhas={setIsolinhas} setConnectivity={setConnectivity} adaPlus={adaPlus} isTuring={isTuring} />}
    {!connectivity && isMaster && !isMaster[0] &&
      <div style={userType === "1" ? { display: 'flex', flexDirection: 'row', marginTop: adaPlus ? ' 4.5vh' : '2.2vh', justifyContent: 'space-around' } : { display: 'flex', flexDirection: 'row', marginTop: '4vh', flexWrap: 'nowrap', justifyContent: 'center' }}>
        <div className='col-sm-0'>
          {userType === "1" ? <ButtonCapture onButtonClick={handleButtonClick} /> :
            <div style={{ paddingBottom: 10, paddingTop: 10, borderRadius: 10, marginLeft: 10 }}></div>
          }
        </div>
        <div className='col-sm-9' style={{ alignSelf: 'center' }}>
          <SliderComponent
            x={x}
            slider={slider}
            setSlider={setSlider}
            decrement={onDecrementDate}
            increment={onIncrementDate}
            onGetValueSlider={onGetValueSlider}
          />
        </div>
        <div className='col-sm-0'>
          <ButtonGroup buttonGroup={buttonGroup} handleButtonGroup={handleButtonGroup} />
        </div>
      </div>
    }

  </div>
  );
};

export default memo(TimelapseMap);