import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Checkbox } from 'primereact/checkbox';
import useUserStore from '../../../store/useUserStore';
import {
    ModalContainer,
    SectionTitle,
    InfoRow,
    CheckboxRow,
    CheckboxLabel,
    ContentContainer,
    InfoColumn,
    ImageColumn,
    AlarmImage,
    InputTextareaMsg,
    ButtonMsg,
    InputContainer,
    ZoomedImage,
    Overlay,
    ObservationText,
    CustomDialog,
    ObservationsSection,
    ObservationsTitle,
    ObservationsContainer,
    ObservationHeader,
    ObservationContent,
    NoObservationsMessage,
    ObservationItem,
    DeviceContentContainer,
    DeviceInfoColumn,
    DeviceInfoRow,
    DeviceSectionTitle,
    DeviceTagsContainer,
    DeviceStyledChip,
    DeviceCheckboxRow,
    DeviceCheckboxLabel
} from './styles';

import { getDescriptionAlarms } from '../../../utils/descriptionAlarms';

const NotificationAlarmModal = ({ visible, onHide, selectedAlarm, onUpdateAlarm, saveObs }) => {
    const intl = useIntl();
    const { user } = useUserStore();
    const [loading, setLoading] = useState(false);
    const [newObservation, setNewObservation] = useState('');
    const [isZoomed, setIsZoomed] = useState(false);
    const [seen, setSeen] = useState(false);
    const [archived, setArchived] = useState(false);
    const [observations, setObservations] = useState([]);

    useEffect(() => {
        if (visible && selectedAlarm) {
            const seenValue = selectedAlarm.metadata?.seen === "true" ||
                selectedAlarm.seen === "true" ||
                selectedAlarm.metadata?.seen === true ||
                selectedAlarm.seen === true;

            const archivedValue = selectedAlarm.metadata?.removedByUser === "true" ||
                selectedAlarm.removedByUser === "true" ||
                selectedAlarm.metadata?.removedByUser === true ||
                selectedAlarm.removedByUser === true;


            setSeen(seenValue);
            setArchived(archivedValue);
        } else {
            setNewObservation('');
            setIsZoomed(false);
        }
    }, [visible, selectedAlarm]);

    useEffect(() => {
        if (visible && selectedAlarm) {
            const currentObservations = selectedAlarm.metadata.clientObs || [];
            setObservations([...currentObservations].reverse());
        }
    }, [visible, selectedAlarm]);

    const handleAddObservation = async () => {
        if (!newObservation.trim()) return;
        setLoading(true);

        try {
            const isSectorAlarm = selectedAlarm.type === 'alarm_sector' ||
                selectedAlarm.alarmType === 'sector' ||
                selectedAlarm.originalData?.type === 'alarm_sector';

            if (isSectorAlarm && saveObs) {


                const updatedAlarm = {
                    ...selectedAlarm,
                };

                const newObs = await saveObs(updatedAlarm, newObservation);

                setObservations(prevObservations => [newObs, ...prevObservations]);
                setNewObservation('');
            }
        } catch (error) {
            console.error("Error adding observation:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleSeenChange = async (checked) => {
        setSeen(checked);

        const updatedData = {
            ...selectedAlarm,
            seen: checked,
            metadata: {
                ...selectedAlarm.metadata,
                seen: checked.toString()
            },
            type: selectedAlarm.alarmType === 'device' ||
                selectedAlarm.type === 'alarm_device' ||
                selectedAlarm.originalData?.type === 'alarm_device'
                ? 'alarm_device'
                : 'alarm_sector'
        };

        await onUpdateAlarm(updatedData);
    };

    const handleArchivedChange = async (checked) => {
        setArchived(checked);

        const updatedData = {
            ...selectedAlarm,
            removedByUser: checked,
            metadata: {
                ...selectedAlarm.metadata,
                removedByUser: checked.toString()
            },
            type: selectedAlarm.alarmType === 'device' ||
                selectedAlarm.type === 'alarm_device' ||
                selectedAlarm.originalData?.type === 'alarm_device'
                ? 'alarm_device'
                : 'alarm_sector'
        };

        await onUpdateAlarm(updatedData);
    };

    const renderDeviceContent = () => {
        if (!selectedAlarm?.deviceData) {
            return <p><FormattedMessage id="no_data_available" /></p>;
        }

        const deviceData = selectedAlarm.deviceData;
        const address = `${deviceData.address}, ${deviceData.number}, ${deviceData.neighborhood}, ${deviceData.city} - ${deviceData.state}, ${deviceData.country}, ${deviceData.zipcode}`;
        return (
            <DeviceContentContainer>
                <DeviceInfoColumn>
                    <DeviceSectionTitle>
                        <FormattedMessage id="title_heat_map_sector" />: {selectedAlarm.metadata?.sectorName || selectedAlarm.sectorName || 'N/A'}
                    </DeviceSectionTitle>

                    <DeviceInfoRow>
                        <strong><FormattedMessage id="alarm" />:</strong>
                        {selectedAlarm.alarmTypeName}
                    </DeviceInfoRow>

                    <DeviceInfoRow>
                        <strong><FormattedMessage id="description" />:</strong>
                        {selectedAlarm.description}
                    </DeviceInfoRow>

                    <DeviceInfoRow>
                        <strong><FormattedMessage id="date_hour" />:</strong>
                        {new Date(selectedAlarm.createdAt).toLocaleString()}
                    </DeviceInfoRow>

                    <DeviceInfoRow>
                        <strong><FormattedMessage id="serie_number" />:</strong>
                        {selectedAlarm.deviceSerial || selectedAlarm.metadata?.dev_serial}
                    </DeviceInfoRow>

                    <DeviceInfoRow>
                        <strong><FormattedMessage id="install_point" />:</strong>
                        {deviceData.name}
                    </DeviceInfoRow>

                    <DeviceInfoRow>
                        <strong><FormattedMessage id="address" />:</strong>
                        {address}
                    </DeviceInfoRow>

                    {deviceData.tags && deviceData.tags.length > 0 && (
                        <DeviceInfoRow>
                            <strong>Tags:</strong>
                            <DeviceTagsContainer>
                                {deviceData.tags.map((tag, index) => {
                                    return (
                                        <DeviceStyledChip
                                            key={`${tag.id}-${index}`}
                                            $backgroundColor={tag.desc || '#007ad9'}
                                            $color={tag.desc === 'yellow' ? 'black' : 'white'}
                                        >{tag.name}</DeviceStyledChip>
                                    )
                                })}
                            </DeviceTagsContainer>
                        </DeviceInfoRow>
                    )}

                    <DeviceCheckboxRow>
                        <Checkbox checked={seen} onChange={e => handleSeenChange(e.checked)} />
                        <DeviceCheckboxLabel><FormattedMessage id="seen" /></DeviceCheckboxLabel>
                    </DeviceCheckboxRow>

                    <DeviceCheckboxRow>
                        <Checkbox checked={archived} onChange={e => handleArchivedChange(e.checked)} />
                        <DeviceCheckboxLabel><FormattedMessage id="tooltip_archive" /></DeviceCheckboxLabel>
                    </DeviceCheckboxRow>
                </DeviceInfoColumn>
            </DeviceContentContainer>
        );
    };

    const renderSectorContent = () => {

        return (
            <ContentContainer>
                <InfoColumn>
                    <SectionTitle><FormattedMessage id="title_heat_map_sector" />: {selectedAlarm.metadata?.sectorName || selectedAlarm.sectorName}</SectionTitle>
                    <InfoRow><FormattedMessage id="alarm" />: {selectedAlarm.alarmTypeName}</InfoRow>
                    <InfoRow><FormattedMessage id="description" />: {getDescriptionAlarms(selectedAlarm.alarmTypeName) || selectedAlarm.description}</InfoRow>
                    <InfoRow><FormattedMessage id="date_hour" />: {new Date(selectedAlarm.metadata?.alarmNoteDate).toLocaleString()}</InfoRow>
                    <InfoRow><FormattedMessage id="alarm_type" />: {selectedAlarm.alarmType === 'sector' ? intl.formatMessage({ id: 'notification_sector_alarm' }) : intl.formatMessage({ id: 'notification_device_alarm' })}</InfoRow>

                    <CheckboxRow>
                        <Checkbox checked={seen} onChange={e => handleSeenChange(e.checked)} />
                        <CheckboxLabel><FormattedMessage id="seen" /></CheckboxLabel>
                    </CheckboxRow>
                    <CheckboxRow>
                        <Checkbox checked={archived} onChange={e => handleArchivedChange(e.checked)} />
                        <CheckboxLabel><FormattedMessage id="tooltip_archive" /></CheckboxLabel>
                    </CheckboxRow>

                    {renderObservationsSection()}
                </InfoColumn>

                <ImageColumn>
                    {selectedAlarm.alarmNoteImg && (
                        <>
                            <AlarmImage
                                src={selectedAlarm.alarmNoteImg}
                                alt="Alarm Image"
                                onClick={() => setIsZoomed(true)}
                            />
                            {isZoomed && (
                                <Overlay onClick={() => setIsZoomed(false)}>
                                    <ZoomedImage src={selectedAlarm.alarmNoteImg} alt="Zoomed Alarm Image" />
                                </Overlay>
                            )}
                        </>
                    )}
                </ImageColumn>
            </ContentContainer>
        );
    };

    const renderObservationsSection = () => {
        return (
            <ObservationsSection>
                <ObservationsTitle>
                    <FormattedMessage id="observations" />
                </ObservationsTitle>

                <ObservationsContainer>
                    {loading ? (
                        <div>
                            <FormattedMessage id="loading_observations" />
                        </div>
                    ) : observations.length > 0 ? (
                        observations.map((obs, index) => (
                            <ObservationItem key={index}>
                                <ObservationHeader>
                                    <ObservationText>
                                        <strong><FormattedMessage id="date" />:</strong> {obs.date}
                                    </ObservationText>
                                    <ObservationText>
                                        <strong><FormattedMessage id="user" />:</strong> {obs.userName}
                                    </ObservationText>
                                </ObservationHeader>
                                <ObservationContent>
                                    <ObservationText>
                                        <strong><FormattedMessage id="observation" />:</strong> {obs.obs}
                                    </ObservationText>
                                </ObservationContent>
                            </ObservationItem>
                        ))
                    ) : (
                        <NoObservationsMessage>
                            <i className="pi pi-info-circle" style={{ marginRight: '8px' }} />
                            <FormattedMessage id="no_observations" />
                        </NoObservationsMessage>
                    )}
                </ObservationsContainer>

                <InputContainer>
                    <InputTextareaMsg
                        value={newObservation}
                        onChange={(e) => setNewObservation(e.target.value)}
                        rows={3}
                        placeholder={intl.formatMessage({ id: 'add_new_observation' })}
                    />
                    <ButtonMsg onClick={handleAddObservation} disabled={!newObservation.trim()}>
                        +
                    </ButtonMsg>
                </InputContainer>
            </ObservationsSection>
        );
    };

    return (
        <CustomDialog
            header={selectedAlarm?.alarmNoteId || selectedAlarm?.metadata?.alarmNoteId || intl.formatMessage({ id: 'alarm_details' })}
            visible={visible}
            style={{ width: '65vw' }}
            onHide={onHide}
        >
            <ModalContainer>
                {selectedAlarm && (
                    selectedAlarm.type === 'alarm_device'
                        ? renderDeviceContent()
                        : renderSectorContent()
                )}
            </ModalContainer>
        </CustomDialog>
    );
};

export default NotificationAlarmModal; 