import React, { useState, useRef, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import {
    CancelButton,
    CreateButton,
    StyledDialog,
    TagContainer,
    TagItem,
    RemoveTagButton,
    InputContainer,
    ColorPickerContainer,
    ColorOption,
    ButtonContainer,
} from './styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { tags_filter, tags_create, tags_delete } from '../../services/install_points_plus';
import useTagStore from '../../store/useTagStore';
import useUserRolesStore from '../../store/useUserRolesStore';


const ModalCreateTags = ({ visible, onHide, onSave, clientId }) => {
    const intl = useIntl();
    const toast = useRef(null);
    const { roles } = useUserRolesStore();
    const [newTag, setNewTag] = useState('');
    const [selectedColor, setSelectedColor] = useState('');
    const [tagList, setTagList] = useState([]);

    const { initializeTags, tags, addTag, removeTag } = useTagStore(); // Use o store
    

    const colors = ['red', 'green', 'blue', 'yellow', 'gray', 'pink', 'purple', 'brown', 'cyan', 'orange'];

    useEffect(() => {
        const adaPlusValue = checkAdaPlus(roles);

        if (adaPlusValue) {
            getTagList();
        }

    }, []);

    function checkAdaPlus(data) {
        if (data.length > 0 && data[0].ada_plus === true) {
            return true;
        } else {
            return false;
        }
    }

    const getTagList = async () => {
        try {
            const response = await tags_filter({
                client_id: clientId,
            });
            if (Array.isArray(response.data)) {
                setTagList(response.data);
            } else {
                console.error('API response is not an array:', response.data);
                setTagList([]);
            }

            initializeTags(response.data); 
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Atenção', detail: 'Não foi possivel listar as tags neste momento.', life: 3000 });
            setTagList([]);
        }
    };

    const getTagCreate = async (data) => {
        try {
            const response = await tags_create({
                client_id: clientId,
                color: data.color,
                id_name: data.id_name,
                name: data.name,
            });
            if (Array.isArray(response.data)) {
                setTagList(response.data);
            } else {
                setTagList([]);
            }
            getTagList();
            addTag(data); // Adicione a tag ao store
            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Tag criada com sucesso.', life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Atenção', detail: 'Não foi possivel criar a tag neste momento.', life: 3000 });
        }
    };

    const getTagDelete = async (data) => {
        const payload = {
            client_id: clientId,
            id_name: data.id_name,
        };

        try {
            removeTag(data.id_name); 
            const response = await tags_delete(payload);
            if (Array.isArray(response.data)) {
                setTagList(response.data);
            }
            getTagList();
            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Tag apagada com sucesso.', life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Atenção', detail: 'Não foi possivel apagar a tag neste momento.', life: 3000 });
        }
    };

    const handleAddTag = () => {
        if (newTag.length > 0 && selectedColor.length > 0) {
            if (newTag.trim() && selectedColor) {
                const newTagData = {
                    client_id: clientId,
                    id_name: newTag.toLowerCase().replace(/\s+/g, '').normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                    name: newTag,
                    color: selectedColor,
                    tag_master: null
                };

                setTagList((prevTagList) => [...prevTagList, newTagData]);
                setNewTag('');
                setSelectedColor('');

                getTagCreate(newTagData);
            }
        } else {
            toast.current.show({
                severity: 'warn',
                summary: intl.formatMessage({ id: "error_attention" }),
                detail: intl.formatMessage({ id: "msg_create_tag_warning" }),
                life: 2000
            });
        }
    };

    const confirmRemoveTag = (index) => {
        confirmDialog({
            message: intl.formatMessage({ id: "msg_erase_tag" }),
            header: intl.formatMessage({ id: "error_attention" }),
            accept: () => handleRemoveTag(index),
            acceptLabel: intl.formatMessage({ id: "yes" }),
            rejectLabel: intl.formatMessage({ id: "cancel_button" }),
            acceptClassName: 'p-button-danger',
            rejectClassName: 'p-button-secondary',
        });
    };

    const handleRemoveTag = (index) => {
        getTagDelete(tagList[index]);
    };

    const handleSave = () => {
        onSave(tagList);
        onHide();
    };

    const itemTemplate = (tag, index) => (
        <TagItem key={index} color={tag.color}>
            {tag.name}
            <RemoveTagButton onClick={() => confirmRemoveTag(index)} aria-label="Remove tag"> x </RemoveTagButton>
        </TagItem>
    );

    return (
        <>
            <Toast ref={toast} />
            <StyledDialog header={intl.formatMessage({ id: "title_created_tags" })} visible={visible} onHide={onHide}>
                <TagContainer>
                    {Array.isArray(tagList) && tagList.map((tag, index) => itemTemplate(tag, index))}
                </TagContainer>

                <h5><FormattedMessage id="title_create_new_tag" /></h5>
                <InputContainer>
                    <span className="p-float-label">
                        <InputText
                            id="tags-create"
                            value={newTag}
                            onChange={(e) => setNewTag(e.target.value)}
                            maxLength={15}
                            style={{ width: '-webkit-fill-available' }}
                        />
                        <label htmlFor="tags-create"><FormattedMessage id="msg_placeholder_tag" /></label>
                    </span>
                </InputContainer>

                <div className="p-field">
                    <h5><FormattedMessage id="title_tag_choose_color" /></h5>
                    <ColorPickerContainer>
                        {colors.map((color) => (
                            <ColorOption
                                key={color}
                                color={color}
                                selected={selectedColor === color}
                                onClick={() => setSelectedColor(color)}
                                aria-label={`Select color ${color}`}
                            />
                        ))}
                    </ColorPickerContainer>
                </div>

                <ButtonContainer>
                    <CancelButton onClick={onHide}><FormattedMessage id="cancel_button" /></CancelButton>
                    <CreateButton onClick={handleAddTag}><FormattedMessage id="create_tag_btn" /></CreateButton>
                </ButtonContainer>
            </StyledDialog>
            <ConfirmDialog />
        </>
    );
};

export default ModalCreateTags;