import styled from 'styled-components';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';

export const ModalContainer = styled.div`
  padding: 1rem;
`;

export const SectionTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #333;
`;

export const InfoRow = styled.div`
  margin-bottom: 0.8rem;
  font-size: 0.9rem;
  
  strong {
    margin-right: 0.5rem;
  }
`;

export const InfoRowObs = styled.div`
  max-height: 200px;
  overflow-y: auto;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 0.5rem 0;
`;

export const CheckboxRow = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
`;

export const CheckboxLabel = styled.span`
  margin-left: 0.5rem;
`;

export const ContentContainer = styled.div`
  display: flex;
  gap: 2rem;
`;

export const InfoColumn = styled.div`
  flex: 1;
`;

export const ImageColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AlarmImage = styled.img`
  max-width: 100%;
  height: auto;
  cursor: pointer;
  border-radius: 4px;
`;

export const InputTextareaMsg = styled.textarea`
  width: 100%;
  height: 80px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  margin-right: 10px;
  resize: none;
  
  &:focus {
    outline: none;
    border-color: #007ad9;
    box-shadow: 0 0 0 1px #007ad9;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-top: 1rem;
  width: 100%;
`; 

export const ButtonMsg = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: #007ad9;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0;
  transition: background-color 0.2s;

  &:hover {
    background-color: #005ea6;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

export const ZoomedImage = styled.img`
  max-width: 90%;
  max-height: 90vh;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
`;

export const ObservationContainer = styled.div`
  padding: 0.5rem;
  border-bottom: 1px solid #eee;
  
  &:last-child {
    border-bottom: none;
  }
`;

export const ObservationText = styled.p`
  margin: 0.2rem 0;
  font-size: 0.9rem;
`;

export const StyledChip = styled.span`
  background-color: ${props => props.$backgroundColor};
  color: ${props => props.$color};
  padding: 0.2rem 0.5rem;
  border-radius: 50px;
  margin: 0 0.2rem;
  font-size: 0.8rem;
`;

export const CustomDialog = styled(Dialog)`
  .p-dialog-content {
    padding: 0;
  }
`;

export const ObservationsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.5rem;
`;

export const ObservationsTitle = styled.h4`
  font-size: 1.1rem;
  color: #333;
  margin: 0;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #eee;
`;

export const ObservationsContainer = styled.div`
  max-height: 300px;
  overflow-y: auto;
  padding: 0.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #f9f9f9;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const ObservationItem = styled.div`
  background-color: white;
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ObservationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #eee;
`;

export const ObservationContent = styled.div`
  padding-top: 0.5rem;
`;

export const NoObservationsMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  color: #666;
  font-style: italic;
  background-color: white;
  border-radius: 4px;
`;

export const DeviceContentContainer = styled(ContentContainer)`
  padding: 1.5rem;
`;

export const DeviceInfoColumn = styled(InfoColumn)`
  gap: 1.5rem;
`;

export const DeviceInfoRow = styled(InfoRow)`
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;
  font-size: 1.1rem;
  line-height: 1.2;
  font-size: 1rem;
  strong {
    margin-right: 1rem;
    color: #333;
    font-weight: 600;
  }
`;

export const DeviceSectionTitle = styled(SectionTitle)`
  font-size: 1.4rem;
  margin-bottom: 2rem;
  padding-bottom: 0.8rem;
`;

export const DeviceTagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  margin-top: 0.8rem;
  padding: 0.5rem 0;
`;

export const DeviceStyledChip = styled(StyledChip)`
  padding: 0.4rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  margin: 0.2rem;
`;

export const DeviceCheckboxRow = styled(CheckboxRow)`
  margin: 1.5rem 0;
  gap: 1rem;
`;

export const DeviceCheckboxLabel = styled(CheckboxLabel)`
  font-size: 1.1rem;
`; 

