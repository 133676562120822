import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { FormattedMessage, useIntl } from "react-intl";
import { Dialog } from "primereact/dialog";
import useCompanySectorStore from "../../store/useCompanySectorStore";
import "./style.css";

const ExportReport = ({ onShow, onClose, config }) => {
  const {
    clientIdSelected,
    sectorIdSelected,
    startDateSelected,
    endDateSelected,
  } = useCompanySectorStore();
  const toast = useRef(null);
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);
  const [emails, setEmails] = useState([""]);
  const [emailErrors, setEmailErrors] = useState([""]);
  const [selectedFormats, setSelectedFormats] = useState([]);

  useEffect(() => {
    if (onShow) {
      setShowModal(true);
    } else if (!onShow) {
      setShowModal(false);
      onClose();
    }
  }, [onShow]);

  const closeModalExport = () => {
    setShowModal(false);
    setEmails([""]);
    onClose();
  };

  const handleEmailChange = (index, value) => {
    const updatedEmails = [...emails];
    updatedEmails[index] = value;
    setEmails(updatedEmails);

    const updatedEmailErrors = [...emailErrors];
    updatedEmailErrors[index] =
      value.length <= 5
        ? intl.formatMessage({ id: "error_address_email" })
        : "";
    setEmailErrors(updatedEmailErrors);
  };

  const handleAddEmail = () => {
    setEmails([...emails, ""]);
    setEmailErrors([...emailErrors, ""]);
  };

  const handleSendEmails = () => {
    const payload = {
      ...config.filters,
      emails: emails,

      file_types: selectedFormats,
    };

    try {
      const url = config.url(payload);
      setShowModal(false);
      setEmails([""]);
      setEmailErrors([""]);
      onClose();
      toast.current.show({
        severity: "success",
        summary: intl.formatMessage({ id: "success_send_email_report" }),
        life: 3000,
      });
    } catch (error) {
      setShowModal(false);
      onClose();
      const message = error.response.data.message;
      toast.current?.show({
        severity: "error",
        summary: intl.formatMessage({ id: "error_send_email_report" }),
        detail: message,
        life: 3000,
      });
    }
  };

  const handleRemoveEmail = (index) => {
    const updatedEmails = [...emails];
    updatedEmails.splice(index, 1);
    setEmails(updatedEmails);

    const updatedEmailErrors = [...emailErrors];
    updatedEmailErrors.splice(index, 1);
    setEmailErrors(updatedEmailErrors);
  };

  const handleCheckboxChange = (format) => {
    setSelectedFormats((prevFormats) =>
      prevFormats.includes(format)
        ? prevFormats.filter((item) => item !== format)
        : [...prevFormats, format]
    );
  };

  const isSendButtonDisabled =
    emails.some((email) => email.length <= 5) || selectedFormats.length === 0;

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header={intl.formatMessage({ id: "export_report" })}
        visible={showModal}
        id="modal-email"
        onHide={closeModalExport}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div>
          {emails.map((email, index) => (
            <div key={index} style={{ marginBottom: 20 }}>
              <div style={{ display: "flex" }}>
                <input
                  type="email"
                  placeholder={intl.formatMessage({ id: "type_email" })}
                  value={email}
                  onChange={(e) => handleEmailChange(index, e.target.value)}
                  style={{
                    width: "100%",
                    backgroundColor: "#e0e0e0",
                    borderRadius: "2px",
                  }}
                />

                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => handleRemoveEmail(index)}
                  style={{ marginLeft: 10 }}
                >
                  X
                </button>
              </div>
              {emailErrors[index] && (
                <div style={{ color: "red", fontSize: 12 }}>
                  {emailErrors[index]}
                </div>
              )}
            </div>
          ))}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
            }}
          >
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleAddEmail}
              style={{
                backgroundColor: "rgb(128, 134, 192)",
                borderColor: "rgb(128, 134, 192)",
              }}
            >
              <FormattedMessage id="add_button" />
            </button>
          </div>
        </div>
        <div>
          <span>Formato do arquivo</span>
        </div>
        <div style={{ marginTop: "15px" }}>
          <label>
            <input
              type="checkbox"
              checked={selectedFormats.includes("csv")}
              onChange={() => handleCheckboxChange("csv")}
              style={{ marginRight: "5px" }}
            />
            CSV
          </label>

          <label style={{ marginLeft: "15px" }}>
            <input
              type="checkbox"
              checked={selectedFormats.includes("xlsx")}
              onChange={() => handleCheckboxChange("xlsx")}
              style={{ marginRight: "5px" }}
            />
            XLSX
          </label>
        </div>
        <div style={{ textAlign: "center", marginTop: 30 }}>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSendEmails}
            disabled={isSendButtonDisabled}
            style={
              !isSendButtonDisabled
                ? {
                    backgroundColor: "rgb(110, 255, 148)",
                    borderColor: "rgb(110, 255, 148)",
                    color: "#000",
                  }
                : {
                    backgroundColor: "#cdcdcd",
                    borderColor: "#cdcdcd",
                    color: "#000",
                  }
            }
          >
            <FormattedMessage id="send_button" />
          </button>
        </div>
      </Dialog>
    </>
  );
};

export default ExportReport;
