import React from "react";
import { Dropdown } from "primereact/dropdown";
import devs from "../../../../assets/img/dropdown/exclamation.png";

const DropdownCompAlarm = ( { data, handleDropdownChangeAlarm, value,style} ) => {



  const alarmTemplate = (option) => {
    return (
      <div className="car-option">
        <span>{option.alarmTypeName}</span>
      </div>
    );
  };

  const selectedAlarmTemplate = (option, props) => {
    if (option) {
      return (
        <div style={{ display: 'flex', alignSelf: 'center'  }}>
          <div style={{ alignSelf: 'center' }}>{option.alarmTypeName}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  return (
    <div>
      <Dropdown
        options={data}
        value={value}
        valueTemplate={selectedAlarmTemplate}
        onChange={handleDropdownChangeAlarm}
        optionLabel="alarmTypeName"
        optionValue="alarmTypeId"
        itemTemplate={alarmTemplate}
        placeholder="Selecione o tipo de alarme"
        style={{ width: '100%', marginTop: "8px", backgroundColor: '#E6E6E6', ...style }}
      />
    </div>
  );
};

export default DropdownCompAlarm;
