// Função para gerar uma string aleatória
function generateRandomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

// Função para gerar uma data aleatória
export function generateRandomDate(start, end) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime())).toISOString();
}

// Função para gerar um dispositivo
function generateDevice() {
    return {
        serial_number: `SN${generateRandomString(6)}`,
        type: "vazão",
        macrometer: "true",
        alarms: [{ id: `A${Math.floor(Math.random() * 100)}`, details: "Low flow rate" }],
        commisioning_date: generateRandomDate(new Date(2020, 0, 1), new Date()),
        display_resolution: "1080p",
        comm_rate: `${Math.floor(Math.random() * 100)}Mbps`,
        set_image: `https://www.saaesorocaba.com.br/wp-content/uploads/2022/06/hidrometro-5.jpg`,
        dev_image: `http://www.saaeguacui.com.br/site/images/HID2.jpg`,
        battery_rate: `${Math.floor(Math.random() * 100)}%`,
        last_comm: generateRandomDate(new Date(2021, 0, 1), new Date())
    };
}

// Função para gerar um site
function generateSite(id) {
    return {
        id: id.toString(),
        sector: "Utilities",
        name: `Site ${generateRandomString(5)}`,
        lat: (Math.random() * 180 - 90).toFixed(4),
        long: (Math.random() * 360 - 180).toFixed(4),
        alt: `${Math.floor(Math.random() * 1000)}`,
        precision: "high",
        address: `${Math.floor(Math.random() * 1000)} Main St`,
        number: `${Math.floor(Math.random() * 1000)}`,
        city: "Los Angeles",
        state: "CA",
        country: "USA",
        zipcode: `900${Math.floor(Math.random() * 100)}`,
        created_at: generateRandomDate(new Date(2020, 0, 1), new Date()),
        updated_at: generateRandomDate(new Date(2021, 0, 1), new Date()),
        timezone: "PST",
        client_id: `client${generateRandomString(5)}`,
        neighborhood: "Downtown",
        complement: "Near Central Park",
        tags: [{ name_tags: "monitoring" }],
        user: { email: `admin${generateRandomString(5)}@example.com` },
        devices: [generateDevice()]
    };
}

// Função principal do serviço
export function generateSites() {
    const sites = Array.from({ length: 300 }, (_, i) => generateSite(i + 1));
    return sites;
}


export function generateStructures(numStructures) {
    const structures = [];
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize the time to midnight

    for (let i = 0; i < numStructures; i++) {
        const structure = {};
        const startDate = new Date();
        startDate.setDate(today.getDate() - (99 - i)); // Adjust the start date for each structure

        let currentDate = new Date(startDate);

        while (currentDate <= today) {
            const dateStr = currentDate.toISOString().split('T')[0];
            structure[dateStr] = Math.floor(Math.random() * 6); // Generate a random value between 0 and 5
            currentDate.setDate(currentDate.getDate() + 1);
        }

        structures.push(structure);
    }

    return structures;
}


