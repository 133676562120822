import React, { useEffect, useState, useCallback, useRef } from "react";
import Header from "../../components/Header";
import { AiFillUpCircle, AiFillDownCircle } from "react-icons/ai";
import { TbMapPins } from "react-icons/tb";
import {
  Container,
  GridContainer,
  SidebarContainer,
  ListContainer,
  List,
  ListItem,
  GridItem,
  GridItemTitle,
  GridItemChart,
  TitleContainer,
  CompanyText,
  SectorText,
  GridItemSearch,
  GridItemInput,
  GridItemDate,
  ChartItem,
  GridItemSidebar,
  AlertMsg,
  SubListContainer,
  SubListWrapper,
  SubList,
  SubListItem,
  OuterDiv,
  InnerDiv,
  GridItemSidebarContainer,
  AllMacrosDiv,
  ChartItemAdaPlus,
} from "./styles";
import { MdList, MdSearch, MdCancel, MdPinDrop } from "react-icons/md";
import { CgDisplayGrid } from "react-icons/cg";
import useUserStore from "../../store/useUserStore";
import useDateSelectedStore from "../../store/map/useDateSelectedStore";
import DateSelect from "../../components/DateSelect";
import { FormattedMessage, useIntl } from "react-intl";
import useStateSidebarStore from "../../store/useStateSidebarStore";
import useClientSelectedStore from "../../store/map/useClientSelectedStore";
import _debounce from "lodash/debounce";
import pako from "pako";
import {
  getReferenceDate,
  convertDeviceMap,
  mergeDevices,
  timeLocationToPosition,
  convertDeviceAPI,
} from "../../utils/Utils";
import {
  allSectors,
  pressure_data,
  mvn_data,
  map_pins_data,
  flowResearchFactor,
  scheme,
  sectorScheme,
  pressure_data_list_limited,
} from "../../services/dashboard";

import RenderPressure from "../../components/Charts/Pressure/index";
import RenderMVNHydroLoad from "../../components/Charts/LoadHydraulic/index";
import RenderWaterFlow from "../../components/Charts/Flow/index";
import RenderLeakResearchFactor from "../../components/Charts/ResearchFactor/index";
import TimelapseMap from "../../components/Maps/TimelapseMap/index";
import { Toast } from "primereact/toast";

import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import useCompanyStore from "../../store/useCompanyStore";
import useCompanySectorStore from "../../store/useCompanySectorStore";
import useDropdownStore from "../../store/useDropdownStore";
import { list_macro_sector } from "../../services/macro_sector";
import { NUMBER_DVCS_CHART_PRESSURE } from "../../config/config";
import { listDevicesLite } from "../../services/install_points_plus";

import macroOffDouble from "../../assets/img/icons/macroOffDouble.png";
import macroOffPurpple from "../../assets/img/icons/macroOffPurpple.png";
import useUserRolesStore from "../../store/useUserRolesStore";


// const NUMBER_DEVICES = parseInt(NUMBER_DVCS_CHART_PRESSURE) || 10;


const Dashboard = () => {
  const intl = useIntl();
  const toast = useRef(null);
  const { roles } = useUserRolesStore();
  const { user } = useUserStore();
  const { tradingName } = useCompanyStore();
  const { clientSelected } = useClientSelectedStore();
  const { dateSelected, setDateSelected } = useDateSelectedStore();
  const { setIsOpen } = useStateSidebarStore();
  const { disableDropdown, enableDropdown } = useDropdownStore();
  const [expand, setExpand] = useState(true);
  const [indexSelected, setIndexSelected] = useState(0);
  const [itemSelected, setItemSelected] = useState(null);
  const [items, setItems] = useState([]);
  const [filter, setFilter] = useState("");
  const [listSectors, setListSectors] = useState([]);
  const [sectorNetMap, setSectorNetMap] = useState([]);
  const [sectorGeoLocation, setSectorGeoLocation] = useState([]);
  const {
    setClientIdSelected,
    setSectorIdSelected,
    setStartDateSelected,
    setEndDateSelected,
  } = useCompanySectorStore();

  const [mvn, setMvn] = useState(null);
  const [pressure, setPressure] = useState(null);
  const [datasMap, setDatasMap] = useState(null);
  const [dataFlowFactorSearch, setDataFlowFactorSearch] = useState(null);
  const [period, setPeriod] = useState("Todo");
  const [info, setInfo] = useState(null);
  const [selectedSensor, setSelectedSensor] = useState(null);
  const [devices, setDevices] = useState(null);
  const [load, setLoad] = useState(true);
  const [macro, setMacro] = useState([]);
  const [allMacros, setAllMacros] = useState([]);
  const [indexMapping, setIndexMapping] = useState(null);
  const [minMaxValueScale, setMinMaxValueScale] = useState(null);
  const [numberDevices, setNumberDevices] = useState(0);

  const [flags, setFlags] = useState({
    showChartFlow: true,
    showChartResearchFactor: false,
    hideListTime: false,
    hideList: false,
    pressure: false,
    mvn: false,
    flow_factor: false,
    map: false,
  });

  // Teste
  const [hoveredElement, setHoveredElement] = useState(null);
  const [indexSubSelected, setIndexSubSelected] = useState(null);
  const [showSubSector, setShowSubSector] = useState(false);
  const [flagAllMacroSectors, setFlagAllMacroSectors] = useState(false);
  const [flagAllMacroSectorsBtn, setFlagAllMacroSectorsBtn] = useState(false);
  const [indexSubToSee, setIndexSubToSee] = useState(null);
  const [listSelected, setListSelected] = useState(null);

  useEffect(() => {
    disableDropdown();
    debouncedGetSectors();
    return debouncedGetSectors.cancel;
  }, [user, clientSelected]);

  useEffect(() => {
    if (itemSelected) {
      disableDropdown();
    }
    debouncedDataCharts();
    return debouncedDataCharts.cancel;
  }, [dateSelected, itemSelected]);

  useEffect(() => {
    if (filter.length > 0) {
      const result = listSectors.filter((setor) =>
        setor.sectorName.toLowerCase().includes(filter.toLowerCase())
      );
      setItems(result);
    } else {
      setItems(listSectors);
    }
  }, [filter, listSectors]);

  useEffect(() => {
    if (items) {
      if (items.length === 0) {
        enableDropdown();
      }
    }
  }, [items]);

  useEffect(() => {
    debouncedGetAllMacroSectors();
  }, [flagAllMacroSectors]);

  const debouncedGetAllMacroSectors = _debounce(() => {
    const payload = {
      clientId:
        user.type_user === "1" && clientSelected.id
          ? clientSelected.id
          : user.client_id,
    };

    getSectorsOfMacroSector(payload);
  }, 100);

  const debouncedGetSectors = _debounce(() => {
    if (user) {
      setIndexSelected(0);
      getSectors();
    }
  }, 200);

  const debouncedDataCharts = _debounce(() => {
    const payload = {
      clientId: user.type_user === "1" ? clientSelected.id : user.client_id,
      dateSelected,
      sectorId: itemSelected?.sectorId,
    };

    if (itemSelected && itemSelected.isMaster) {
      payload.isMaster = true;
      getSectorsOfMacroSector({ clientId: payload.clientId, isMaster: false });
    }

    setClientIdSelected(payload.clientId);
    setSectorIdSelected(payload.sectorId);

    const allValuesAreTruthy = Object.values(payload).every(Boolean);

    if (allValuesAreTruthy && load) {
      dataChart(payload);
    }
  }, 1000);

  const customSort = (a, b) => {
    if (a.isMaster !== b.isMaster) {
      // Se isMaster for diferente, ordena com base em isMaster
      return a.isMaster ? -1 : 1;
    } else {
      // Se isMaster for igual, ordena com base em sectorName
      return a.sectorName.localeCompare(b.sectorName);
    }
  };

  const getSectors = async () => {
    setDateSelected("30");
    try {
      const payload = {
        clientId:
          user.type_user === "1" && clientSelected.id
            ? clientSelected.id
            : user.client_id,
      };
      const response = await allSectors(payload);
      const verifyIsMaster = response.data.map((item) => item.isMaster);
      const countTrue = verifyIsMaster.filter((item) => item === true).length;
      const moreThanOneTrue = countTrue > 1;

      setFlagAllMacroSectorsBtn(moreThanOneTrue);

      const topLevelSectors = response.data.filter((sector) => {
        const isSubSector = response.data.some(
          (sub) =>
            sub.subSector &&
            sub.subSector.some(
              (subSector) => subSector.sectorId === sector.sectorId
            )
        );
        return !isSubSector;
      });

      const ortedData = topLevelSectors.sort(customSort);

      const sectorIdToIndexMap = {};

      const mapSectors = (sectors, parentIndex = null) => {
        sectors.forEach((sector, subIndex) => {
          sectorIdToIndexMap[sector.sectorId] = { subIndex, parentIndex };

          if (sector.subSector && sector.subSector.length > 0) {
            mapSectors(sector.subSector, subIndex);
          }
        });
      };
      mapSectors(ortedData);
      setIndexMapping(sectorIdToIndexMap);
      setItems(ortedData);
      setListSectors(ortedData);
      selectItem(0, ortedData[0]);

      if (response.data && response.data.length === 0) {
        setDatasMap([{}, "", {}]);
        setMvn([]);
        setPressure([]);
        setDataFlowFactorSearch([]);
        enableDropdown();
      }
    } catch (error) {
      if (error.response) {
        const msg = error.response.data.message;
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: msg,
          life: 10000,
        });
      } else if (error.request) {
        const message = error.message;
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: message,
          life: 10000,
        });
      }
    }
  };

  function checkAdaPlus(data) {
    if (data.length > 0 && data[0].ada_plus === true) {
      return true;
    } else {
      return false;
    }
  }

  const dataChart = async (data) => {
    localStorage.removeItem("deviceData");
    const adaPlusValue = checkAdaPlus(roles);

    if (data.isMaster) {
      enableDropdown();
      setDatasMap([{}, "", {}]);
      setMvn([]);
      setPressure([]);
      setDataFlowFactorSearch([]);
      setInfo({});
      setFlags((prevFlags) => ({
        ...prevFlags,
        pressure: false,
        mvn: false,
        flow_factor: false,
        map: true,
      }));
    }

    if (itemSelected && !data.isMaster) {
      setMacro([false, {}]);
      setLoad(false);
      setFlags((prevFlags) => ({
        ...prevFlags,
        pressure: false,
        mvn: false,
        flow_factor: false,
        map: false,
      }));
      const intervalDays = data.dateSelected;
      const currentDate = new Date();
      const startDate = new Date(currentDate);
      startDate.setDate(startDate.getDate() - intervalDays);
      const finalStartDate = `${startDate.getFullYear()}-${(
        startDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${startDate.getDate().toString().padStart(2, "0")}`;
      const finalEndDate = `${currentDate.getFullYear()}-${(
        currentDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${currentDate
          .getDate()
          .toString()
          .padStart(2, "0")}`;
      setStartDateSelected(finalStartDate);
      setEndDateSelected(finalEndDate);

      try {
        const payload = {
          sectorId: data.sectorId,
          dti: finalStartDate,
          dtf: finalEndDate,
          clientId: data.clientId,
        };

        const payloadDevices = {
          per_page: 5000,
          sector_name: items[0]?.sectorName,
        };

        const requests = [
          mvn_data(payload),
          map_pins_data(payload),
          flowResearchFactor(payload),
          scheme(payload),
          sectorScheme(payload),
        ];

        if (adaPlusValue === true) {
          requests.push(listDevicesLite(payloadDevices));
        }

        const [
          mvnRes,
          mapPinsRes,
          flowAndResearchFactorRes,
          schemeRes,
          sectorSchemeRes,
          listDevicesLiteRes,
        ] = await Promise.all(requests);

        setMvn(mvnRes.data);

        const tempSchemeDevices = schemeRes.data.devices;
        const tempListDevicesLite =
          adaPlusValue === true ? listDevicesLiteRes.items : [];

        tempSchemeDevices.forEach((itemA) => {
          const matchingItemB = tempListDevicesLite.find((itemB) =>
            itemB.devices.some(
              (device) => device.serial_number === itemA.deviceId
            )
          );

          if (matchingItemB) {
            const matchingDevice = matchingItemB.devices.find(
              (device) => device.serial_number === itemA.deviceId
            );
            if (matchingDevice) {
              itemA.last_comm = matchingDevice.last_comm;
              itemA.last_comm_status = matchingDevice.last_comm_status;
            }
          } else {
            itemA.last_comm = null;
            itemA.last_comm_status = null;
          }
        });

        const auxMapPins = convertDeviceAPI(mapPinsRes);
        const devices = convertDeviceMap(auxMapPins);

        if (devices) {
          const effectiveNumberDevices = checkAdaPlus(roles) ? numberDevices : parseInt(NUMBER_DVCS_CHART_PRESSURE);
        
          if (Object.keys(devices).length > effectiveNumberDevices) {
            const dataArray = Object.values(devices);
            dataArray.sort((a, b) => {
              const pressAvgA = a.pressAvg || Number.MAX_VALUE;
              const pressAvgB = b.pressAvg || Number.MAX_VALUE;
              return pressAvgA - pressAvgB;
            });
            const listDevicesIdTop = dataArray
              .slice(0, effectiveNumberDevices)
              .map((item) => item.deviceId);
            getPressuresDevicesNumberLimited(listDevicesIdTop);
            localStorage.setItem("deviceDataInitial", JSON.stringify(listDevicesIdTop));
          }
          if (Object.keys(devices).length <= effectiveNumberDevices) {
            const listDevicesId = Object.values(devices).map(
              (item) => item.deviceId
            );
            getPressuresDevicesNumberLimited(listDevicesId);
            localStorage.setItem("deviceDataInitial", JSON.stringify(listDevicesId));
          }
        }

        const ref_datetime = getReferenceDate(period, payload.dti);
        const { positions, locations } = timeLocationToPosition(
          mapPinsRes?.data,
          devices,
          ref_datetime
        );

        const devicesMerge = mergeDevices(devices, tempSchemeDevices);
        setDatasMap([devicesMerge, ref_datetime, locations]);
        setDataFlowFactorSearch(flowAndResearchFactorRes.data);
        setInfo(sectorSchemeRes.data.info);
        setSectorNetMap(schemeRes.data?.sectorNetMap);
        setSectorGeoLocation(sectorSchemeRes.data?.sectorGeoDelimitation);
        setDevices(schemeRes.data?.devices);
        setLoad(true);

        const DeviceMainId = sectorSchemeRes.data.mainInputPressPoint;
        const DeviceSecondaryId = sectorSchemeRes.data.secondEntryPressurePoint;
        const DeviceTertiaryId = sectorSchemeRes.data.thirdEntryPressurePoint;

        setMinMaxValueScale({
          DeviceMainId,
          DeviceSecondaryId,
          DeviceTertiaryId,
        });

        setFlags((prevFlags) => ({
          ...prevFlags,
          pressure: false,
          mvn: true,
          flow_factor: true,
          map: true,
        }));
        enableDropdown();
      } catch (error) {
        enableDropdown();
        if (error.response) {
          const msg = error.response.data.message;
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: msg,
            life: 10000,
          });
        } else if (error.request) {
          const message = error.message;
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            life: 10000,
          });
        }
        setFlags((prevFlags) => ({
          ...prevFlags,
          pressure: false,
          mvn: false,
          flow_factor: false,
          map: false,
        }));
      }
    }
  };

  const getPressuresDevicesNumberLimited = async (data) => {
    setFlags((prevFlags) => ({
      ...prevFlags,
      pressure: false,
      mvn: true,
      flow_factor: true,
      map: true,
    }));
    const intervalDays = dateSelected;
    const currentDate = new Date();
    const startDate = new Date(currentDate);
    startDate.setDate(startDate.getDate() - intervalDays);
    const finalStartDate = `${startDate.getFullYear()}-${(
      startDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${startDate.getDate().toString().padStart(2, "0")}`;
    const finalEndDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
    setListSelected(data);

    const payload = {
      clientId: user.type_user === "1" ? clientSelected.id : user.client_id,
      dti: finalStartDate,
      dtf: finalEndDate,
      sectorId: itemSelected?.sectorId,
      devices: data,
    };

    try {
      const pressureRes = await pressure_data_list_limited(payload);
      const auxPressure = pressureRes.data.data;
      const tempPressure = JSON.parse(
        pako.ungzip(auxPressure, { to: "string" })
      );
      setPressure(tempPressure);
      setFlags((prevFlags) => ({
        ...prevFlags,
        pressure: true,
        mvn: true,
        flow_factor: true,
        map: true,
      }));
    } catch (error) {
      setPressure([]);
      if (error.response) {
        const msg = error.response.data.message;
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: msg,
          life: 10000,
        });
      } else if (error.request) {
        const message = error.message;
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: message,
          life: 10000,
        });
      }
      setFlags((prevFlags) => ({
        ...prevFlags,
        pressure: true,
        mvn: true,
        flow_factor: true,
        map: true,
      }));
    }
  };

  const getSectorsOfMacroSector = async (data) => {
    setSectorGeoLocation([]);

    try {
      const response = await list_macro_sector(data);
      const allMacros = response.data.filter((item) => item.isMaster === true);
      setAllMacros([true, allMacros.map((item) => item.sectorGeoDelimitation)]);

      if (!itemSelected && flagAllMacroSectors) {
        const extractedData = allMacros
          .flatMap((item) => item.subSector)
          .flatMap((sectorGroup) =>
            sectorGroup.sectorGeoDelimitation.map((coord) => ({
              sectorId: sectorGroup.sectorId,
              lat: coord.lat,
              long: coord.long,
            }))
          );

        const groupedBySectorId = extractedData.reduce((acc, item) => {
          const sectorId = item.sectorId;
          acc[sectorId] = acc[sectorId] || [];
          acc[sectorId].push(item);
          return acc;
        }, {});

        const resultArrayAll = Object.values(groupedBySectorId);
        setSectorGeoLocation(resultArrayAll);
      }

      if (itemSelected && itemSelected.sectorId) {
        const aux = response.data.find(
          (data) => data.sectorId === itemSelected.sectorId
        );

        if (flagAllMacroSectors) {
          const extractedData = allMacros
            .flatMap((item) => item.subSector)
            .flatMap((sectorGroup) =>
              sectorGroup.sectorGeoDelimitation.map((coord) => ({
                sectorId: sectorGroup.sectorId,
                lat: coord.lat,
                long: coord.long,
              }))
            );

          const groupedBySectorId = extractedData.reduce((acc, item) => {
            const sectorId = item.sectorId;
            acc[sectorId] = acc[sectorId] || [];
            acc[sectorId].push(item);
            return acc;
          }, {});

          const resultArrayAll = Object.values(groupedBySectorId);
          setSectorGeoLocation(resultArrayAll);
        }

        if (aux && aux.subSector && !flagAllMacroSectors) {
          const coordinatesArray = aux.subSector.flatMap((sector) =>
            sector.sectorGeoDelimitation.map((coord) => ({
              sectorId: sector.sectorId,
              ...coord,
            }))
          );

          const groupedBySectorId = coordinatesArray.reduce((acc, item) => {
            const sectorId = item.sectorId;
            acc[sectorId] = acc[sectorId] || [];
            acc[sectorId].push(item);
            return acc;
          }, {});

          const resultArray = Object.values(groupedBySectorId);
          setSectorGeoLocation(resultArray);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const changeExpand = () => {
    setExpand(!expand);
    setIsOpen(expand);
  };

  const selectItem = (index, item) => {
    setFlagAllMacroSectors(false);
    setIndexSelected(index);
    setItemSelected(item);
    setDateSelected("30");
    setSelectedSensor(null);
    setIndexSubSelected(null);
    if (checkAdaPlus(roles)) {
      setNumberDevices(0);
    };
    
    if (
      item &&
      item.sectorGeoDelimitation &&
      item.sectorGeoDelimitation.length > 0 &&
      item.isMaster
    ) {
      setMacro([true, item.sectorGeoDelimitation]);
    }
  };

  const handleFiltroChange = (event) => {
    const valueTyped = event.target.value;
    setFilter(valueTyped);
  };

  const cleanSearch = () => {
    setFilter("");
    setItems(listSectors);
  };

  const handleBtnChangeChartFlow = useCallback(() => {
    setFlags((prevFlags) => ({
      ...prevFlags,
      showChartFlow: true,
      showChartResearchFactor: false,
    }));
  }, []);

  const handleBtnChangeChartResearchFactor = useCallback(() => {
    setFlags((prevFlags) => ({
      ...prevFlags,
      showChartFlow: false,
      showChartResearchFactor: true,
    }));
  }, []);

  const onSelectSensor = (sensorId) => {
    setSelectedSensor((prevSelectedSensor) =>
      sensorId === prevSelectedSensor ? null : sensorId
    );
  };

  const handleSelectLine = (data) => {
    onSelectSensor(data.label);
    setSelectedSensor(data.label);
  };

  const handleMouseEnter = (key) => {
    setHoveredElement(key);
  };

  const handleMouseLeave = () => {
    setHoveredElement(null);
  };

  const handleMouseEnterSub = (key) => {
    setHoveredElement(`sub-${key}`);
  };

  const handleMouseLeaveSub = () => {
    setHoveredElement(null);
  };

  const listSubSector = (e, index) => {
    setIndexSubToSee(index);
    setShowSubSector(!showSubSector);
    e.stopPropagation();
  };

  const selectSubSector = (index, item) => {
    setIndexSubSelected(`sub-${index}`);
    setItemSelected(item);
    setDateSelected("30");
    setSelectedSensor(null);
    setIndexSelected(null);
  };

  const onSelectedSector = (data) => {
    if (data) {
      setIndexSelected(indexMapping[data].parentIndex);
      if (data.length === 0) {
        return;
      }
      const aux = items.find(
        (item) =>
          item.sectorId === data ||
          (item.subSector &&
            item.subSector.some((subItem) => subItem.sectorId === data))
      );
      const sectorSelected = aux.subSector.find(
        (item) => item.sectorId === data
      );

      const getIndexList = items
        .map((sector) => sector.sectorName)
        .indexOf(aux.sectorName);
      setIndexSubToSee(getIndexList);
      setShowSubSector(true);

      setItemSelected(sectorSelected);
      setDateSelected("30");
      setSelectedSensor(null);
      setFlagAllMacroSectors(false);
      selectSubSector(indexMapping[data].subIndex, sectorSelected);
    }
  };

  const showAllMacroSectors = () => {
    setFlagAllMacroSectors(true);
    setMacro(allMacros);
    setIndexSelected(-1);
    setItemSelected(null);
  };

  const handleGetPressuresDevicesNumberLimited = (data) => {
    const arrayDvcs = Object.keys(data).filter(key => data[key]);
    const length = arrayDvcs.length;
    setNumberDevices(length > 0 ? parseInt(NUMBER_DVCS_CHART_PRESSURE) : 0);
    if (length > parseInt(NUMBER_DVCS_CHART_PRESSURE)) {
      toast.current.show({
        severity: "error",
        summary: intl.formatMessage({ id: "error_attention" }),
        detail: intl.formatMessage({ id: "msg_number_devices_to_get" }),
        life: 5000,
      });
      return;
    }
    getPressuresDevicesNumberLimited(arrayDvcs);
  };

  return (
    <Container>
      <Toast ref={toast} />
      <Header />
      <AlertMsg>
        {" "}
        <FormattedMessage id="msg_device_size" />{" "}
      </AlertMsg>
      <GridContainer
        $sidebarOpen={expand}
        $isMacro={
          (itemSelected && itemSelected.isMaster) || flagAllMacroSectors
        }
      >
        <GridItemSearch $sidebarOpen={expand}>
          <MdList
            onClick={changeExpand}
            size={50}
            style={{ cursor: "pointer" }}
          />
          {expand && (
            <>
              <GridItemInput
                placeholder={intl.formatMessage({ id: "search_region" })}
                value={filter}
                onChange={handleFiltroChange}
              />
              {filter.length === 0 && <MdSearch size={25} />}
              {filter.length > 0 && (
                <MdCancel
                  size={25}
                  onClick={cleanSearch}
                  style={{ cursor: "pointer" }}
                />
              )}
            </>
          )}
        </GridItemSearch>
        <GridItemTitle>
          <TitleContainer>
            <CompanyText>{tradingName}</CompanyText>
            <SectorText>
              {indexSelected === -1
                ? intl.formatMessage({ id: "all_macro_sectors" })
                : itemSelected?.sectorName}
            </SectorText>
          </TitleContainer>
        </GridItemTitle>
        <GridItemDate>
          <DateSelect show={(itemSelected && !itemSelected.isMaster) || null} />
        </GridItemDate>
        <GridItemSidebar>
          {flagAllMacroSectorsBtn && (
            <OuterDiv onClick={showAllMacroSectors}>
              <InnerDiv $expand={expand}>
                {expand ? (
                  <AllMacrosDiv $selected={indexSelected === -1}>
                    <CgDisplayGrid
                      size={30}
                      color={indexSelected === -1 ? "#8086C0" : "#000000"}
                      style={{ marginRight: 5 }}
                    />{" "}
                    <FormattedMessage id="all_macro_sectors" />
                  </AllMacrosDiv>
                ) : (
                  <CgDisplayGrid
                    size={30}
                    color={indexSelected === -1 ? "#8086C0" : "#000000"}
                  />
                )}
              </InnerDiv>
            </OuterDiv>
          )}
          <GridItemSidebarContainer $height={flagAllMacroSectorsBtn}>
            <SidebarContainer>
              <List>
                {items.map((item, index) => (
                  <ListContainer key={index}>
                    <ListItem
                      key={index}
                      $active={index === indexSelected}
                      onClick={() => selectItem(index, item)}
                      $sidebarOpen={expand}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                    >
                      {!expand ? "" : item.sectorName}
                      {item.subSector && item.isMaster ? (
                        <AiFillDownCircle
                          key={index}
                          size={23}
                          color={
                            index === indexSelected || index === hoveredElement
                              ? " #fff"
                              : "#8086C0"
                          }
                          onClick={(e) => listSubSector(e, index, item)}
                          style={{
                            transform:
                              (index === indexSelected && showSubSector) ||
                                (index === hoveredElement && showSubSector)
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                            transition: "0.2s ease",
                          }}
                        />
                      ) : item.hasMacrometer ? (
                        <img
                          src={macroOffPurpple}
                          height="25px"
                          style={{
                            filter:
                              index === indexSelected ||
                                index === hoveredElement
                                ? "brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(93deg) brightness(108%) contrast(104%)"
                                : "",
                          }}
                          alt="Macrometer"
                        />
                      ) : (
                        <MdPinDrop
                          key={index}
                          size={23}
                          color={
                            index === indexSelected || index === hoveredElement
                              ? " #fff"
                              : "#8086C0"
                          }
                        />
                      )}
                    </ListItem>
                    <SubListContainer>
                      {item.subSector && showSubSector && (
                        <SubListWrapper>
                          {index === indexSubToSee &&
                            item.subSector.map((subSector, subIndex) => (
                              <SubList key={subIndex}>
                                <SubListItem
                                  key={subIndex}
                                  $activeSub={
                                    itemSelected &&
                                    itemSelected.sectorId ===
                                    subSector.sectorId &&
                                    `sub-${subIndex}` === indexSubSelected
                                  }
                                  onClick={() =>
                                    selectSubSector(subIndex, subSector)
                                  }
                                  $sidebarOpen={expand}
                                  onMouseEnter={() =>
                                    handleMouseEnterSub(subIndex)
                                  }
                                  onMouseLeave={handleMouseLeaveSub}
                                >
                                  {!expand ? "" : subSector.sectorName}
                                  {item.hasMacrometer ? (
                                    <img
                                      src={macroOffDouble}
                                      height="25px"
                                      style={{
                                        filter:
                                          (itemSelected &&
                                            itemSelected.sectorId ===
                                            subSector.sectorId &&
                                            `sub-${subIndex}` ===
                                            indexSubSelected) ||
                                            (itemSelected &&
                                              itemSelected.sectorId ===
                                              subSector.sectorId &&
                                              `sub-${subIndex}` ===
                                              hoveredElement)
                                            ? "brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(93deg) brightness(108%) contrast(104%)"
                                            : "",
                                      }}
                                      alt="Macrometer"
                                    />
                                  ) : (
                                    <TbMapPins
                                      key={subIndex}
                                      size={23}
                                      color={
                                        (itemSelected &&
                                          itemSelected.sectorId ===
                                          subSector.sectorId &&
                                          `sub-${subIndex}` ===
                                          indexSubSelected) ||
                                          (itemSelected &&
                                            itemSelected.sectorId ===
                                            subSector.sectorId &&
                                            `sub-${subIndex}` === hoveredElement)
                                          ? " #fff"
                                          : "#8086C0"
                                      }
                                    />
                                  )}
                                </SubListItem>
                              </SubList>
                            ))}
                        </SubListWrapper>
                      )}
                    </SubListContainer>
                  </ListContainer>
                ))}
              </List>
              {/* Fim Aqui */}
            </SidebarContainer>
          </GridItemSidebarContainer>
        </GridItemSidebar>
        <GridItem
          $skeleton={flags.map}
          $isMacro={
            (itemSelected && itemSelected.isMaster) || flagAllMacroSectors
          }
        >
          {flags.map && (
            <TimelapseMap
              period={datasMap[1]}
              devices={datasMap[0]}
              locations={datasMap[2]}
              layers={null}
              traces={sectorNetMap}
              geoLocation={sectorGeoLocation}
              onSelectSensor={onSelectSensor}
              selectedSensor={selectedSensor}
              onSelectSector={onSelectedSector}
              info={info}
              userType={user?.type_user}
              isMaster={macro}
              minMaxValueScale={minMaxValueScale}
              adaPlus={checkAdaPlus(roles)}
              onGetDraw={handleGetPressuresDevicesNumberLimited}
            />
          )}
        </GridItem>
        {checkAdaPlus(roles)
          ? itemSelected &&
          !itemSelected.isMaster && (
            <GridItemChart
              $isMacro={
                (itemSelected && itemSelected.isMaster) || flagAllMacroSectors
              }
            >
              <ChartItemAdaPlus $skeleton={flags.pressure}>
                {flags.pressure && (
                  <RenderPressure
                    ref_date={datasMap[1]}
                    region={pressure}
                    devicesList={devices}
                    selectedSensor={selectedSensor}
                    onSelectLine={handleSelectLine}
                    onGetDevices={handleGetPressuresDevicesNumberLimited}
                    initialDevicesList={listSelected}
                    adaPlus={checkAdaPlus(roles)}
                  />
                )}
              </ChartItemAdaPlus>
              <ChartItemAdaPlus $skeleton={false && flags.showChartFlow}>
                {flags.showChartFlow && flags.flow_factor && (
                  <RenderWaterFlow
                    ref_date={datasMap[1]}
                    region={dataFlowFactorSearch}
                    btnChangeChart={handleBtnChangeChartResearchFactor}
                  />
                )}
                {flags.showChartResearchFactor && flags.flow_factor && (
                  <RenderLeakResearchFactor
                    ref_date={datasMap[1]}
                    region={dataFlowFactorSearch}
                    btnChangeChart={handleBtnChangeChartFlow}
                  />
                )}
              </ChartItemAdaPlus>
            </GridItemChart>
          )
          : itemSelected &&
          !itemSelected.isMaster && (
            <GridItemChart
              $isMacro={
                (itemSelected && itemSelected.isMaster) || flagAllMacroSectors
              }
            >
              <ChartItem $skeleton={flags.mvn}>
                {flags.mvn && (
                  <RenderMVNHydroLoad
                    ref_date={datasMap[1]}
                    region={mvn}
                    selectedSensor={selectedSensor}
                    onSelectLine={handleSelectLine}
                  />
                )}
              </ChartItem>
              <ChartItem $skeleton={flags.pressure}>
                {flags.pressure && (
                  <RenderPressure
                    ref_date={datasMap[1]}
                    region={pressure}
                    devicesList={devices}
                    selectedSensor={selectedSensor}
                    onSelectLine={handleSelectLine}
                    onGetDevices={handleGetPressuresDevicesNumberLimited}
                    initialDevicesList={listSelected}
                    adaPlus={checkAdaPlus(roles)}
                  />
                )}
              </ChartItem>
              <ChartItem $skeleton={false && flags.showChartFlow}>
                {flags.showChartFlow && flags.flow_factor && (
                  <RenderWaterFlow
                    ref_date={datasMap[1]}
                    region={dataFlowFactorSearch}
                    btnChangeChart={handleBtnChangeChartResearchFactor}
                  />
                )}
                {flags.showChartResearchFactor && flags.flow_factor && (
                  <RenderLeakResearchFactor
                    ref_date={datasMap[1]}
                    region={dataFlowFactorSearch}
                    btnChangeChart={handleBtnChangeChartFlow}
                  />
                )}
              </ChartItem>
            </GridItemChart>
          )}
      </GridContainer>
    </Container>
  );
};

export default Dashboard;
