export const ADA_API_URL = process.env.REACT_APP_BASE_URL;
export const GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;
export const NUMBER_DVCS_CHART_PRESSURE = process.env.REACT_APP_NUMBER_DVCS_CHART_PRESSURE;
export const URL_BASE_KEYCLOAK = process.env.REACT_APP_URL_BASE_KEYCLOAK;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const REALMS_LIST = process.env.REACT_APP_REALMS_LIST;
export const URL_KDI_API = process.env.REACT_APP_URL_KDI_API;
export const URL_STG_API = process.env.REACT_APP_STT4_API;
export const TIMEOUT_REQ = process.env.REACT_APP_TIMEOUT_REQ;
export const TIMEOUT_REQ_LOGIN = process.env.REACT_APP_TIMEOUT_REQ_LOGIN;
export const ALARM_TYPE_OPTIONS = process.env.REACT_APP_ALARM_TYPE_OPTIONS;
export const DISABLE_DEVICE_ALARMS_FEATURES = process.env.REACT_APP_DISABLE_DEVICE_ALARMS_FEATURES;