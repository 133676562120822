import React from "react";
import { LoadingModalWrapper, LoadingContent, LoadingText, Spinner } from "./styles";
import { useIntl, FormattedMessage } from 'react-intl';

const LoadingModal = ({ loading }) => {
  if (!loading) return null; 

  return (
    <LoadingModalWrapper>
      <LoadingContent>
        <Spinner />
        <LoadingText><FormattedMessage id="loading_map_data" /></LoadingText>
      </LoadingContent>
    </LoadingModalWrapper>
  );
};

export default LoadingModal;

