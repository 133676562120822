import styled from "styled-components";

export const Container = styled.div`
  padding: 1px 3px 3px 3px;
  background-color: rgb(230, 230, 230);
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleChart = styled.div`
  margin-left: 20px;
  align-self: center;
  font-weight: bold;
`;

export const SettingsOpt = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const SettingsContainer = styled.div`
  max-height: 250px;
  width: 250px;
  overflow-y: auto;
  margin-top: 10px;
`;

export const SettingsContainerList = styled.div`
  max-height: 250px;
  width: 250px;
  overflow-y: auto;
  margin-top: 10px;
`;

export const Content = styled.div`
  display: flex;
  > label {
    margin-left: 10px;
  }

  > span {
    font-size: 15px;
  }
`;

export const SettingsCircle = styled.div`
  width: 15px;
  height: 15px;
  border: 1px solid ${(props) => props.$borderColor};
  background-color: ${(props) => props.$borderColor};
  border-radius: 20px;
`;

export const ButtonReset = styled.div`
  padding-left: 15px;
  padding-right: 5px;
  cursor: pointer;
  height: 28px;
  margin-right: 10px; // Espaço entre os botões
  align-self: center;
  user-select: none;
`;

export const ButtonExport = styled.div`
  padding-left: 3px;
  padding-right: 3px;
  cursor: pointer;
  height: 28px;
  align-self: center;
  user-select: none;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Button = styled.button`
  padding: 5px 10px;
`;

export const Chart = styled.div`
  background-color: #fff;
  max-height: 21vh;
  max-width: 49vw;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.$data.labels.length > 0 ? "max-height: 21vh" : "height: 21vh"}
`;

export const ChartAdaPlus = styled.div`
  background-color: #fff;
  max-height: 35vh;
  max-width: 49vw;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.$data.labels.length > 0 ? "max-height: 35vh" : "height: 35vh"};
`;
