import React from 'react';
import { ButtonGroup, Button } from './styles';

const CustomSelectButton = ({ options, value, onChange, disabled, disabledOptions = [] }) => {
  return (
    <ButtonGroup>
      {options.map((option, index) => {
        const isOptionDisabled = disabled || disabledOptions.includes(option.value); 
        return (
          <Button
            key={index}
            selected={value === option.value}
            onClick={() => !isOptionDisabled && onChange(option.value)} 
            disabled={isOptionDisabled} 
          >
            {value === option.value && <span>✓&nbsp;&nbsp;</span>}
            {option.label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export default CustomSelectButton;