import React, { useState } from 'react';
import { GoogleMap, Marker, Polyline, Polygon, OverlayView } from '@react-google-maps/api';
import html2canvas from 'html2canvas';
import { mapStyle } from './mapStyle';
import { ButtonContainer, CustomButton, SliderContainer } from './styles';
import SizeSelector from './Select';
import './style.css';

const AlarmCreatorMap = React.forwardRef(({
  mapContainerStyle = { 
    width: '100%',
    height: '100%',
    minWidth: '600px',
    position: 'relative',
    padding: '40px 0', 
    boxSizing: 'border-box'
  },
  defaultCenter,
  showTraces,
  traces,
  customMarkerIcon,
  kindPin,
  idDeviceChosen,
  polygonsPros,
  valueScaleColor,
  onSnapshot
}, ref) => {
  const circleRef = React.useRef(null);
  const rectangleRef = React.useRef(null);
  const [map, setMap] = React.useState(null);
  const [points, setPoints] = React.useState(null);
  const [polygons, setPolygons] = React.useState(null);
  const mapRef = React.useRef(null);
  const [rotationAngle, setRotationAngle] = React.useState(0);
  const [showRectangle, setShowRectangle] = React.useState(false);
  const [shapeSize, setShapeSize] = React.useState(50);
  const [currentPosition, setCurrentPosition] = React.useState(null);
  const [lastMapPosition, setLastMapPosition] = useState(null);
  
  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    styles: mapStyle,
    scaleControl: true,
    scaleControlOptions: {
      position: window.google.maps.ControlPosition.BOTTOM_CENTER
    },
    keyboardShortcuts: false
  };

  const colorsIsolines = [
    'rgb(124, 0, 152)',
    'rgb(170, 0, 0)',
    'rgb(234, 47, 58)',
    'rgb(196, 87, 23)',
    'rgb(255, 140, 0)',
    'rgb(255, 189, 14)',
    'rgb(255, 255, 0)',
    'rgb(155, 165, 0)',
    'rgba(25, 150, 100, 194)',
    'rgb(0, 255, 0)'
  ];

  function value2color(value) {
    const hidroLoad = Math.round(value);
    const minVal = valueScaleColor?.minVal;
    const maxVal = valueScaleColor?.maxVal;

    if (isNaN(hidroLoad)) {
      return "rgb(0, 0, 0)";
    }

    if (maxVal && hidroLoad > maxVal) {
      return "rgb(0, 150, 255)";
    }

    const percentage = (hidroLoad - minVal) / (maxVal - minVal) * 100;
    let index = Math.round(percentage / 10);
    if (percentage < 0) {
      index = 0;
    } else if (index > 0) {
      index -= 1;
    }
    return colorsIsolines[index];
  }

  const rotateRectangle = (center, bounds, angle) => {
    const toRadians = (angle) => angle * (Math.PI / 180);
    const rotatedBounds = [];
    const coords = [
      { lat: bounds.north, lng: bounds.west },
      { lat: bounds.north, lng: bounds.east },
      { lat: bounds.south, lng: bounds.east },
      { lat: bounds.south, lng: bounds.west },
    ];

    for (const coord of coords) {
      const lat = coord.lat - center.lat;
      const lng = coord.lng - center.lng;
      const newLat = center.lat + lat * Math.cos(toRadians(angle)) - lng * Math.sin(toRadians(angle));
      const newLng = center.lng + lat * Math.sin(toRadians(angle)) + lng * Math.cos(toRadians(angle));
      rotatedBounds.push({ lat: newLat, lng: newLng });
    }

    return rotatedBounds;
  };

  const getPolygonCenter = (polygon) => {
    const bounds = new window.google.maps.LatLngBounds();
    const vertices = polygon.getPath().getArray();
    vertices.forEach((vertex) => {
      bounds.extend(vertex);
    });
    return bounds.getCenter();
  };

  const getCurrentMapCenter = () => {
    if (!map) return defaultCenter;
    const center = map.getCenter();
    return {
      lat: center.lat(),
      lng: center.lng()
    };
  };

  const handleAddRectangle = () => {
    setShowRectangle(true);
    
    if (circleRef.current) {
      circleRef.current.setMap(null);
      circleRef.current = null;
    }

    const newPosition = getCurrentMapCenter();
    setCurrentPosition(newPosition);
    
    if (rectangleRef.current) {
      rectangleRef.current.setMap(null);
      rectangleRef.current = null;
    }

    const adjustedBounds = {
      north: newPosition.lat + shapeSize / 100000,
      south: newPosition.lat - shapeSize / 100000,
      east: newPosition.lng + shapeSize / 100000,
      west: newPosition.lng - shapeSize / 100000,
    };

    const rotatedBounds = rotateRectangle(newPosition, adjustedBounds, rotationAngle);

    const newRectangle = new window.google.maps.Polygon({
      paths: rotatedBounds,
      map: map,
      fillColor: 'transparent',
      fillOpacity: 0.3,
      strokeColor: '#000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      draggable: true,
      zIndex: 1010,
      clickable: true
    });

    newRectangle.addListener('drag', () => {
      const center = getPolygonCenter(newRectangle);
      if (center) {
        setCurrentPosition({
          lat: center.lat(),
          lng: center.lng()
        });
      }
    });

    rectangleRef.current = newRectangle;
  };

  const handleAddCircle = () => {
    setShowRectangle(false);
    
    if (rectangleRef.current) {
      rectangleRef.current.setMap(null);
      rectangleRef.current = null;
    }

    const newPosition = getCurrentMapCenter();
    setCurrentPosition(newPosition);
    
    if (circleRef.current) {
      circleRef.current.setMap(null);
      circleRef.current = null;
    }

    const newCircle = new window.google.maps.Circle({
      center: newPosition,
      radius: shapeSize,
      map: map,
      fillColor: 'transparent',
      fillOpacity: 0.3,
      strokeColor: '#000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      draggable: true,
      zIndex: 1010,
      clickable: true
    });

    newCircle.addListener('drag', () => {
      const center = newCircle.getCenter();
      if (center) {
        setCurrentPosition({
          lat: center.lat(),
          lng: center.lng()
        });
      }
    });

    circleRef.current = newCircle;
  };

  const handleRemoveShape = () => {
    setShowRectangle(false);
    setRotationAngle(0);
    setCurrentPosition(null);
    if (circleRef.current) {
      circleRef.current.setMap(null);
      circleRef.current = null;
    }
    if (rectangleRef.current) {
      rectangleRef.current.setMap(null);
      rectangleRef.current = null;
    }
  };

  const applyMapCorrection = () => {
    const mapNode = document.querySelector('.gm-style>div>div>div:last-child>div');
    if (mapNode) {
      const transformMatrix = getComputedStyle(mapNode).transform.split(',');
      mapNode.setAttribute(
        'style',
        `left:${parseFloat(transformMatrix[4])}px;top:${parseFloat(transformMatrix[5])}px;transform: none;position: absolute;z-index: 983;`
      );
    }
  };

  const takeSnapshot = React.useCallback(() => {
    if (!map || !mapRef.current) return;

    applyMapCorrection();
    return html2canvas(mapRef.current, {
      backgroundColor: null,
      useCORS: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      if (onSnapshot) {
        onSnapshot(imgData);
      }
      return imgData;
    });
  }, [map, onSnapshot]);

  React.useImperativeHandle(ref, () => ({
    takeSnapshot
  }));

  React.useEffect(() => {
    if (customMarkerIcon.length > 0) {
      setPoints(customMarkerIcon);
    }
  }, [customMarkerIcon]);

  React.useEffect(() => {
    if (polygonsPros?.length > 0) {
      setPolygons(polygonsPros);
    }
  }, [polygonsPros]);


  React.useEffect(() => {
    if (map) {
      if (showRectangle && rectangleRef.current) {
        const adjustedBounds = {
          north: currentPosition.lat + shapeSize / 100000,
          south: currentPosition.lat - shapeSize / 100000,
          east: currentPosition.lng + shapeSize / 100000,
          west: currentPosition.lng - shapeSize / 100000,
        };

        const rotatedBounds = rotateRectangle(currentPosition, adjustedBounds, rotationAngle);
        rectangleRef.current.setPath(rotatedBounds);
      } else if (circleRef.current) {
        circleRef.current.setRadius(shapeSize);
      }
    }
  }, [shapeSize, rotationAngle, map, showRectangle, currentPosition]);

  const checkBoundsAndCenter = React.useCallback(() => {
    return;
  }, []);

  React.useEffect(() => {
    if (map) {
      map.setOptions({
        gestureHandling: 'greedy',
        disableDefaultUI: true,
      });
      
      map.initialCenterComplete = true;
      
      if (map.boundsChangedListener) {
        window.google.maps.event.removeListener(map.boundsChangedListener);
      }
      
      map.boundsChangedListener = map.addListener('bounds_changed', () => {
      });

      return () => {
        if (map.boundsChangedListener) {
          window.google.maps.event.removeListener(map.boundsChangedListener);
        }
      };
    }
  }, [map]);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}>
      <ButtonContainer>
        <SizeSelector selectedSize={shapeSize} onChange={setShapeSize} />
        <CustomButton
          className="pi pi-stop"
          onClick={handleAddRectangle}
          $bgColor="#696969"
          $hoverColor="#363636"
        />
        <CustomButton
          className="pi pi-circle"
          onClick={handleAddCircle}
          $bgColor="#696969"
          $hoverColor="#363636"
        />
        <CustomButton
          className="pi pi-trash"
          onClick={handleRemoveShape}
          $bgColor="#696969"
          $hoverColor="#363636"
        />
    
     {showRectangle &&
        <SliderContainer>
          <label>Rotacionar Retângulo</label>
          <input
            type="range"
            min="0"
            max="180"
            value={rotationAngle}
            onChange={(e) => {
              setRotationAngle(Number(e.target.value));
            }}
          />
          <span>{rotationAngle}°</span>
        </SliderContainer>
      }

      </ButtonContainer>

      <div ref={mapRef} style={{ 
        flex: '1',
        position: 'relative',
        width: '100%',
        minHeight: '300px',
        minWidth: '600px',
      }}>
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }}>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            onLoad={(mapInstance) => setMap(mapInstance)}
            center={defaultCenter}
            zoom={17}
            options={mapOptions}
          >
            {showTraces && traces && traces.map((line, index) => (
              <Polyline
                key={index}
                path={[{ lat: line.x0, lng: line.y0 }, { lat: line.x1, lng: line.y1 }]}
                options={{
                  strokeColor: "#006699",
                  strokeOpacity: 1,
                  strokeWeight: 1,
                }}
              />
            ))}

            {polygons?.map((polygon, index) => (
              <Polygon
                key={index}
                paths={polygon.data}
                options={{
                  fillColor: value2color(polygon.value),
                  fillOpacity: 0.4,
                  strokeColor: "transparent",
                  strokeOpacity: 1,
                  strokeWeight: 2,
                }}
              />
            ))}

            {points && points.map((e, idx) => {
              const icon = kindPin === "alarm" ? e : {
                path: idDeviceChosen === e.id ? "M 6 -12 L -6 -12 L -6 0 L 6 0 Z" : "M 6 -6 A 6 6 0 1 0 -6 -6 A 6 6 0 1 0 6 -6",
                fillColor: value2color(e.h_load),
                fillOpacity: 0.9,
                scale: 4,
                strokeWeight: 0,
                zIndex: 1000
              };

              return (
                <Marker key={`mrk_bm_idx_${idx}`} position={e} icon={icon} zIndex={1000}>
                  <OverlayView
                    position={e}
                    getPixelPositionOffset={(width, height) => ({
                      x: -width / 2,
                      y: -height,
                    })}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  >
                    <div style={{ width: '10px', height: '10px', ...icon, marginTop: -66, marginLeft: -20, zIndex: 1000 }}>
                      <span style={{ textAlign: 'center', backgroundColor: '#000', color: '#fff', padding: '1px 5px', borderRadius: '8px', marginLeft: 4 }}>
                        {e.id.slice(-4)}
                      </span>
                      <br /><br />
                      <span style={{ fontSize: 10, fontWeight: 'bold', color: '#000' }}>
                        {`
                          ${e.h_load !== '.' ? `CH:${Math.round(e.h_load)}` : ''}
                          ${e.pressure !== '.' && !isNaN(e.pressure) ? `P:${Math.round(e.pressure)}` : ''}
                        `}
                      </span>
                    </div>
                  </OverlayView>
                </Marker>
              );
            })}
          </GoogleMap>
        </div>
      </div>
    </div>
  );
});

export default AlarmCreatorMap;