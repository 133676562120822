import api_ada from "./api_ada";

export const clients_all = () => {
    return api_ada.get('client/all_clients')
}

export const list_all_alarms = (data) => {
    return api_ada.post('alarm_note/list_all', data)
}

export const list_alarms_by_sector = (data) => {
    return api_ada.post('alarm_note/list_by_sector', data)
}

export const create_alarms = (data) => {
    return api_ada.post('alarm_note/create', data)
}

export const create_alarms_v2 = (data) => {
    return api_ada.post('alarm_note/create/v2', data)
}

export const delete_alarms = (data) => {
    return api_ada.post('alarm_note/delete', data)
}

export const update_alarms = (data) => {
    return api_ada.put('alarm_note/update', data)
}

export const update_alarms_v2 = (data) => {
    return api_ada.put('alarm_note/update/v2', data)
}

export const update_devices = (data) => {
    return api_ada.put("/events", data);
};

export const note_by_id_alarms = (data) => {
    return api_ada.post('alarm_note/note_by_id', data)
}

export const type_alarms = () => {
    return api_ada.get('alarm_type/types')
}

export const create_type_alarms = (data) => {
    return api_ada.post('alarm_type/create', data)
}

export const delete_type_alarms = (data) => {
    return api_ada.delete('alarm_type/delete', data)
}

export const update_type_alarms = (data) => {
    return api_ada.put('alarm_type/update', data)
}