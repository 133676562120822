import styled, {css} from "styled-components";
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Dropdown } from "react-bootstrap";

const activeButtonStyles = css`
  background-color: ${props => props.theme.colors.primary}; ;
  border-color: ${props => props.theme.colors.primary}; ;
`;

export const TitleCardSeen = styled.span`
    font-size: 16px;
    margin-right: 10px;
`;

export const ContainerCardBottom = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
`;

export const ContainerCardButtons = styled.div`
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
`;

export const CustomButton = styled(Button)`
  height: 32px;
  &.p-component .p-component{
    border-color: white;
  }
`;

export const CustomCard = styled(Card)`
 
  width: 22rem;
    cursor: initial;
    .p-card-content{
        padding: 0 !important;
    }
`;

export const IconContainer = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
`;

export const CardSection = styled.section`
    display: flex;
    height: 100%;
    margin-left: 20px;
`;

export const CardSpacer = styled.div`
    width: 20%;
`;

export const CardContentColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
`;

export const CardBoldText = styled.span`
    font-size: 14px;
    font-weight: bold;
`;

export const CardRightColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`;

export const CheckboxContainer = styled.div`
    display: flex;
    gap: 4px;
`;

export const CustomToggle = styled(Dropdown.Toggle)`
 padding: 0.5rem 1rem;
  background-color: ${props => props.theme.colors.primary}; 
  color: #fff;
  border: none;
  border-color: ${props => props.theme.colors.primary}; 

  &::after {
    display: none;
    background-color: ${props => props.theme.colors.primary}; 
  }

  & ::before {
    background-color: ${props => props.theme.colors.primary}; 
  }

  &.btn-primary {
    background-color: ${props => props.theme.colors.primary}; 
  }

  &:hover {
    background-color: ${props => props.theme.colors.primary}; 
    color: #fff; 
  }

  &.show {
    background-color: ${props => props.theme.colors.primary}; 
    color: #fff;
  }

  &:checked {
    ${activeButtonStyles}
  }

  &:focus {
    background-color: ${props => props.theme.colors.primary}; 
    border-color: ${props => props.theme.colors.primary}; 
  }

  &:active {
    background-color: ${props => props.theme.colors.primary}; 
    border-color: ${props => props.theme.colors.primary}; 
  }

  &:disabled {
    background-color: ${props => props.theme.colors.primary}; 
    border-color: ${props => props.theme.colors.primary}; 
  }
`;

export const CustomMenu = styled(Dropdown.Menu)`
    margin-top: 18px;
    border: 1px solid grey; 
    border-radius: 0%;
    margin-right: 16%;
    right: -21%;
  &::before {
    content: "";
    position: absolute;
    top: -20px; 
    left: 92%; 
    transform: translateX(-50%);
    border-width: 12px; 
    border-style: solid;
    border-color: transparent transparent grey transparent; 
  }
`;
