import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
  border: ${(props) => (props.$buttonGroup ? 'solid 1px rgb(128, 134, 192)' : 'solid 1px black')};
  padding: 3px 12px;
  border-radius: 10px;
  background: ${(props) => (props.$buttonGroup ? 'rgb(128, 134, 192)' : '#fff')};
  cursor: pointer;
`;
