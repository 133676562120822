import styled, { css, keyframes } from "styled-components";
import { MultiSelect } from "primereact/multiselect";

export const StyledMultiSelect = styled(MultiSelect)`
  flex-grow: 1;
  width: 100%;

  .p-multiselect-items-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .p-multiselect-item {
    flex: 1 1 calc(33.333% - 0.5rem);
    box-sizing: border-box;
  }
`;

export const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoadingIcon = styled.span`
display: inline-block;
width: 16px;
height: 16px;
border: 2px solid #ccc;
border-top: 2px solid #007bff;
border-radius: 50%;
animation: ${spin} 1s linear infinite;
margin-left: 8px; /* Espaço entre o texto e o ícone */
`;


// Animação de rotação
const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// Container do loading
export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 14px;
  color: #333;
`;

// Ícone de loading
export const LoadingIconSelect = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

// Spinner (ícone de carregamento)
export const Spinner = styled.svg`
  animation: ${rotate} 1s linear infinite;
  width: 24px;
  height: 24px;

  circle {
    fill: none;
    stroke-width: 5;
  }
`;