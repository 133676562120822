import styled from "styled-components";

export const Container = styled.div`
  padding: 1px 3px 23px 3px;
  background-color: rgb(230, 230, 230);
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleButton = styled.div`
  align-self: center;
  margin: auto;
`;

export const ButtonSelected = styled.button`
  background-color: #6eff94;
  cursor: auto;
  padding-left: 5px;
  padding-right: 5px;
  height: 22px;
  border: 1px solid #6eff94;
  align-self: center;
`;

export const ButtonNoSelected = styled.button`
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid #6eff94;
  height: 22px;
  align-self: center;
`;

export const Chart = styled.div`
  background-color: #fff;
  max-height: 21vh;
  max-width: 49vw;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.$data.labels.length > 0 ? "max-height: 21vh" : "height: 21vh"}
`;

export const ChartAdaPlus = styled.div`
  background-color: #fff;
  max-height: 34.2vh;
  max-width: 49vw;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36.8vh;
  ${(props) =>
    props.$data.labels.length > 0 ? "max-height: 34vh" : "height: 30vh"};

  @media (max-height: 729px) {
    max-height: 32.5vh;
  }
  }
`;

export const ContainerReset = styled.div`
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
  height: 28px;
  margin-right: 20px;
  align-self: center;
  user-select: none;
`;

export const Content = styled.div`
  display: flex;
  > label {
    margin-left: 10px;
    margin-bottom: 5px;
  }

  > span {
    font-size: 15px;
  }
`;

export const SettingsContainerList = styled.div`
  max-height: 250px;
  width: 250px;
  overflow-y: auto;
  margin-top: 10px;
`;
