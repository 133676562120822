import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { IntlProvider } from 'react-intl';
import enMessages from './locales/en.json';
import esMessages from './locales/es.json';
import ptMessages from './locales/pt.json';
import { AuthProvider } from './hooks/auth';
import { fetchDataFromAPIRealms } from './realms/realms';

import { initKeycloak, keycloak } from './keycloak';

const messages = {
  en: enMessages,
  es: esMessages,
  pt: ptMessages
};

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const initializeApp = async () => {
  try {
    await initKeycloak();
    await fetchDataFromAPIRealms();
    await delay(500); 
  } catch (error) {
    console.error('Error fetching data from API Realms:', error);
  }

  const listLocalStorage = localStorage.getItem('realms-list-stt4');
  
  if (!listLocalStorage) {
    console.log('No realms list found, reloading...');
    window.location.reload();
  } else {
    const aux = navigator.language.split(/[-_]/)[0];
    const language = aux === 'pt' ? 'pt' : aux === 'es' ? 'es' : aux === 'en' ? 'en' : 'en';
    const defaultLocale = 'en';

    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <React.StrictMode>
        <IntlProvider locale={language} messages={messages[language]} defaultLocale={defaultLocale}>
          <AuthProvider>
            <App />
          </AuthProvider>
        </IntlProvider>
      </React.StrictMode>
    );

    reportWebVitals();
  }
};

initializeApp();

