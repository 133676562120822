import styled from 'styled-components';

export const ScrollContainer = styled.div`
  overflow-y: auto;
  height: ${props => props.height || '400px'};
  width: ${props => props.width || '100%'};
  
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const EmptyMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  color: #6c757d;
  font-size: 1rem;
  text-align: center;
  background-color: #f8f9fa;
  border-radius: 4px;
  margin: 1rem 0;
`; 