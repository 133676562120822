import styled from 'styled-components';

export const Container = styled.div`
position: absolute;
background: #000000dd;
color: black;
right: 0;
top: calc(64vh - 111px);
width: ${props => (props.$isVisible ? '235px' : '27px')};
font-size: 10px;
padding: 5px;
transition: width 0.3s ease;
overflow: hidden;
min-height: 30px;
`;

export const Paragraph = styled.p`
text-align: center;
font-size: 10px;
font-weight: bold;
margin-bottom: 2px;
color: white;
`;

export const Item = styled.div`
background-color: ${props => props.$bgColor};
color: ${props => props.$textColor};
padding: 3px;
white-space: nowrap;
`;

export const Icon = styled.img`
width: 16px;
height: 16px;
margin-right: 8px;
`;

export const ToggleButton = styled.button`
  position: absolute;
  left: 0;
  top: 0;
  background: #333;
  color: white;
  border: none;
  padding: 2px 5px;
  cursor: pointer;
  font-size: 10px;
  z-index: 10;
`;