import api_ada from "./api_ada";

export const allSectors = (data)  => {
    return api_ada.post('sector/all/v2',data)
    
}

export const allSectorsInformation = (data) => {
  return api_ada.post("sector/all/information", data);
};

export const sectorCreate = (data)  => {
    return api_ada.post('sector/create/v2', data)
}

export const sectorUpdate = (data)  => {
    return api_ada.put('sector/update/v2', data)
}

export const sectorDelete = (data) => {
    return api_ada.post('sector/delete/v2', data)
}

export const general_infos = (data) => {
  return api_ada.post("sector/general_infos", data);
};

export const scheme = (data) => {
  return api_ada.post("sector/dvcs_ip_data", data);
};

export const sectorScheme = (data) => {
  return api_ada.post("sector/scheme", data);
};

export const flowResearchFactor = (data) => {
  return api_ada.post(
    "devices_data/sector/flow_data_and_research_factor",
    data
  );
};

export const chart = (data) => {
  return api_ada.post("devices_data/sector/h_load_press_and_map_data", data);
};

export const pressure_data = (data) => {
  return api_ada.post("devices_data/sector/pressure_data", data);
};

export const pressure_data_list_limited = (data) => {
  return api_ada.post("devices_data/sector/pressure_data/serial_number", data);
};

export const pressure_data_list_limited_v2 = (data) => {
  return api_ada.post("devices_data/sector/pressure_data/serial_number/v2", data);
};

export const mvn_data = (data) => {
  return api_ada.post("devices_data/sector/h_load_mvn_chart_data", data);
};

export const map_pins_data = (data) => {
  return api_ada.post("devices_data/sector/map_pins_data", data);
};

export const map_pins_data_v2 = (data) => {
  return api_ada.post("devices_data/sector/map_pins_data/v2", data);
};

export const map_pins_data_heat_map = (data) => {
  return api_ada.post("devices_data/sector/map_pins_max_min_data", data);
};


export const export_mvn_csv = (data) => {
  return api_ada.post("mail_service/mvn_csv", data);
};

export const export_pressure_csv = (data) => {
  return api_ada.post("mail_service/pressure_csv", data);
};

export const export_report = (data) => {
  return api_ada.post("mail_service/send_install_point_report", data);
};

export const export_report_alarms_events = (data) => {
  return api_ada.post("mail_service/send_events_report", data);
};

export const export_report_pressure = (data) => {
  return api_ada.post("mail_service/send_readings_report", data);
};
