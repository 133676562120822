import React, { useState, useRef, useEffect } from 'react';
import { Calendar } from 'primereact/calendar';
import styled from 'styled-components';
import { Button } from 'primereact/button';
import { useIntl } from 'react-intl';
import useDateSelectedStore from '../../store/map/useDateSelectedStore';

export const StyledCalendar = styled(Calendar)`
  .p-datepicker-trigger {
    background-color: ${props => props.theme.colors.primary};
    color: #ffffff;
    border: none;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #8086C0;
    }

    &:active {
      background-color: ${props => props.theme.colors.primary};
    }
  }
`;

const DateRangePicker = ({ onDateChange }) => {
    const intl = useIntl();
    const { dateSelected } = useDateSelectedStore();
    const [periodDateRead, setPeriodDateRead] = useState(null);
    const calendarRef = useRef(null);

    const calculateDateRange = (days) => {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setDate(endDate.getDate() - days);
        return [startDate, endDate];
    };

    useEffect(() => {
        if (dateSelected) {
            const days = (parseInt(dateSelected, 10) - 1);
            if (!isNaN(days)) {
                const range = calculateDateRange(days);
                setPeriodDateRead(range);
                if (onDateChange) {
                    onDateChange(range);
                }
            }
        }
    }, [dateSelected]);

    const setDateRange = (days) => {
        const range = calculateDateRange(days);
        setPeriodDateRead(range);

        if (onDateChange) {
            onDateChange(range);
        }

        if (calendarRef.current) {
            calendarRef.current.hide();
        }
    };

    const footerTemplate = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '0.5rem', flexWrap: 'wrap'}}>
                    <Button label={`7 ${intl.formatMessage({ id: 'days' })}`} onClick={() => setDateRange(7)} className="p-button-text p-button-sm" style={{color: '#8086C0'}} />
                    <Button label={`15 ${intl.formatMessage({ id: 'days' })}`} onClick={() => setDateRange(15)} className="p-button-text p-button-sm" style={{color: '#8086C0'}} />
                    <Button label={`30 ${intl.formatMessage({ id: 'days' })}`} onClick={() => setDateRange(30)} className="p-button-text p-button-sm" style={{color: '#8086C0'}}/>
                    <Button label={`60 ${intl.formatMessage({ id: 'days' })}`} onClick={() => setDateRange(60)} className="p-button-text p-button-sm" style={{color: '#8086C0'}}/>
                    <Button label={`90 ${intl.formatMessage({ id: 'days' })}`} onClick={() => setDateRange(90)} className="p-button-text p-button-sm" style={{color: '#8086C0'}}/>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '0.5rem' }}>
                    <Button label={intl.formatMessage({ id: 'button_today' })}  onClick={() => setDateRange(0)} className="p-button-text p-button-sm" style={{color: '#8086C0'}}/>
                    <Button
                        label={intl.formatMessage({ id: 'cancel_button' })} 
                        onClick={() => {
                            if (calendarRef.current) {
                                calendarRef.current.hide();
                            }
                        }}
                        severity='danger'
                        className="p-button-text p-button-sm"
                    />
                </div>
            </div>
        );
    };


    return (
        <div>
            <StyledCalendar
                ref={calendarRef}
                value={periodDateRead}
                onChange={(e) => {
                    setPeriodDateRead(e.value);
                    if (onDateChange) {
                        onDateChange(e.value);
                    }
                }}
                selectionMode="range"
                readOnlyInput
                showIcon
                dateFormat="dd/mm/y"
                locale={intl?.locale}
                footerTemplate={footerTemplate}
            />
        </div>
    );
};

export default DateRangePicker;