import React from 'react'
import { ThemeProvider } from 'styled-components'
import { GlobalStyles } from './styles/GlobalStyles';
import light from './styles/themes/light';
import Routes from './routes'

import { LoadScript } from '@react-google-maps/api';
import { GOOGLE_MAPS_KEY } from "./config/config";

const App = () => {

  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAPS_KEY} libraries={['drawing', 'visualization']}>
      <ThemeProvider theme={light}>
        <Routes />
        <GlobalStyles />
      </ThemeProvider>
    </LoadScript>
  )
}

export default App
