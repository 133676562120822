import React, { useEffect, useState, memo } from 'react';
import { GoogleMap, Marker, MarkerClusterer } from '@react-google-maps/api';
import { mapStyle } from '../../../utils/MapStyles';

const containerStyle = {
  width: '100%',
  height: '90vh'
};

const mapOptions = {
  zoomControl: false, 
  fullscreenControl: true, 
  styles: mapStyle 
};

const center = {
  lat: -25.4924395,
  lng: -49.3116498
};

const circleSvg = `
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
    <circle cx="20" cy="20" r="18" fill="grey" stroke="grey" stroke-width="2" />
  </svg>
`;

const transparentIcon = {
  url: 'https://maps.google.com/mapfiles/ms/icons/white-dot.png',
  scaledSize: window.google && window.google.maps ? new window.google.maps.Size(40, 40) : { width: 40, height: 40 },
  opacity: 0.5
};

const standardIcon = {
  url: `data:image/svg+xml;utf-8,${encodeURIComponent(circleSvg)}`,
  scaledSize: window.google && window.google.maps ? new window.google.maps.Size(40, 40) : { width: 40, height: 40 },
  opacity: 1
};

const FirstMapHomeDevices = ({ points }) => {
  const [pointsMap, setPointsMap] = useState([]);
  const [showClusters, setShowClusters] = useState(false);

  useEffect(() => {
    setPointsMap(points);
  }, [points]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowClusters(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle} 
        center={center}
        zoom={10} 
        options={mapOptions} 
      >
        {showClusters ? (
          <MarkerClusterer>
            {(clusterer) =>
              pointsMap.map((item) => (
                <Marker
                  key={item.id}
                  position={{ lat: item.lat, lng: item.long }}
                  clusterer={clusterer}
                  icon={standardIcon}
                />
              ))
            }
          </MarkerClusterer>
        ) : (
          pointsMap.map((item) => (
            <Marker
              key={item.id}
              position={{ lat: item.lat, lng: item.long }}
              icon={transparentIcon} 
            />
          ))
        )}
      </GoogleMap>
    </>
  );
};

export default memo(FirstMapHomeDevices);