import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import RenderHeatMap from "../HeatMapInstallPointsPlus";
import { useIntl, FormattedMessage } from "react-intl";

import { Container, Column, InfoText, ImageContainer, Image, HeatMapContainer, ModalImage } from "./styles";

const baseUrlS3 = "https://4fluid-iot-comissionamento.s3.sa-east-1.amazonaws.com/";

const RowExpansionTemplate = ({ data, historyCommunicationDevice, selectedDevices, dateStart, dateEnd }) => {
   const intl = useIntl();
  const deviceId = data.devices[0].id;
  const deviceHistory = historyCommunicationDevice[deviceId];
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const openModal = (imageUrl) => {
    setModalImage(imageUrl);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalImage("");
  };

  return (
    <Container>
      <Column>
        <InfoText>
          <strong><FormattedMessage id="row_expansion_templete_latitude_longitude" />:</strong> {data.lat} / {data.long}
        </InfoText>
        <InfoText>
          <strong><FormattedMessage id="title_header_install_points_id_device" />:</strong> {data?.devices[0]?.serial_number}
        </InfoText>
        <InfoText>
          <strong><FormattedMessage id="title_modal_manager_alarm_plus_address" />:</strong> {data.address}, {data.number}, {data.city}, {data.state}, {data.country}, {data.zipcode}
        </InfoText>
        <InfoText>
          <strong><FormattedMessage id="row_expansion_templete_data_commissioning" />:</strong>{" "}
          {new Date(data?.devices[0]?.commisioning_date).toLocaleString("pt-br")}
        </InfoText>
        <InfoText>
          <strong><FormattedMessage id="row_expansion_templete_data_display_resolution" />:</strong> {data?.devices[0]?.display_resolution}
        </InfoText>
        <InfoText>
          <strong><FormattedMessage id="row_expansion_templete_data_communication_tax_last_seven_days" />:</strong> {data?.devices[0]?.comm_rate ? parseFloat(data.devices[0].comm_rate).toFixed(1) : 'N/A'}
        </InfoText>
      </Column>
      <Column>
        <ImageContainer>
          <InfoText>
            <strong><FormattedMessage id="row_expansion_templete_data_photo_device" />:</strong>
            <Image
              src={`${baseUrlS3 + data?.devices[0]?.dev_image}`}
              alt={ intl.formatMessage({ id: "row_expansion_templete_data_photo_device" })}
              onClick={() => openModal(baseUrlS3 + data?.devices[0]?.dev_image)}
            />
          </InfoText>
          <InfoText>
            <strong><FormattedMessage id="row_expansion_templete_data_photo_installation" />:</strong>
            <Image
              src={`${baseUrlS3 + data?.devices[0]?.set_image}`}
              alt={ intl.formatMessage({ id: "row_expansion_templete_data_photo_installation" })}
              onClick={() => openModal(baseUrlS3 + data?.devices[0]?.set_image)}
            />
          </InfoText>
        </ImageContainer>
      </Column>
      <Column>
        <InfoText>
          <strong><FormattedMessage id="row_expansion_templete_data_calendar_connectivity" />:</strong>
          {deviceHistory && selectedDevices[data.id] && (
            <HeatMapContainer>
              <RenderHeatMap value={deviceHistory} dateOn={dateStart} dateTo={dateEnd} />
            </HeatMapContainer>
          )}
        </InfoText>
      </Column>

      <Modal show={showModal} onHide={closeModal} centered size="lg" backdrop="static" keyboard={false}>
        <Modal.Body style={{ padding: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
          <ModalImage
            src={modalImage}
            alt={intl.formatMessage({ id: "row_expansion_templete_data_photo_amplied" })}
            onClick={closeModal} 
          />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default RowExpansionTemplate;