import styled from 'styled-components';

export const Heatmap = styled.div`
    .domain-background {
        height: auto;
    }
    .graph-legend {
        cursor: pointer;
    }
`;

export const ContainerLegend = styled.div`
  margin-top: 10px;
`;

export const BoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

export const ColoredBox = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 5px;
`;

export const RedBox = styled(ColoredBox)`
  background-color: #ff0000;
`;

export const GreenBox = styled(ColoredBox)`
  background-color: #6eff94;
`;

export const Text = styled.span`
  font-size: 12px;
`;

export const NavigationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
`;

export const Button = styled.button`
  padding: 10px 20px;
  background-color: #6eff94;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color:rgb(126, 223, 138);
  }
`;