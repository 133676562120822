import React from 'react';
import { InfoWindowF } from '@react-google-maps/api';
import { FormattedMessage, useIntl } from 'react-intl';
import { Container, Heading, InfoItem, Button } from './styles';

const InfoWindows = ({ e, selectedMarker, setSelectedMarker, selectedSensor, onSelectSensor }) => {
  const intl = useIntl();
  return (
    <InfoWindowF
      position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
      onCloseClick={() => setSelectedMarker(null)}
      options={{
        pixelOffset: new window.google.maps.Size(0, -45)
      }}
    >
      <Container>
        <Heading><FormattedMessage id="details" /></Heading>
        <InfoItem><strong><FormattedMessage id="location" />:</strong> {e.lat}, {e.lng}</InfoItem>
        <InfoItem><strong><FormattedMessage id="device" />:</strong> {e.device}</InfoItem>
        <InfoItem><strong><FormattedMessage id="point" />:</strong> {e.install_point}</InfoItem>
        <InfoItem><strong><FormattedMessage id="altitude" />:</strong> {e.elevation ? e.elevation.toFixed(2) : 0} <FormattedMessage id="meters" /></InfoItem>
        {e.type !== 'macrometer' && <Button
          className={"btn"}
          style={{ backgroundColor: selectedSensor == e.device ? '#E44C4E' : '#6EFF94', borderColor: 'transparent' }}
          onClick={() => onSelectSensor(e.device)}>
          {selectedSensor == e.device
            ? intl.formatMessage({ id: "deselect" })
            : intl.formatMessage({ id: "select" })}
        </Button>}
      </Container>
    </InfoWindowF>
  );
}

export default InfoWindows;
