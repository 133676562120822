import styled from 'styled-components';
import { Chip } from 'primereact/chip';

export const ModalContainer = styled.div`
  padding: 10px;
`;

export const SectionTitle = styled.h2`
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #333;
`;

export const InfoRow = styled.div`
  margin-bottom: 15px;
  line-height: 1.6;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
`;

export const InfoRowObs = styled.div`
  margin-bottom: 15px;
  line-height: 1.6;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  max-height: 100px; 
  overflow-y: auto; 
  padding-right: 10px; 

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;

`;

export const CheckboxRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const CheckboxLabel = styled.label`
  font-weight: bold;
`;

export const ContentContainer = styled.div`
  display: flex;
  gap: 50px; // Espaço entre as colunas
`;

export const InfoColumn = styled.div`
  flex: 1; // Ocupa o espaço disponível
`;

export const ImageColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const MapContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export const AlarmImage = styled.img`
  width: 100%;
  height: 100%
  max-height: 350px;
  border-radius: 8px;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: flex-end; /* Alinha o botão na parte inferior */
  gap: 10px; /* Espaço entre o textarea e o botão */
  width: 100%;
  margin-bottom: 15px;
`;

export const InputTextareaMsg = styled.textarea`
   width: 100%;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.6;
  resize: vertical; /* Permite redimensionamento vertical */
  max-height: 150px; /* Altura máxima */
  overflow-y: auto; /* Adiciona scroll vertical se necessário */

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  &:focus {
    outline: none;
    border-color: #007bff; /* Cor da borda ao focar */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Sombra ao focar */
  }
`;

export const ButtonMsg = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px; /* Ajuste o padding para um botão quadrado */
  border-radius: 4px;
  font-size: 20px; /* Tamanho maior para o símbolo + */
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px; /* Altura fixa para alinhar com o textarea */

  &:hover {
    background-color: #0056b3;
  }

  &:active {
    background-color: #004080;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ZoomedImage = styled.img`
  width: 80vw;
  height: 85vh;
  object-fit: contain;
  border-radius: 10px;
`;

export const ObservationContainer = styled.div`
  margin-top: 10px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
`;

export const ObservationText = styled.div`
  margin-bottom: 5px;
`;

export const StyledChip = styled(Chip).attrs((props) => ({
  style: {
    backgroundColor: props.$backgroundColor || '#007ad9',
    color: props.$color || 'white',
    fontWeight: 'bold',
    borderRadius: '20px',
    padding: '2px 15px',
    marginLeft: '20px',
  },
}))``;