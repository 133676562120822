import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useIntl, FormattedMessage } from "react-intl";

const ConfirmModal = ({
  visible,
  onHide,
  devices,
  selectedRows,
  sendConfigDevices,
}) => {
  const intl = useIntl();
  const [checkedItems, setCheckedItems] = useState({});

  useEffect(() => {
    const initialCheckedState = devices
      .filter((item) => selectedRows.includes(item.id))
      .reduce((acc, item) => {
        acc[item.id] = true;
        return acc;
      }, {});
    setCheckedItems(initialCheckedState);
  }, [visible, devices, selectedRows]);

  const handleCheckboxChange = (id) => {
    setCheckedItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      style={{ width: "50vw", height: "50vh" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      closable={false}
      header={intl.formatMessage({ id: "config_modal_intall_points_attention" })}
      draggable={false}
      resizable={false}
    >
      <div style={{ padding: '1rem', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
          <strong><FormattedMessage id="config_modal_intall_points_attention_detail" /></strong>
        </div>
        <div style={{ flex: 1, overflowY: 'auto', display: 'flex', justifyContent: 'center' }}>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            {devices
              .filter((item) => selectedRows.includes(item.id))
              .map((item) => (
                <li key={item.id}>
                  <label>
                    <input
                      type="checkbox"
                      checked={checkedItems[item.id] || false}
                      onChange={() => handleCheckboxChange(item.id)}
                      style={{ marginRight: '1.5rem' }}
                    />
                    {item.devices[0].serial_number}
                  </label>
                </li>
              ))}
          </ul>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
          <Button
            onClick={onHide}
            label={intl.formatMessage({ id: "cancel_button" })}
            severity="danger"
          />
          <Button
            onClick={() => {
              onHide();
              const selectedDevices = devices.filter((item) => checkedItems[item.id]);
              sendConfigDevices(selectedDevices);
            }}
            label={intl.formatMessage({ id: "confirm_button" })}
            severity="success"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmModal;