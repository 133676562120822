import React, { useState, useEffect } from 'react'
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import { FormattedMessage } from 'react-intl';
import { MiniCalendar } from '../../MiniCalendar/index';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Message } from 'primereact/message';
import { MdAddCircleOutline, MdOutlineClose } from "react-icons/md";
import {
    ContainerTitleDialog,
    LabelEventsMsg,
    ContainerMsgSuccessDialog,
    ContainerDialog,
    ContainerDialogImg,
    ContainerDialogCheckBox,
    ContainerDialogCheckBoxLabel,
    ContainerDialogCalender,
    ContainerDialogObs,
    ContainerDialogButtons,
    SubContainerDialogButtons,
    DialogButtons,
    CustomInputTextarea,
    CustomCard,
    CustomDialog,
    CustomCardHistory
} from './styles'

const AlarmDialogList = ({ visible, onHide, selectedSeeCheck, selectedAlarms, seen, remove, addBack, textObs, typeObs, saveObs, showMsg, textAlert, historyObs }) => {

    const [flagNewObs, setFlagNewObs] = useState(false);

    useEffect(() => {
        if (!visible) {
            setFlagNewObs(false);
        }
    }, [visible])

    const getDescription = (alarmTypeName) => {

        
        const alarmTypeDescription = {
            "Variação local da pressão acima": "Mistura, manobra do usuário, problemas no bombeamento/booster.",

            "Variação da pressão abaixo": "Fechamento do registro do cavalete, obstrução no ramal, vazamento externo, vazamento interno, fraude, consumo excessivo.",

            "Região com elevação de pressão": "Mistura de setor, manobra operacional, problema do bombeamento/booster.",
          
            "Região com queda de pressão": "mistura de setor, manobra operacional, problema do bombeamento/booster, vazamento, obstrução, quebra de registro de rede.",
        };
        return alarmTypeDescription[alarmTypeName];
    }

    return (
        <div className="flex justify-content-center">
            <CustomDialog header={
                <ContainerTitleDialog>
                    <div>
                        <span><FormattedMessage id="envents" /></span><br />
                        <LabelEventsMsg><FormattedMessage id="events_message" /></LabelEventsMsg>
                    </div>
                    <ContainerMsgSuccessDialog>
                        {showMsg ? <Message severity="success" text={textAlert} /> : <></>}
                    </ContainerMsgSuccessDialog>
                </ContainerTitleDialog>
            } visible={visible} onHide={onHide}>

                <ContainerDialog>
                    <ContainerDialogImg className='col-6'>
                        <img src={selectedAlarms?.alarmNoteImg} style={{ width: '100%' }} />
                    </ContainerDialogImg>
                    <div className='col-6'>
                        <div className='button-edit-custom'>
                            <ContainerDialogCheckBox>
                                <div>
                                    <ContainerDialogCheckBoxLabel><FormattedMessage id="seen" /></ContainerDialogCheckBoxLabel>
                                </div>
                                <Checkbox
                                    checked={selectedSeeCheck}
                                    onChange={e => seen(e.checked, selectedAlarms)}
                                /> 
                            </ContainerDialogCheckBox>
                        </div>
                        <CustomCard>
                            <ContainerDialogCalender>
                                {selectedAlarms && MiniCalendar(new Date(selectedAlarms?.alarmNoteDate))}
                                <div>
                                    <p><FormattedMessage id="identifier" />: {selectedAlarms?.alarmNoteId}</p>
                                    <p><FormattedMessage id="time" />: <span>{selectedAlarms?.alarmNoteDate}</span></p>
                                </div>
                            </ContainerDialogCalender>

                            <p className='font-card-alarms' style={{ marginTop: 30 }}><FormattedMessage id="alarm" />: <span > <FormattedMessage id={`${selectedAlarms?.alarmTypeName}`} /></span></p>
                            <p className='font-card-alarms'><FormattedMessage id="description" />: {selectedAlarms?.completeDescription || getDescription(selectedAlarms?.alarmTypeName)}</p>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span className='font-card-alarms'><FormattedMessage id="obs_alarm" /></span>
                                <CustomCardHistory>
                                    {historyObs && historyObs.map((data, index) => (
                                        <div key={index} style={{ marginBottom: 15 }}>
                                            {data && data.obs && <div style={{ display: 'flex', justifyContent: 'space-around', fontSize: '13px', fontWeight: 'bold' }}>
                                                {data.userName.length > 0 ? <>
                                                    <span>Autor: {data.userName}</span>
                                                    <span>Data: {data.date}</span>
                                                </> : <span>---------------------------------------------------------</span>}
                                            </div>}
                                            {data.obs}
                                        </div>
                                    ))}
                                </CustomCardHistory>
                                <i onClick={() => setFlagNewObs(!flagNewObs)} style={{ cursor: 'pointer', marginLeft: 10 }} >
                                    {flagNewObs ?
                                        <MdOutlineClose size={20} /> :
                                        <MdAddCircleOutline size={20} />
                                    }
                                </i>
                            </div>
                            {flagNewObs && <ContainerDialogObs>
                                <span className='font-card-alarms-new-obs'><FormattedMessage id="new_obs_alarm" /></span>
                                <CustomInputTextarea
                                    autoResize
                                    value={textObs}
                                    onChange={(e) => typeObs(e.target.value)}
                                    rows={1}
                                    cols={50}
                                />
                            </ContainerDialogObs>}
                        </CustomCard>
                        <ContainerDialogButtons>
                            <SubContainerDialogButtons>
                                {selectedAlarms && selectedAlarms.removedByUser === false ?
                                    <DialogButtons className="btn btn-secondary" onClick={() => remove(selectedAlarms)} ><i className="pi pi-folder" /> <FormattedMessage id="remove" /></DialogButtons>
                                    :
                                    <DialogButtons className="btn btn-secondary" onClick={() => addBack(selectedAlarms)} ><i className="pi pi-folder" /> <FormattedMessage id="add_back" /></DialogButtons>
                                }
                            </SubContainerDialogButtons>
                            <SubContainerDialogButtons>
                                <DialogButtons className={textObs.length > 0 ? "btn btn-primary" : "btn btn-primary disabled"} onClick={() => saveObs(selectedAlarms)} ><i className="pi pi-save" /> <FormattedMessage id="obs_save_button" /></DialogButtons>
                            </SubContainerDialogButtons>
                        </ContainerDialogButtons>
                    </div>
                </ContainerDialog>
            </CustomDialog>
        </div>
    )
}

export default AlarmDialogList