import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { MdOutlineFindInPage } from "react-icons/md";
import { MdClear } from "react-icons/md";
import { useIntl, FormattedMessage } from 'react-intl';
import Header from '../../components/Header';
import { listEvents, listSectorsAlarmeds, listCompleteDevices } from '../../services/install_points_plus';
import { Container, CardContainer, Title, ContainerBtnExport, ExportButton, LoadingIcon, StyledMultiSelect } from './styles';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { format, parse } from 'date-fns';
import AlarmModalPlus from '../../components/Modals/ModalManagerAlarmPlus';
import ExportReport from '../../components/ExportReport';
import { Calendar } from 'primereact/calendar';
import { export_report_alarms_events } from '../../services/dashboard';
import { ALARM_TYPE_OPTIONS, DISABLE_DEVICE_ALARMS_FEATURES } from '../../config/config';
import { getDescriptionAlarms } from '../../utils/descriptionAlarms';
import '../../locales/calendarLocales';
import './styles.css';
import { debounce } from "lodash";
import { InputText } from 'primereact/inputtext';

const ManagerAlarmPlus = () => {
  const intl = useIntl();
  const toast = useRef(null);
  const calendarRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState(-1);
  const [sortField, setSortField] = useState("created_at");
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [events, setEvents] = useState([]);
  const [displayModal, setDisplayModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showModalExport, setShowModalExport] = useState(false);
  const [sectorItems, setSectorItems] = useState([]);
  const [selectedOptionsSectorItems, setSelectedOptionsSectorItems] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [devSerialOptions, setDevSerialOptions] = useState([]);
  const [searchSerialNumber, setSearchSerialNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loadingSerialScroll, setLoadingSerialScroll] = useState(false);
  const [serialPage, setSerialPage] = useState(1);
  const disableDeviceAlarmsFeatures = DISABLE_DEVICE_ALARMS_FEATURES =='true';
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    'type': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    'metadata.sectorName': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    'metadata.dev_serial': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    'metadata.alarmTypeName': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    'metadata.alarmNoteId': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    'created_at': {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.BETWEEN },
        { value: null, matchMode: FilterMatchMode.BETWEEN },
      ],
    },
    'metadata.removedByUser': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    'metadata.clientObs[0].date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.BETWEEN }] },
    'metadata.clientObs[0].userName': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    'status': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    'metadata.shortDescription': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    'metadata.seen': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    'created_at': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.BETWEEN }] },
  });

  const [config, setConfig] = useState({
    filters: {
      'type': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      'metadata.sectorName': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      'metadata.dev_serial': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      'metadata.alarmTypeName': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      'metadata.alarmNoteId': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      'created_at': {
        operator: FilterOperator.AND,
        constraints: [
          { value: null, matchMode: FilterMatchMode.BETWEEN },
          { value: null, matchMode: FilterMatchMode.BETWEEN },
        ],
      },
      'metadata.removedByUser': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      'metadata.clientObs[0].date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.BETWEEN }] },
      'metadata.clientObs[0].userName': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      'status': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      'metadata.shortDescription': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      'metadata.seen': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    },
    url: export_report_alarms_events,
  });



  useEffect(() => {
    getSectorsAlarmeds(currentPage, 100);
  }, [])


  useEffect(() => {
    getEvents(currentPage, rowsPerPage, sortField, sortOrder === 1 ? "ASC" : "DESC", filters);
  }, [currentPage, filters, sortField, sortOrder]);

  useEffect(() => {
    const updatedConfig = {
      ...config,
      filters: filters,
    };
    setConfig(updatedConfig);
  }, [filters]);

  useEffect(() => {
    const uniqueDevSerials = [...new Set(events.map(event => event.metadata?.dev_serial).filter(Boolean))];
    const devSerialOptions = uniqueDevSerials.map(serial => ({
      label: serial,
      value: serial,
    }));
    setDevSerialOptions(devSerialOptions);
  }, [events]);

  useEffect(() => {
    if (searchSerialNumber) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const response = await getInstallPointsAlarmeds(1, 10, searchSerialNumber);
          const { items } = response;
          const serialNumbers = items.flatMap(item =>
            item.devices.map(device => device.serial_number)
          );
          const uniqueSerialNumbers = [...new Set(serialNumbers)];
          const devSerialOptions = uniqueSerialNumbers.map(serial => ({
            label: serial,
            value: serial,
          }));
          setDevSerialOptions(devSerialOptions);
        } catch (error) {
          console.error('Error fetching serial numbers:', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    } else {
      setDevSerialOptions([]);
      setIsLoading(false);
    }
  }, [searchSerialNumber]);

  const decodedString = atob(ALARM_TYPE_OPTIONS);
  const parsedOptions = JSON.parse(decodedString);

  const alarmTypeOptions = parsedOptions.map(option => ({
    label: option.labels[intl.locale] || option.labels['en'],
    value: option.value,
  }));

  useEffect(() => {
    setSelectedOptions(alarmTypeOptions.map(option => option.value));
  }, []);

  const typeBooleanOptions = [
    { label: intl.formatMessage({ id: 'manager_alarms_plus_no' }), value: 'false' },
    { label: intl.formatMessage({ id: 'manager_alarms_plus_yes' }), value: 'true' },
  ];

  const formatFiltersForExport = (filters) => {
    const params = {};
    const metadataFilters = [];

    const typeFilter = filters['type']?.constraints[0]?.value;
    if (typeFilter && typeFilter.length > 0) {
      params.type = JSON.stringify({
        criteria: 'in',
        values: Array.isArray(typeFilter) ? typeFilter : [typeFilter],
      });
    }

    const sectorFilter = filters['metadata.sectorName']?.constraints[0]?.value;
    if (sectorFilter) {
      metadataFilters.push({
        criteria: 'in',
        values: sectorFilter,
        field: 'sectorName',
        type_field: 'text',
      });
    }

    const dev_serialFilter = filters['metadata.dev_serial']?.constraints[0]?.value;
    if (dev_serialFilter) {
      metadataFilters.push({
        criteria: 'in',
        values: dev_serialFilter,
        field: 'dev_serial',
        type_field: 'text',
      });
    }

    const alarmTypeNameFilter = filters['metadata.alarmTypeName']?.constraints[0]?.value;
    if (alarmTypeNameFilter) {
      metadataFilters.push({
        criteria: 'contains',
        values: alarmTypeNameFilter,
        field: 'alarmTypeName',
        type_field: 'text',
      });
    }

    const alarmNoteIdFilter = filters['metadata.alarmNoteId']?.constraints[0]?.value;
    if (alarmNoteIdFilter) {
      metadataFilters.push({
        criteria: 'contains',
        values: alarmNoteIdFilter,
        field: 'alarmNoteId',
        type_field: 'text',
      });
    }

    const seenFilter = filters['metadata.seen']?.constraints[0]?.value;
    if (seenFilter !== null && seenFilter !== undefined) {
      metadataFilters.push({
        criteria: 'equals',
        values: seenFilter.toString(),
        field: 'seen',
        type_field: 'text',
      });
    }

    const removedByUserFilter = filters['metadata.removedByUser']?.constraints[0]?.value;
    if (removedByUserFilter !== null && removedByUserFilter !== undefined) {
      metadataFilters.push({
        criteria: 'equals',
        values: removedByUserFilter.toString(),
        field: 'removedByUser',
        type_field: 'text',
      });
    }

    const alarmNoteDateFilter = filters['created_at']?.constraints;
    if (alarmNoteDateFilter?.[0]?.value) {
      const [from, to] = alarmNoteDateFilter[0].value;
      if (from && to) {
        params.from = from;
        params.to = to;
      }
    }

    if (metadataFilters.length > 0) {
      params.metadata = JSON.stringify(metadataFilters);
    }

    return params;
  };

  const getInstallPointsAlarmeds = async (page = 1, perPage = 10, serialNumber) => {
    const serial_number = JSON.stringify({ "criteria": "contains", "values": `${serialNumber}` });

    try {
      const response = await listCompleteDevices({ page, per_page: perPage, alarmed: true, serial_number });
      return response;
    } catch (error) {

    }
  };

  const getSectorsAlarmeds = async (page = 1, perPage = 100) => {
    try {
      const response = await listSectorsAlarmeds({ page, per_page: perPage, only_with_alarms: true });
      const { items } = response;
      setSectorItems(items);
    } catch (error) {

    }
  };

  const onLazyLoad = debounce(async (event) => {
    const { first, last } = event;
    const totalItems = devSerialOptions.length;

    if (last >= totalItems - 50 && !loadingSerialScroll) {
      setLoadingSerialScroll(true);
      try {
        const response = await getInstallPointsAlarmeds(serialPage + 1, 10, searchSerialNumber);
        const { items } = response;
        const serialNumbers = items.flatMap(item =>
          item.devices.map(device => device.serial_number)
        );
        const uniqueSerialNumbers = [...new Set(serialNumbers)];
        const newSerialOptions = uniqueSerialNumbers.map(serial => ({
          label: serial,
          value: serial,
        }));

        setDevSerialOptions(prevOptions => [...prevOptions, ...newSerialOptions]);
        setSerialPage(prevPage => prevPage + 1);
      } catch (error) {
        console.error('Error fetching serial numbers:', error);
      } finally {
        setLoadingSerialScroll(false);
      }
    }
  }, 300);

  const getEvents = async (page = 1, perPage = 10, sortField = null, sortOrder = null, filters = {}) => {
    setLoading(true);
    try {
      const params = {
        page,
        per_page: perPage,
        ...(disableDeviceAlarmsFeatures ? {type: 'alarm_sector'} : {})
      };

      if (sortField && sortOrder) {
        params.order_by = `${sortField} ${sortOrder}`;
      }

      const typeFilter = filters['type']?.constraints[0]?.value;
      if (typeFilter && typeFilter.length > 0) {
        params.type = JSON.stringify({
          criteria: 'in',
          values: Array.isArray(typeFilter) ? typeFilter : [typeFilter],
        });
      }

      // Array para acumular os filtros de metadata
      const metadataFilters = [];

      const sectorFilter = filters['metadata.sectorName']?.constraints[0]?.value;
      if (sectorFilter) {
        metadataFilters.push({
          criteria: 'in',
          values: sectorFilter,
          field: 'sectorName',
          type_field: 'text',
        });
      }

      const dev_serialFilter = filters['metadata.dev_serial']?.constraints[0]?.value;
      if (dev_serialFilter) {
        metadataFilters.push({
          criteria: 'in',
          values: dev_serialFilter,
          field: 'dev_serial',
          type_field: 'text',
        });
      }

      const alarmTypeNameFilter = filters['metadata.alarmTypeName']?.constraints[0]?.value;
      if (alarmTypeNameFilter) {
        metadataFilters.push({
          criteria: 'contains',
          values: alarmTypeNameFilter,
          field: 'alarmTypeName',
          type_field: 'text',
        });
      }

      const alarmNoteIdFilter = filters['metadata.alarmNoteId']?.constraints[0]?.value;
      if (alarmNoteIdFilter) {
        metadataFilters.push({
          criteria: 'contains',
          values: alarmNoteIdFilter,
          field: 'alarmNoteId',
          type_field: 'text',
        });
      }

      const seenFilter = filters['metadata.seen']?.constraints[0]?.value;
      if (seenFilter !== null && seenFilter !== undefined) {
        metadataFilters.push({
          criteria: 'equals',
          values: seenFilter.toString(),
          field: 'seen',
          type_field: 'text',
        });
      }

      const removedByUserFilter = filters['metadata.removedByUser']?.constraints[0]?.value;
      if (removedByUserFilter !== null && removedByUserFilter !== undefined) {
        metadataFilters.push({
          criteria: 'equals',
          values: removedByUserFilter.toString(),
          field: 'removedByUser',
          type_field: 'text',
        });
      }

      // Adiciona todos os filtros de metadata ao params, se houver
      if (metadataFilters.length > 0) {
        params.metadata = JSON.stringify(metadataFilters);
      }

      const statusFilter = filters['status']?.constraints[0]?.value;
      if (statusFilter) {
        params.status = JSON.stringify({
          criteria: 'equals',
          values: statusFilter,
        });
      }

      const alarmNoteDateFilter = filters['created_at']?.constraints;
      if (alarmNoteDateFilter?.[0]?.value) {
        const [from, to] = alarmNoteDateFilter[0].value;
        if (from && to) {
          params.from = from;
          params.to = to;
        }
      }

      const response = await listEvents(params);

      if (!response?.items) {
        toast.current.show({
          severity: 'error',
          summary: 'Atenção',
          detail: 'Resposta da API inválida',
          life: 3000,
        });
        return;
      }

      setTotalRecords(response.total || 0);
      setCurrentPage(response.page || 1);
      setEvents(response.items);
    } catch (error) {
      console.log('Error on getEvents:', error);
      toast.current.show({
        severity: 'error',
        summary: 'Erro ao buscar eventos',
        detail: error.response?.data?.message || 'Erro desconhecido',
        life: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const alarmTypeFilterSector = (options) => {
    const sectorOptions = sectorItems.map(sector => ({
      label: sector.name,
      value: sector.name,
    }));

    return (
      <MultiSelect
        value={selectedOptionsSectorItems}
        options={sectorOptions} // Passa as opções mapeadas
        onChange={(e) => {
          setSelectedOptionsSectorItems(e.value);
          options.filterCallback(e.value);
        }}
        placeholder={intl.formatMessage({ id: 'manager_alarms_plus_filter_by_sector' })}
        showClear
        filter
        emptyFilterMessage={intl.formatMessage({ id: 'empty_filter_message' })}
      />
    );
  };

  const alarmTypeFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={selectedOptions}
        options={alarmTypeOptions}
        onChange={(e) => {
          setSelectedOptions(e.value);
          options.filterCallback(e.value);
        }}
        placeholder={intl.formatMessage({ id: 'manager_alarms_plus_select_alarm_type' })}
        showClear
        emptyFilterMessage={intl.formatMessage({ id: 'empty_filter_message' })}
      />
    );
  };

  const alarmTypeFilterAlarm = (options) => {
    return (
      <InputText
        value={options.value}
        onChange={(e) => {
          options.filterCallback(e.target.value);
        }}
        placeholder={intl.formatMessage({ id: 'manager_alarms_plus_filter_by_alarm' })}
        showclear
        emptyFilterMessage={intl.formatMessage({ id: 'empty_filter_message' })}
      />
    );
  };

  const alarmTypeFilterSerial = (options) => {
    return (
      <StyledMultiSelect
        value={options.value}
        options={devSerialOptions}
        onChange={(e) => options.filterCallback(e.value)}
        onFilter={(e) => {
          setSearchSerialNumber(e.filter);
        }}
        placeholder={intl.formatMessage({ id: 'manager_alarms_plus_filter_by_device' })}
        showClear
        filter
        filterPlaceholder={intl.formatMessage({ id: 'msg_input_filter' })}
        emptyFilterMessage={intl.formatMessage({ id: 'empty_filter_message' })}
        closeIcon={
          isLoading ? (
            <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <LoadingIcon />
            </span>
          ) : (
            <MdClear size={25} />
          )
        }
        virtualScrollerOptions={{
          lazy: true,
          onLazyLoad: onLazyLoad,
          itemSize: 40,
          showLoader: true,
          // loading: loadingSerialScroll,
          delay: 50,
          threshold: '80%',
        }}
      />
    );
  };

  const typeBooleanFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={typeBooleanOptions}
        onChange={(e) => options.filterCallback(e.value)}
        placeholder={intl.formatMessage({ id: 'manager_alarms_plus_select_option' })}
        showClear
      />
    );
  };

  const filterApplyTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-check"
        onClick={() => {
          options.filterApplyCallback();
          setCurrentPage(1);
        }}
        severity="success"
        style={{ backgroundColor: '#87ee97' }}
      />
    );
  };

  const filterClearTemplate = (options) => {
    return (
      <Button
        type="button"
        icon="pi pi-times"
        onClick={() => {
          options.filterClearCallback();
          if (options.field === 'metadata.sectorName') {
            setSelectedOptionsSectorItems([]);
          } 
        }}
        severity="secondary"
      />
    );
  };

  const DateFilterTemplate = (options) => {
    return (
      <Calendar
        ref={calendarRef}
        value={options.value}
        onChange={(e) => {
          options.filterCallback(e.value);
          const date = e.value.filter(item => item !== null)
          if (date?.length === 2) {
            calendarRef.current.hide();
          }
        }}
        showIcon
        selectionMode="range"
        readOnlyInput
        dateFormat="dd/mm/yy"
        locale={intl?.locale}
      />
    );
  };

  const alarmTypeFilterIdentifier = (options) => {
    return (
      <InputText
        value={options.value}
        onChange={(e) => options.filterCallback(e.target.value)}
        placeholder={intl.formatMessage({ id: 'manager_alarms_plus_filter_by_identifier' })}
        showclear
        emptyFilterMessage={intl.formatMessage({ id: 'empty_filter_message' })}
      />
    );
  };
  
  const columns = [
    !disableDeviceAlarmsFeatures && {
      field: 'type',
      header: intl.formatMessage({ id: 'manager_alarms_plus_type_alarm' }),
      filterField: 'type',
      filterElement: alarmTypeFilterTemplate,
      body: (rowData) => {
        if (rowData.type === 'alarm_sector') {
          return intl.formatMessage({ id: 'manager_alarms_plus_sector_alarm' });
        } else if (rowData.type === 'alarm_device') {
          return intl.formatMessage({ id: 'manager_alarms_plus_device_alarm' });
        }
        return '-';
      },
    },
    {
      field: 'metadata.sectorName',
      header: intl.formatMessage({ id: 'manager_alarms_plus_sector' }),
      filterField: 'metadata.sectorName',
      filterElement: alarmTypeFilterSector,
      filterPlaceholder: intl.formatMessage({ id: 'manager_alarms_plus_filter_by_sector' }),
      body: (rowData) => rowData.metadata?.sectorName || '-',
    },
    !disableDeviceAlarmsFeatures &&  {
      field: 'metadata.dev_serial',
      header: intl.formatMessage({ id: 'manager_alarms_plus_device' }),
      filterField: 'metadata.dev_serial',
      filterElement: alarmTypeFilterSerial,
      filterPlaceholder: intl.formatMessage({ id: 'manager_alarms_plus_filter_by_device' }),
      body: (rowData) => rowData.metadata?.dev_serial || '-',
    },
    {
      field: 'metadata.alarmTypeName',
      header: intl.formatMessage({ id: 'manager_alarms_plus_alarm' }),
      filterField: 'metadata.alarmTypeName',
      filterElement: alarmTypeFilterAlarm,
      filterPlaceholder: intl.formatMessage({ id: 'manager_alarms_plus_filter_by_alarm' }),
      body: (rowData) => rowData.metadata?.alarmTypeName || '-',
    },
    {
      field: 'metadata.alarmNoteId',
      header: intl.formatMessage({ id: 'manager_alarms_plus_identifier' }),
      filterField: 'metadata.alarmNoteId',
      filterElement: alarmTypeFilterIdentifier,
      filterPlaceholder: intl.formatMessage({ id: 'manager_alarms_plus_filter_by_identifier' }),
      body: (rowData) => rowData.metadata?.alarmNoteId || '-',
    },
    {
      field: 'created_at',
      header: intl.formatMessage({ id: 'manager_alarms_plus_date' }),
      filterField: 'created_at',
      filterElement: DateFilterTemplate,
      sortableElement: true,
      filterPlaceholder: intl.formatMessage({ id: 'manager_alarms_plus_filter_by_date' }),
      body: (rowData) => {
        if (!rowData.created_at) return '-';
        const formattedDate = format(new Date(rowData.created_at), 'dd/MM/yyyy');
        return formattedDate;
      },
    },
    {
      field: 'metadata.seen',
      header: intl.formatMessage({ id: 'manager_alarms_plus_seen' }),
      body: (rowData) => (rowData.metadata?.seen === true ? intl.formatMessage({ id: 'manager_alarms_plus_yes' }) : intl.formatMessage({ id: 'manager_alarms_plus_no' })),
      filterField: 'metadata.seen',
      filterElement: typeBooleanFilterTemplate,
      filterPlaceholder: intl.formatMessage({ id: 'manager_alarms_plus_filter_by_seen' }),
    },
    {
      field: 'metadata.removedByUser',
      header: intl.formatMessage({ id: 'manager_alarms_plus_archived' }),
      body: (rowData) => (rowData.metadata?.removedByUser === true ? intl.formatMessage({ id: 'manager_alarms_plus_yes' }) : intl.formatMessage({ id: 'manager_alarms_plus_no' })),
      filterField: 'metadata.removedByUser',
      filterElement: typeBooleanFilterTemplate,
      filterPlaceholder: intl.formatMessage({ id: 'manager_alarms_plus_filter_by_archived' }),
    },
    {
      field: 'metadata.clientObs[0].date',
      header: intl.formatMessage({ id: 'manager_alarms_plus_last_obs' }),
      body: (rowData) => {
        if (rowData.metadata?.clientObs?.length > 0) {
          const dateString = rowData.metadata.clientObs[0].date;
          const parsedDate = parse(dateString, 'dd-MM-yyyy', new Date());

          if (parsedDate instanceof Date && !isNaN(parsedDate)) {
            const formattedDate = format(parsedDate, 'dd/MM/yyyy');
            return formattedDate;
          }
        }
        return '-';
      },
    },
    {
      field: 'metadata.clientObs',
      header: intl.formatMessage({ id: 'manager_alarms_plus_last_user' }),
      body: (rowData) => {
        if (rowData.metadata?.clientObs?.length > 0) {
          return rowData.metadata.clientObs[0].userName;
        }
        return '-';
      },
    },
    {
      field: 'metadata.shortDescription',
      header: intl.formatMessage({ id: 'manager_alarms_plus_description' }),
      body: (rowData) => {
        if (rowData.type === 'alarm_device') {
          return rowData.description || '-';
        }
    
        if (rowData.metadata && rowData.metadata.alarmTypeName) {
          const typeName = rowData.metadata.alarmTypeName;
          const description = getDescriptionAlarms(typeName) || rowData.metadata?.shortDescription;
          return description ? (description.length > 35 ? description.slice(0, 35) + '...' : description) : '-';
        }
  
        return '-';
      },
    }
  ];

  const onSort = (e) => {
    const sortOrderForApi = e.sortOrder === 1 ? "ASC" : "DESC";
    setSortField(e.sortField || "created_at");
    setSortOrder(e.sortOrder);
  };

  const onPage = (e) => {
    setCurrentPage(e.page + 1);
    setRowsPerPage(e.rows);
  };

  const handleClose = () => {
    setShowModalExport(false);
  };


  const handleModalExportReport = () => {
    if (events.length > 0) {
      const formattedFilters = formatFiltersForExport(filters);
      const exportConfig = {
        ...config,
        filters: formattedFilters
      };
      setConfig(exportConfig);
      setShowModalExport(true);
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Atenção",
        detail: "Não há lista para exportar.",
        life: 3000,
      });
    }
  };

  return (
    <Container>
      <Header />

      <ContainerBtnExport>
        <Title><FormattedMessage id="manager_alarms_plus_list_alarms" /></Title>
        <ExportButton onClick={handleModalExportReport}>
          <FormattedMessage id="save_reports" />
        </ExportButton>
      </ContainerBtnExport>

      <ExportReport
        onShow={showModalExport}
        onClose={handleClose}
        config={config}
      />

      <AlarmModalPlus visible={displayModal} onHide={() => setDisplayModal(false)} rowData={selectedRowData} onLoad={() => getEvents(currentPage, rowsPerPage, sortField, sortOrder === 1 ? "ASC" : "DESC", filters)} />
      <CardContainer>
        <Toast ref={toast} />
        <DataTable
          tableStyle={{ minWidth: '50rem' }}
          value={events}
          loading={loading}
          paginator
          lazy
          rows={rowsPerPage}
          first={(currentPage - 1) * rowsPerPage}
          totalRecords={totalRecords}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          dataKey="id"
          filters={filters}
          onFilter={(e) => setFilters(e.filters)}
          emptyMessage={intl.formatMessage({ id: 'no_alarm_message' })}
          currentPageReportTemplate={intl.formatMessage({ id: 'current_pagereport_template_data_table' })}
          onSort={onSort}
          sortField={sortField}
          sortOrder={sortOrder}
          onPage={onPage}
        >
          {columns.map((col, index) => (
            <Column
              key={index}
              field={col.field}
              header={col.header}
              body={col.body}
              filter={col.filterField}
              showAddButton={false}
              showFilterMatchModes={false}
              showFilterOperator={false}
              filterMenuStyle={{ width: '10rem' }}
              filterClear={filterClearTemplate}
              filterApply={filterApplyTemplate}
              filterElement={col.filterElement}
              sortable={col.sortableElement}
            />
          ))}
          <Column
            header={intl.formatMessage({ id: 'manager_alarms_plus_view' })}
            body={(rowData) => (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <MdOutlineFindInPage
                  size={25}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setSelectedRowData(rowData);
                    setDisplayModal(true);
                  }}
                />
              </div>
            )}
          />
        </DataTable>
      </CardContainer>
    </Container>
  );
};

export default ManagerAlarmPlus;