import styled from 'styled-components';

export const ButtonGroup = styled.div`
  display: flex;
  height: 40px; 
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  width: 200px;
`;

export const Button = styled.button`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: ${(props) => (props.selected ? '#8086C0' : '#f0f0f0')};
  color: ${(props) => (props.selected ? '#fff' : '#000')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')}; // Altera o cursor para desabilitado
  transition: background-color 0.3s, color 0.3s;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)}; // Reduz a opacidade para botões desabilitados

  &:not(:last-child) {
    border-right: 1px solid #ccc;
  }

  &:hover {
    background-color: ${(props) =>
      props.disabled
        ? (props.selected ? '#8086C0' : '#f0f0f0') // Mantém a cor original no hover se desabilitado
        : (props.selected ? '#8096C0' : '#ddd')}; // Aplica hover apenas se não estiver desabilitado
  }
`;