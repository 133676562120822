import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { GoogleMap, Marker, HeatmapLayer, DrawingManager } from '@react-google-maps/api';
import { ControlPanel, LegendContainer, LegendLabel, ColorScale, CustomInputSwitch, StyledInputSwitch, GearIcon, Input, Button, InputContainer, ToggleContainer, ToggleLabel, ToggleSwitch, ToggleInput, ToggleSlider } from './styles';
import CustomHeatMapMenu from '../../Menu/CustomHeatMapMenu';
import { ButtonDraw } from './styles';
import { Toast } from 'primereact/toast';
import { useIntl, FormattedMessage } from 'react-intl';
import { mapStyle } from '../../../utils/MapStyles';

import { MdOutlineDraw, MdOutlineBackHand } from "react-icons/md";
import { FaEraser } from "react-icons/fa";

import { usePressureStore } from '../../../store/heatmap/usePressureStore';

const defaultCenter = {
  lat: -25.48,
  lng: -49.27,
};

const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

const useMapZoom = (devices, mapRef) => {
  const initialFitDoneRef = useRef(false);

  const fitMapToDevices = useCallback((devicesList) => {
    if (!window?.google?.maps || !mapRef.current || !devicesList.length) return;

    const bounds = new window.google.maps.LatLngBounds();
    let validDevicesFound = false;

    devicesList.forEach(device => {
      if (device.lat && device.long && !isNaN(device.lat) && !isNaN(device.long)) {
        bounds.extend(new window.google.maps.LatLng(device.lat, device.long));
        validDevicesFound = true;
      }
    });

    if (validDevicesFound) {
      mapRef.current.fitBounds(bounds, { padding: 100 });
      if (mapRef.current.getZoom() > 14) {
        mapRef.current.setZoom(14);
      }
    }
  }, [mapRef]);

  useEffect(() => {
    if (mapRef.current && devices && devices.length > 0 && !initialFitDoneRef.current) {
      fitMapToDevices(devices);
      initialFitDoneRef.current = true;
    }
  }, [devices, fitMapToDevices, mapRef]);

  return {
    fitMapToDevices,
    initialFitDoneRef
  };
};

const getValidNumber = (value, defaultValue = 0) => {
  const parsedValue = parseFloat(value);
  return isNaN(parsedValue) ? defaultValue : parsedValue;
};

const formatLegendValue = (value) => {
  if (value === Infinity || value === -Infinity || isNaN(value)) {
    return '';
  }
  return value.toFixed(2);
};

const MapHeatMapComponent = ({ devices, onDevicesFiltered, mapCenter, typeValue, maxMinValue, pointSelected }) => {
  const intl = useIntl();
  const toast = useRef(null);
  const [showMarkers, setShowMarkers] = useState(false);
  const [polygon, setPolygon] = useState(null);
  const [polygons, setPolygons] = useState([]);
  const [drawingMode, setDrawingMode] = useState(null);
  const drawingManagerRef = useRef(null);
  const mapRef = useRef(null);
  const { minPressure, maxPressure } = usePressureStore();
  const [devicesFiltereds, setDevicesFiltereds] = useState([]);

  const { fitMapToDevices, initialFitDoneRef } = useMapZoom(devices, mapRef);

  const [expanded, setExpanded] = useState(false);
  const [maxValue, setMaxValue] = useState(maxPressure || 0);
  const [minValue, setMinValue] = useState(minPressure || 0);
  const [autoMode, setAutoMode] = useState(false);

  function normalizeValue(value, maxValue, minValue) {
    const normalized = (value - minValue) / (maxValue - minValue);
    let adjusted = 0 + normalized * 1;
    adjusted = Math.min(Math.max(adjusted, 0), 1);

    return adjusted;
  }

  const heatmapData = useMemo(() => {
    if (!devices || !Array.isArray(devices) || devices.length === 0) {
      return [];
    }

    const max = autoMode ? maxMinValue.max : maxValue;
    const min = autoMode ? maxMinValue.min : minValue;

    return devices
      .filter(device => device && device.lat && device.long && !isNaN(device.lat) && !isNaN(device.long))
      .map(device => ({
        location: new window.google.maps.LatLng(parseFloat(device.lat), parseFloat(device.long)),
        weight: normalizeValue(device[typeValue], max, min),
      }));
  }, [devices, autoMode, maxMinValue, maxValue, minValue, typeValue]);

  const legendMaxValue = formatLegendValue(autoMode ? maxMinValue.max : maxValue);
  const legendMinValue = formatLegendValue(autoMode ? maxMinValue.min : minValue);

  const greenMarkerIcon = {
    url: 'https://maps.gstatic.com/mapfiles/ms2/micons/green.png',
    scaledSize: new window.google.maps.Size(40, 40),
  };


  const mapOptions = {
    zoomControl: false,
    styles: mapStyle,
    fullscreenControl: true,
    scaleControl: true,
    scaleControlOptions: {
      position: window.google.maps.ControlPosition.BOTTOM_CENTER
    },
  };

  const heatmapGradient = [
    'rgba(0, 255, 255, 0)',
    'rgba(0, 255, 255, 1)',
    'rgba(0, 191, 255, 1)',
    'rgba(0, 127, 255, 1)',
    'rgba(0, 63, 255, 1)',
    'rgba(0, 0, 255, 1)',
    'rgba(255, 255, 0, 1)',
    'rgba(255, 127, 0, 1)',
    'rgba(255, 0, 0, 1)'
  ];

  const handleStartDrawing = () => {
    if (drawingManagerRef.current) {
      drawingManagerRef.current.setMap(mapRef.current);
      drawingManagerRef.current.setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON);
      setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON);
    }
  };

  const handleFilterDevices = () => {
    if (!Array.isArray(polygons) || polygons.length === 0 || !Array.isArray(devices) || devices.length === 0) {
      if (typeof onDevicesFiltered === 'function') {
        onDevicesFiltered([]);
      }
      return;
    }

    filterDevicesInPolygons(polygons);
  };

  const handleClearDrawing = () => {
    try {
      if (Array.isArray(polygons)) {
        polygons.forEach(poly => {
          if (poly) {
            poly.setMap(null);
          }
        });
      }

      setPolygons([]);
      setPolygon(null);
      setDevicesFiltereds([]);

      if (drawingManagerRef.current) {
        drawingManagerRef.current.setDrawingMode(null);
        drawingManagerRef.current.setMap(null);
        setDrawingMode(null);
      }

      if (typeof onDevicesFiltered === 'function') {
        onDevicesFiltered([]);
      }
    } catch (error) {
      console.error("Error clearing drawing:", error);
    }
  };

  const onPolygonComplete = (poly) => {
    if (drawingManagerRef.current) {
      drawingManagerRef.current.setDrawingMode(null);
      setDrawingMode(null);
    }

    const newPolygon = poly;

    window.google.maps.event.addListener(newPolygon.getPath(), 'set_at', () => {
      handlePolygonEdit();
    });

    window.google.maps.event.addListener(newPolygon.getPath(), 'insert_at', () => {
      handlePolygonEdit();
    });

    window.google.maps.event.addListener(newPolygon.getPath(), 'remove_at', () => {
      handlePolygonEdit();
    });

    setPolygon(newPolygon);
    setPolygons(prevPolygons => {
      const updatedPolygons = [...prevPolygons, newPolygon];

      if (typeof onDevicesFiltered === 'function') {
        filterDevicesInPolygons(updatedPolygons);
      }

      return updatedPolygons;
    });
  };

  const handlePolygonEdit = () => {
    setPolygons(prevPolygons => {
      if (typeof onDevicesFiltered === 'function') {
        filterDevicesInPolygons(prevPolygons);
      }
      return prevPolygons;
    });
  };

  const filterDevicesInPolygons = (polygonArray) => {
    try {
      const filteredDevices = devices.filter(device => {
        if (!device || !device.lat || !device.long || isNaN(device.lat) || isNaN(device.long)) {
          return false;
        }

        const deviceLatLng = new window.google.maps.LatLng(parseFloat(device.lat), parseFloat(device.long));
        return polygonArray.some(polygon => {
          if (!polygon) return false;
          try {
            return window.google.maps.geometry.poly.containsLocation(deviceLatLng, polygon);
          } catch (error) {
            console.error("Error checking if device is inside polygon:", error);
            return false;
          }
        });
      });
      setDevicesFiltereds(filteredDevices);
      onDevicesFiltered(filteredDevices);
    } catch (error) {
      console.error("Error filtering devices:", error);
      onDevicesFiltered([]);
    }
  };


  const toggleDrawingMode = () => {
    if (polygon) {
      return;
    }

    if (drawingManagerRef.current) {
      drawingManagerRef.current.setMap(mapRef.current);
      drawingManagerRef.current.setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON);
      setDrawingMode(drawingMode === 'polygon' ? null : 'polygon');
    }
  };

  const handleToggle = () => {
    setAutoMode(!autoMode);
  };

  const selectedDeviceMarker = useMemo(() => {
    if (!pointSelected || !pointSelected.serialNumber || !devicesFiltereds || !devicesFiltereds.length) {
      return null;
    }

    const foundDevice = devicesFiltereds.find(device =>
      device.serialNumber === pointSelected.serialNumber
    );

    if (!foundDevice) return null;

    return {
      position: { lat: foundDevice.lat, lng: foundDevice.long },
      device: foundDevice
    };
  }, [pointSelected, devicesFiltereds]);

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <Toast ref={toast} />
      <ControlPanel>
        <CustomInputSwitch>
          <StyledInputSwitch
            type="checkbox"
            checked={showMarkers}
            onChange={() => setShowMarkers(!showMarkers)}
            size="small"
          />
          <span><FormattedMessage id="title_heat_map_switch_devices" /></span>
        </CustomInputSwitch>
      </ControlPanel>

      <LegendContainer>
        {legendMaxValue !== '' && <LegendLabel>{legendMaxValue}</LegendLabel>}
        <ColorScale />
        {legendMinValue !== '' && <LegendLabel>{legendMinValue}</LegendLabel>}
        <GearIcon onClick={() => setExpanded(!expanded)}>⚙️</GearIcon>
        {expanded && (
          <>
            <ToggleContainer>
              <ToggleLabel>Auto</ToggleLabel>
              <ToggleSwitch>
                <ToggleInput
                  type="checkbox"
                  checked={autoMode}
                  onChange={handleToggle}
                />
                <ToggleSlider />
              </ToggleSwitch>
            </ToggleContainer>

            <InputContainer>
              <Input
                type="number"
                value={getValidNumber(maxValue, maxPressure)}
                onChange={(e) => setMaxValue(getValidNumber(e.target.value, maxPressure))}
                placeholder="Max"
                disabled={autoMode}
              />
              <Input
                type="number"
                value={getValidNumber(minValue, minPressure)}
                onChange={(e) => setMinValue(getValidNumber(e.target.value, minPressure))}
                placeholder="Min"
                disabled={autoMode}
              />
            </InputContainer>
          </>
        )}
      </LegendContainer>

      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={14}
        center={mapCenter || defaultCenter}
        onLoad={(map) => {
          mapRef.current = map;
          if (devices && devices.length > 0 && !initialFitDoneRef.current) {
            fitMapToDevices(devices);
            initialFitDoneRef.current = true;
          }
        }}
        options={mapOptions}
      >
        <div style={{ position: 'absolute', top: '10px', left: '50%', transform: 'translateX(-30%)', zIndex: 1, display: 'flex' }}>
          <ButtonDraw onClick={toggleDrawingMode} title={intl.formatMessage({ id: 'draw_polygon' }) || "Dibujar polígono"}>
            {drawingMode === 'polygon' ? <MdOutlineBackHand /> : <MdOutlineDraw />}
          </ButtonDraw>

          <ButtonDraw
            onClick={handleClearDrawing}
            title={intl.formatMessage({ id: 'clear_drawing' }) || "Borrar dibujo"}
          >
            <FaEraser />
          </ButtonDraw>
        </div>

        {heatmapData.length > 0 && (
          <HeatmapLayer
            data={heatmapData}
            options={{
              radius: 30,
              gradient: heatmapGradient,
            }}
          />
        )}
        {selectedDeviceMarker && (
          <Marker
            position={selectedDeviceMarker.position}
            icon={{
              url: 'https://maps.gstatic.com/mapfiles/ms2/micons/purple.png',
              scaledSize: new window.google.maps.Size(40, 40)
            }}
            title={`Selected: ${selectedDeviceMarker.device.installPointName}`}
          />
        )}
        {showMarkers &&
          devices?.map((device) => (
            <Marker
              key={`${device.deviceId}-${device.lat}-${device.long}`}
              position={{ lat: device.lat, lng: device.long }}
              icon={greenMarkerIcon}
            // label={device.installPointName}
            />
          ))
        }
        <DrawingManager
          ref={drawingManagerRef}
          onLoad={(manager) => {
            drawingManagerRef.current = manager;
            manager.setMap(mapRef.current);
          }}
          drawingMode={drawingMode}
          onPolygonComplete={onPolygonComplete}
          options={{
            drawingControl: false,
            drawingControlOptions: {
              position: window.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [window.google.maps.drawing.OverlayType.POLYGON]
            },
            polygonOptions: {
              fillColor: '#FF0000',
              fillOpacity: 0.1,
              strokeWeight: 2,
              strokeColor: '#FF0000',
              clickable: true,
              editable: true,
              zIndex: 1,
            },
          }}
        />
      </GoogleMap>
    </div>
  );
};

export default MapHeatMapComponent;