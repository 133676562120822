import { create } from 'zustand';

const useTagStore = create((set) => ({
    tags: [],

    addTag: (newTag) =>
        set((state) => ({
            tags: [...state.tags, newTag],
        })),

    removeTag: (idName) =>
        set((state) => ({
            tags: state.tags.filter((tag) => tag.id_name !== idName),
        })),

    initializeTags: (tagsFromApi) => set({ tags: tagsFromApi }),
}));

export default useTagStore;