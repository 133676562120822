import React from 'react';
import heatmap from "../../assets/img/heatmap/icon_heatmap_black.svg";
import heatmapWhite from "../../assets/img/heatmap/icon_heatmap_white.svg";

const HeatmapIcon = ({ isActive }) => {
  return (
    <picture style={{ padding: '0px 4px' }}>
      <img 
        style={{ width: '16px', height: '16px' }} 
        src={isActive ? heatmapWhite : heatmap} 
        alt="Heatmap" 
      />
    </picture>
  );
};

export default HeatmapIcon; 