export const getDescriptionAlarms = (alarmTypeName) => {

    const alarmTypeDescription = {
        "Variação local da pressão acima": "Mistura, manobra do usuário, problemas no bombeamento/booster.",

        "Variação local da pressão abaixo": "Fechamento do registro do cavalete, obstrução no ramal, vazamento externo, vazamento interno, fraude, consumo excessivo.",

        "Região com elevação de pressão": "Mistura de setor, manobra operacional, problema do bombeamento/booster.",

        "Região com queda de pressão": "mistura de setor, manobra operacional, problema do bombeamento/booster, vazamento, obstrução, quebra de registro de rede.",
    };
    return alarmTypeDescription[alarmTypeName];
}
