import { MdOutlineHome, MdOutlinePlace, MdDensitySmall, MdSettings, MdSave, MdPictureAsPdf, MdLock, MdLogout, MdOutlineMap, MdSell } from 'react-icons/md'
import React, { useState, useEffect, useRef } from "react";
// import { Container, CustomToggle, CustomMenu, ContainerItems, Items, ContainerLogo, Logo } from './styles'
import {
  HeaderContainer,
  Logo,
  Nav,
  NavItem,
  Menu,
  Items,
  CustomToggle,
  CustomMenu,
  NavItemSelect,
  Alarm,
  CustomItemDropdown,
  StyledLink
} from "./styles";
import { FormattedMessage, useIntl } from "react-intl";
import Dropdown from "react-bootstrap/Dropdown";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { clients_all } from "../../services/alarms";
import { useAuth } from "../../hooks/auth";
import DropdownClient from "../DropdownClients";
import ExportReport from "../ExportReport";
import { Toast } from "primereact/toast";
import _debounce from "lodash/debounce";
import { CgDisplayGrid } from "react-icons/cg";
import ModalCreateTags from '../ModalTags';
import HeatmapIcon from "../HeatmapIcon";

import logo from "../../assets/img/logo-stattus4-light.webp";
import useUserStore from "../../store/useUserStore";
import AlarmList from "../../components/AlarmList/index";
import AlarmListPlus from '../AlarmListPlus';
import useClientSelectedStore from "../../store/map/useClientSelectedStore";
import { keycloak } from "../../keycloak";
import "primereact/resources/themes/lara-light-indigo/theme.css"; // theme
import "primereact/resources/primereact.css";
import "bootstrap/dist/css/bootstrap.min.css";
import useCompanyStore from "../../store/useCompanyStore";
import useDropdownStore from "../../store/useDropdownStore";
import useHeaderStore from "../../store/useHeaderStore";
import useUserRolesStore from "../../store/useUserRolesStore";

const Header = ({hideAlarm}) => {
  const toast = useRef(null);
  const { logout } = useAuth();
  const { user, setUser } = useUserStore();
  const routeLocation = useLocation();
  const navigate = useNavigate();
  const { setTradingName } = useCompanyStore();
  const { clientSelected } = useClientSelectedStore();
  const { isDropdownDisabled } = useDropdownStore();
  const { isHeaderVisible } = useHeaderStore();
  const [currentRoute, setCurrentRoute] = useState(null);
  const [allClients, setAllClients] = useState([]);
  const [initialClient, setInitialClient] = useState(null);
  const [showModalExport, setShowModalExport] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const loginByKeycloak = JSON.parse(localStorage.getItem('isKcAda'));
  const last_client = localStorage.getItem("last_client");
  const { roles } = useUserRolesStore();

  useEffect(() => {
    debouncedGetAllClients();
    return debouncedGetAllClients.cancel;
  }, []);

  useEffect(() => {
    if (user) {
      const client = {
        id: user.client.id,
        trading_name: user.client.trading_name,
      };
      setInitialClient(client);
      setTradingName(user.client.trading_name);
    }
  }, []);

  useEffect(() => {
    if (routeLocation) {
      setCurrentRoute(routeLocation.pathname);
    }
  }, [routeLocation]);

  useEffect(() => {
    if (loginByKeycloak) {
      handleCheckAuth();
    };
  }, [routeLocation]);

  const debouncedGetAllClients = _debounce(() => {
    getAllClients();
  }, 1);

  const getAllClients = async () => {
    try {
      const response = await clients_all();
      const filteredData = response.data.filter(
        (item) => item.activeAda === true
      );
      setAllClients(filteredData);
    } catch (error) {
      if (error.response) {
        const msg = error.response.data.message;
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: msg,
          life: 3000,
        });
      } else if (error.request) {
        const message = error.message;
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: message,
          life: 3000,
        });
      }
    }
  };

  const handleCheckAuth = async () => {
    if (loginByKeycloak) {
      const isAuthenticated = await keycloak.authenticated;
      if (isAuthenticated) {
        try {
          const refreshed = await keycloak.updateToken(80);
          if (refreshed) {
            const updatedUser = { ...user, token: keycloak.token };
            setUser(updatedUser);
          };
        } catch (error) {
          console.error('Falha ao renovar o token:', error);
          exit();
        }
      } else {
        exit();
      }
    }
  };

  const exit = () => {
    if (loginByKeycloak) {
      keycloak.logout({
        redirectUri: window.location.origin + `/intercept/${last_client}`,
      });
      sessionStorage.removeItem("@ada-user");
      sessionStorage.removeItem("@ada-company");
      sessionStorage.removeItem("@ada-user-roles");
      localStorage.removeItem("isKcAda");
    }
    if (!loginByKeycloak) {
      logout();
      navigate("/login");
    }
  };

  const handleModalExportReport = () => {
    setShowModalExport(true);
  };

  const handleClose = () => {
    setShowModalExport(false);
  };

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <>
      {/* <HeaderContainer $show={isHeaderVisible}>     Função não mais usada, SUPERSET*/}
      <HeaderContainer $show={true}>
        <Toast ref={toast} />
        <Link to="/home">
          <Logo>
            <img src={logo} alt="Logo" />
          </Logo>
        </Link>
        <Nav>
          <NavItem>
            {roles.some((role) => role.ada_plus) ? (
              <Items
                as={Link}
                to="/home-plus"
                $actived={currentRoute === "/home-plus"}
              >
                <MdOutlineHome />
                <FormattedMessage id="home" />
              </Items>
            ) : (
              <Items
                as={Link}
                to="/home"
                $actived={currentRoute === "/home"}
              >
                <MdOutlineHome />
                <FormattedMessage id="home" />
              </Items>
            )}
            {roles.some((role) => role.ada_plus) ? (
              <Items
                as={Link}
                to="/install-points-plus"
                $actived={currentRoute === "/install-points-plus"}
              >
                <MdOutlinePlace />
                <FormattedMessage id="install_points" />
              </Items>
            ) : (
              <Items
                as={Link}
                to="/install-points"
                $actived={currentRoute === "/install-points"}
              >
                <MdOutlinePlace />
                <FormattedMessage id="install_points" />
              </Items>
            )}
            {roles.some((role) => role.ada_plus) && (
              <Items
                as={Link}
                to="/install-points-heatmap"
                $actived={currentRoute === "/install-points-heatmap"}
              >
                <HeatmapIcon isActive={currentRoute === "/install-points-heatmap"} />
                <FormattedMessage id="install_points_map_heat_map" />
              </Items>
            )}
          </NavItem>
          <NavItemSelect>
            {currentRoute === "/home" && user.type_user === "1" && (
              <DropdownClient
                data={allClients}
                initial={initialClient}
                disabled={isDropdownDisabled}
              />
            )}
            {currentRoute === "/install-points" && user.type_user === "1" && (
              <DropdownClient
                data={allClients}
                initial={initialClient}
                disabled={isDropdownDisabled}
              />
            )}
            {currentRoute === "/install-points-plus" && user.type_user === "1" && (
              <DropdownClient
                data={allClients}
                initial={initialClient}
                disabled={isDropdownDisabled}
              />
            )}
          </NavItemSelect>
          <NavItem></NavItem>
        </Nav>
        <Alarm>
          {currentRoute === "/home" && user && (
            roles.some((role) => role.ada_plus) ? (
              <AlarmListPlus
                data={user.type_user !== "1" ? user.client_id : clientSelected.id}
                token={user.token}
              />
            ) : (
              <AlarmList
                data={user.type_user !== "1" ? user.client_id : clientSelected.id}
                token={user.token}
              />
            )
          )}

          {currentRoute === "/home-plus" && user && !hideAlarm && (
            roles.some((role) => role.ada_plus) ? (
              <AlarmListPlus
                data={user.type_user !== "1" ? user.client_id : clientSelected.id}
                token={user.token}
              />
            ) : (
              <AlarmList
                data={user.type_user !== "1" ? user.client_id : clientSelected.id}
                token={user.token}
              />
            )
          )}
        </Alarm>
        <Menu>
          <Dropdown>
            <CustomToggle variant="success" id="dropdown-basic">
              <MdDensitySmall size={28} color="#000" />
            </CustomToggle>
            <CustomMenu>
              {user.type_user === "1" && (
                <StyledLink to="/manager-macro-sector">
                  <div>
                    <CgDisplayGrid size={30} />{" "}
                    <FormattedMessage id="macro_sector_management" />
                  </div>
                </StyledLink>
              )}
              {user.type_user === "1" && (
                <StyledLink to="/manager-sector">
                  <div>
                    <MdOutlineMap size={25} />{" "}
                    <FormattedMessage id="sector_manager" />
                  </div>
                </StyledLink>
              )}
              {user.type_user === "1" && (
                <StyledLink to="/manager-alarm">
                  <div>
                    <MdSettings size={25} />{" "}
                    <FormattedMessage id="manager_alarms_plus_list_alarms" />
                  </div>
                </StyledLink>
              )}
              {roles.some((role) => role.ada_plus) &&
                <StyledLink to="/manager-alarm-plus">
                  <div>
                    <MdSettings size={25} />{" "}
                    <FormattedMessage id="manager_alarms_plus_list_alarms" />
                  </div>
                </StyledLink>
              }
              {!roles.some((role) => role.ada_plus) &&
                <CustomItemDropdown onClick={handleModalExportReport}>
                  <div>
                    <MdSave size={25} /> <FormattedMessage id="save_reports" />
                  </div>
                </CustomItemDropdown>}
              {roles.some((role) => role.ada_plus) && (
                <CustomItemDropdown onClick={openModal}>
                  <div>
                    <MdSell size={25} />
                    <FormattedMessage id="title_create_tag" />
                  </div>
                </CustomItemDropdown>
              )}
              {/* <CustomItemDropdown disabled>
                <div>
                  <Link to="#/action-3">
                    <MdPictureAsPdf size={25} />{" "}
                    <FormattedMessage id="export_pdf" />
                  </Link>
                </div>
              </CustomItemDropdown> */}
              {!loginByKeycloak && (
                <StyledLink to="/change-password">
                  <div>
                    <MdLock size={25} />{" "}
                    <FormattedMessage id="change_password" />
                  </div>
                </StyledLink>
              )}
              <CustomItemDropdown onClick={exit}>
                <MdLogout size={25} /> <FormattedMessage id="log_out" />
              </CustomItemDropdown>
            </CustomMenu>
          </Dropdown>
        </Menu>
      </HeaderContainer>
      <ExportReport onShow={showModalExport} onClose={handleClose} />
      <ModalCreateTags
        visible={modalVisible}
        onHide={closeModal}
        clientId={user.type_user !== '1' ? user.client_id : clientSelected.id}
      />
    </>
  );

};

export default Header;
