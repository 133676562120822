import React from 'react';

const SizeSelector = ({ selectedSize, onChange }) => {
  const sizes = [50, 75, 100, 150, 200, 250, 300];
  const scaleSizes = sizes.map(size => size / 2);

  return (
    <div className='pi pi-arrows-alt'
      style={{ margin: '10px 0', padding: '8px 25px', background: '#696969', borderRadius: '5px', color: '#FFF', fontSize: '18px' }}>
      <select
        id="sizeSelector"
        value={selectedSize}
        onChange={(e) => onChange(Number(e.target.value))}
        style={{ marginLeft: '10px', cursor: 'pointer' }}
      >
        {scaleSizes.map((size, index) => (
          <option key={sizes[index]} value={size}>
            {sizes[index]} metros
          </option>
        ))}
      </select>
    </div>
  );
};

export default SizeSelector;
