import React from "react";
import { Navigate } from "react-router-dom";
import useUserRolesStore from "../store/useUserRolesStore";

const ProtectedRoute = ({ children }) => {
  const { roles } = useUserRolesStore();

  if (!roles || !roles.some((role) => role.ada_plus)) {
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
};

export default ProtectedRoute;
