import styled from 'styled-components';

export const ButtonDraw = styled.button`
  background: none padding-box rgb(255, 255, 255);
  display: table-cell;
  border: 0px;
  margin: 0px;
  padding: 0px 12px;
  text-transform: none;
  appearance: none;
  position: relative;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  text-align: center;
  height: 40px;
  vertical-align: middle;
  color: rgb(0, 0, 0);
  font-family: Roboto, Arial, sans-serif;
  font-size: 18px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  min-width: 45px;
  font-weight: 500;

  /* Estilos adicionais que você pode querer */
  &:hover {
    /* Exemplo: Adicionar um efeito de hover */
    background-color: rgba(0, 0, 0, 0.1); /* Um cinza claro ao passar o mouse */
  }

  &:active {
    /* Exemplo: Estilo ao clicar no botão */
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px -1px; /* Sombra menor ao clicar */
    transform: translateY(1px); /* Levemente para baixo ao clicar */
  }

  /* Você pode adicionar props para estilizar condicionalmente */
  ${props => props.primary && `
    background-color: #007bff; /* Exemplo: Cor de fundo azul para botões primários */
    color: white;
  `}

  ${props => props.danger && `
    background-color: #dc3545; /* Exemplo: Cor de fundo vermelha para botões de perigo */
    color: white;
  `}
`;


// Como usar o componente:
// Em seu componente React:
// import Button from './SeuComponenteButton'; // Importe o componente estilizado

// <Button>Texto do Botão</Button>
// <Button primary>Botão Primário</Button>
// <Button danger>Botão de Perigo</Button>