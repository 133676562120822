import styled, { css, keyframes } from "styled-components";

const loadingAnimation = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

const loadingStyles = css`
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${loadingAnimation} 1.5s infinite;
`;


export const Container = styled.div``

export const GridContainer = styled.div`
  user-select: none;
  margin-top: 25px;
  display: grid;
  grid-template-rows: 30px 1fr; 
  grid-template-columns: ${({ $sidebarOpen }) => $sidebarOpen ? '235px' : '60px'} minmax(0, 1fr) ${({ $isMacro }) => $isMacro ? '0%' : '50%'};
  gap: 15px; 
  height: 83vh; 
  transition: grid-template-columns 0.4s;
  padding-left: 10px;
  padding-right: 10px;
  @media(max-width: 900px) {
      display: none;
    }
`;

export const GridItem = styled.div`
  ${({ $skeleton }) => $skeleton ? 'background-color: #e0e0e0;' : 'loadingStyles'};
  // width: ${({ $isMacro }) => $isMacro ? '235%' : '100%'}; 
  border: 1px solid #ccc;
  overflow: auto;
  color: #000;
  height: 81vh;
  transition: width 1s ease; 
`;

export const GridItemSidebar = styled.div`
 
  /* border: 1px solid #ccc; */
  /* overflow: auto;
  color: #000;
  height: 81vh;
  @media(max-width: 900px) {
      display: none;
    } */
`;

export const GridItemSidebarContainer = styled.div`
  background-color: #f0f0f0;
  border: 1px solid #ccc; 
  overflow: auto;
  color: #000;
  height: ${({ $height }) => $height ? '75.5vh' : '81vh'};;
  @media(max-width: 900px) {
      display: none;
    }
`;

export const GridItemSearch = styled.div`
  border-radius: 5px;
  background-color: ${({ $sidebarOpen }) => $sidebarOpen ? '#fff' : '#8086C0'};
  border: ${({ $sidebarOpen }) => $sidebarOpen ? 'none' : '#8086C0 1px solid'};
  display: flex;
  align-items: center;
  justify-content: ${({ $sidebarOpen }) => $sidebarOpen ? 'space-between' : 'center'};
`;

export const GridItemInput = styled.input`
  width: 62%;
  border: #000 solid 1px;
  padding-left: 10px;
`;

export const GridItemTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const GridItemDate = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;

export const TitleContainer = styled.div`
    background: linear-gradient(to right, #cfcfcf 50%, ${props => props.theme.colors.success} 50%);
    width: 70%;
    height: 100%;
    display: flex; 
    padding: 0 5px; 
`;

export const CompanyText = styled.span`
    color: ${props => props.theme.colors.black}; 
    align-self: center;
    font-size: 14px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    width: 50%;
    text-align: center;
`;

export const SectorText = styled.span`
    color: ${props => props.theme.colors.black}; 
    align-self: center;
    font-size: 14px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    width: 50%;
    text-align: center;
`;

export const SidebarContainer = styled.div`
  background-color: #f0f0f0;
  position: relative;

`;

export const ListContainer = styled.div`
`;

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  font-size: 13px;
`;

export const ListItem = styled.li`
  display: flex;
  justify-content: ${({ $sidebarOpen }) => $sidebarOpen ? 'space-between' : 'center'};
  border-bottom: 4px #fff solid;
  align-items: center;
  padding: 10px;
  text-overflow: ellipsis;
  cursor: pointer;
  height: auto;
  &:hover {
    opacity: 0.5;
    background-color: #8086C0;
  }
  ${props => props.$active && `
    opacity: 0.5;
    background-color: #8086C0;
  `}
`;

export const SubListContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SubListWrapper = styled.div`
    width: 100%;
`;

export const SubList = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SubListItem = styled.li`
  display: flex;
  justify-content: ${({ $sidebarOpen }) => $sidebarOpen ? 'space-between' : 'center'};
  width: 100%;
  margin-top: 10px 0px 0px 10px;
  padding: 10px;
  cursor: pointer;
  list-style-type: none;
  border-bottom: 4px #fff solid;
  &:hover {
    opacity: 0.5;
    background-color: #8086C0;
  }
  ${props => props.$activeSub && `
    opacity: 0.5;
    background-color: #8086C0;
  `}
`;

export const GridItemChart = styled.div`
  display: ${({ $isMacro }) => $isMacro ? 'none' : 'grid'}; 
  grid-template-rows: 1fr 1fr 1fr; 
  gap: 10px; 
  height: 81vh;
`;

export const ChartItemAdaPlus = styled.div`
  height: 40vh;
  border: 1px solid #ccc;
  margin-top: 0;

  ${({ $skeleton }) => 
    $skeleton
      ? css`
          background-color: #e0e0e0;
          height: 40vh;
        `
      : loadingStyles};

  &:nth-child(1) {
    margin-top: 0;
  }
`;

export const ChartItem = styled.div`
  ${({ $skeleton }) => $skeleton ? 'background-color: #e0e0e0;' : loadingStyles};
  height: 100%;
  margin-top: 0;
  border: 1px solid #ccc;
  &:nth-child(1) {
    margin-top: 0;
  }
`;

export const AlertMsg = styled.div`
  display: none;
  @media(max-width: 900px) {
    display: block;
    padding: 10px;
    margin-top: 33vh;
    font-size: 18px;
  }
`;


export const OuterDiv = styled.div`
  text-align: left;
  font-size: 14px;
  padding: 10px;
  border-bottom: #fff solid 4px;
  cursor: pointer;
  background-color: ${({ $indexSelected }) => ($indexSelected === -1 ? '#8086C0' : 'transparent')};
  opacity: ${({ $indexSelected }) => ($indexSelected === -1 ? '55%' : '100%')};

  &:hover {
    background-color: ${({ $indexSelected }) => ($indexSelected === -1 ? '#8086C0' : 'rgba(128, 134, 192, 0.8)')};
  }
`;

export const InnerDiv = styled.div`
  display: flex;
  justify-content: ${({ $expand }) => ($expand ? 'space-between' : 'center')};
`;

export const AllMacrosDiv = styled.div`
  display: flex;
  align-items: center;
  color: ${({ $selected }) => ($selected ? '#8086C0' : '#000000')};
  text-decoration-line: ${({ $selected }) => ($selected ? 'underline' : 'none')};
`;