import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";

import Signin from "../pages/Signin";
import ForgotPassword from "../pages/ForgotPassword";
import Intercept from "../pages/Intercept";

import {
    LoadingOverlay,
    LoadingSpinnerWrapper,
} from '../pages/Signin/styles'

import { initKeycloak, keycloak } from '../keycloak';

const AuthRoute = ({ element: Component }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        const checkAuth = async () => {
            await new Promise(resolve => setTimeout(resolve, 3000));

            if (!keycloak.authenticated) {
                await keycloak.login();
            }

            setLoading(false);
        };

        checkAuth();

        keycloak.onAuthSuccess = () => {
            setLoading(false);
        };

        keycloak.onAuthError = () => {
            setLoading(false);
        };

        return () => {
            keycloak.onAuthSuccess = null;
            keycloak.onAuthError = null;
        };
    }, []);

    if (loading) {
        return <LoadingOverlay>
            <LoadingSpinnerWrapper />
        </LoadingOverlay>
    }

    return <Component />;
};

const AuthRoutes = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        initKeycloak()
            .then((kc) => {

            })
            .catch((error) => {
                console.error('Failed to initialize Keycloak:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <LoadingOverlay>
            <LoadingSpinnerWrapper />
        </LoadingOverlay>
    }

    return (
        <Routes>
            <Route path="/" element={<Signin />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/intercept/:clientId" element={<AuthRoute element={Intercept} />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};

export default AuthRoutes;
