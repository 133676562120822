import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import { note_by_id_alarms, update_alarms_v2, update_devices } from "../../services/alarms";
import { listEvents, listCompleteDevices } from '../../services/install_points_plus';
import { Checkbox } from 'primereact/checkbox';
import ConfirmModal from '../../components/Modals/ConfirmModal/index';
import { Dropdown } from 'react-bootstrap';
import {
    AlarmIcon,
    ContainerCardOpt,
    AlarmCardIconReturn,
    AlarmLabelCheckBox,
    AlarmCardIconFile,
    ContainerMsgNoAlarm,
    CustomDropdown,
    CustomToggle,
    CustomMenu,
    LoadingSpinner,
    AlarmListContainer
} from './styles'

import AlarmCardList from './AlarmCardList';

import { FaBell } from "react-icons/fa6";
import useUserStore from '../../store/useUserStore';
import moment from 'moment/moment';
import useCompanySectorStore from '../../store/useCompanySectorStore';
import NotificationAlarmModal from './NotificationAlarmModal';
import InfiniteScroll from '../../components/InfiniteScroll';
import { DISABLE_DEVICE_ALARMS_FEATURES } from '../../config/config';


export default function AlarmListPlus({ data, token }) {
    const intl = useIntl();
    const toast = useRef(null);
    const { user } = useUserStore();
    const {sectorNameSelected } = useCompanySectorStore();
    const dropdownRef = useRef(null);

    const [isOpen, setIsOpen] = useState(false);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const [finalList, setFinalList] = useState([]);
    const [selectedAlarm, setSelectedAlarm] = useState(null);
    const [selectedSeeCheck, setSelectedSeeCheck] = useState(false);
    const [colorAlarm, setColorAlarm] = useState(null);

    const [showCondition, setShowCondition] = useState(false);

    const [confirm, setConfirm] = useState(false);
    const [confirmAddBack, setConfirmAddBack] = useState(false);
    const [removed, setRemoved] = useState(null);
    const [addBack, setAddBack] = useState(null);

    const [obs, setObs] = useState('');
    const [obsTemp, setObsTemp] = useState(null);
    const [oldObs, setOldObs] = useState(null);
    const [msgModal, setMsgModal] = useState('');
    const [showMsgModal, setShowMsgModal] = useState(false);
    const [saveMsg, setSaveMsg] = useState(false);

    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const disableDeviceAlarmsFeatures = DISABLE_DEVICE_ALARMS_FEATURES =='true';


    const [filters, setFilters] = useState({
        seen: false,
        unseen: true,
        deviceAlarm: !disableDeviceAlarmsFeatures,
        sectorAlarm: true
    });

    const [alarmType, setAlarmType] = useState(null);

    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const handleFilterChange = (filterName, checked) => {
        setLoading(true);

        setFilters(prev => ({
            ...prev,
            [filterName]: checked
        }));
        setPage(1);
        setHasMore(true);
        setFirst(0);
    };

    const fetchEvents = async (page = 1, perPage = 10, skipLoading = false, preserveSeenItems = false) => {
        try {
            if (page === 1 && !skipLoading) {
                setLoading(true);
            }

            const params = {
                page,
                per_page: perPage,
                ...(disableDeviceAlarmsFeatures && {
                    type: 'alarm_sector'
                })
            };

            if (showCondition) {
                params.metadata = JSON.stringify({
                    criteria: "contains",
                    values: "true",
                    field: "removedByUser",
                    type_field: "text"
                });
            } else {
                const metadataConditions = [];

                metadataConditions.push({
                    criteria: "contains",
                    values: "false",
                    field: "removedByUser",
                    type_field: "text"
                });

                if (sectorNameSelected) {
                    metadataConditions.push({
                        criteria: "equals",
                        values: sectorNameSelected,
                        field: "sectorName",
                        type_field: "text"
                    });
                }

                if ((filters.seen && !filters.unseen) || (!filters.seen && filters.unseen)) {
                    if (!preserveSeenItems) {
                        const seenValue = filters.seen ? "true" : "false";
                        metadataConditions.push({
                            criteria: "contains",
                            values: seenValue,
                            field: "seen",
                            type_field: "text"
                        });
                    }
                }

                if (metadataConditions.length > 0) {
                    params.metadata = JSON.stringify(
                        metadataConditions.length === 1
                            ? metadataConditions[0]
                            : metadataConditions
                    );
                }
            }

            if (!showCondition) {
                const typeFilters = [];
                if (filters.deviceAlarm) typeFilters.push("alarm_device");
                if (filters.sectorAlarm) typeFilters.push("alarm_sector");

                if (typeFilters.length > 0) {
                    params.type = JSON.stringify({
                        criteria: "in",
                        values: typeFilters
                    });
                }
            }

            const res = await listEvents(params);
            if (res?.items) {
                setTotalRecords(res.total || 0);

                if (preserveSeenItems && page === 1) {
                    const currentIds = new Set(finalList.map(item => item.id));
                    const newItems = res.items.filter(item => !currentIds.has(item.id));
                    setFinalList(prevList => [...prevList, ...newItems]);
                } else if (page === 1) {
                    setFinalList(res.items);
                } else {
                    setFinalList(prevList => [...prevList, ...res.items]);
                }

                callColorAlarm(res.items);
                return res.items;
            }
            if (page === 1 && !preserveSeenItems) {
                setFinalList([]);
            }
            return [];
        } catch (error) {
            toast.current.show({
                severity: 'error',
                summary: intl.formatMessage({ id: 'error_fetching_events' }),
                life: 3000
            });
            if (page === 1 && !preserveSeenItems) {
                setFinalList([]);
            }
            return [];
        } finally {
            if (!skipLoading) {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        setLoading(true);
        setPage(1);
        setHasMore(true);
        fetchEvents(1, rowsPerPage);
    }, []);

    useEffect(() => {
        setPage(1);
        setHasMore(true);
        fetchEvents(1, rowsPerPage);
    }, [sectorNameSelected, rowsPerPage, showCondition, filters]);

    useEffect(() => {
        if (visible) {
            setSelectedSeeCheck(selectedAlarm?.seen);
        } else {
            setObs('');
            setObsTemp(null);
            setOldObs(null);
        }
    }, [visible, selectedAlarm]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const callColorAlarm = (array) => {
        if (!array) return;

        const hasUnseenAlarms = array.some(obj => {
            const seen = obj.metadata?.seen === "true" ||
                (typeof obj.metadata?.seen === "boolean" && obj.metadata.seen) ||
                obj.seen === "true" ||
                (typeof obj.seen === "boolean" && obj.seen);
            return !seen;
        });

        setColorAlarm(!hasUnseenAlarms);
    };

    const prepareDeviceAlarmPayload = (data) => {
        const originalData = data?.originalData || data;
        const metadata = originalData.metadata || {};

        return {
            client_id: originalData.client_id || metadata.clientId,
            type: "alarm_device",
            severity: originalData.severity || "medium",
            description: originalData.description || originalData.shortDescription,
            id: originalData.id,
            source: originalData.source || "manual",
            status: originalData.status || "active",
            metadata: {
                ...metadata,
                seen: (data.seen !== undefined ? data.seen : metadata.seen === "true").toString(),
                removedByUser: (data.removedByUser !== undefined ? data.removedByUser : metadata.removedByUser === "true").toString(),
                deletedByOperation: metadata.deletedByOperation || "false",
                deviceName: metadata.deviceName,
                dev_serial: metadata.dev_serial || originalData.deviceSerial
            }
        };
    };

    const prepareSectorAlarmPayload = (data) => {
        const originalData = data?.originalData || data;
        const metadata = originalData.metadata || {};
        return {
            clientId: metadata.clientId || originalData.client_id,
            alarmTypeId: metadata.alarmTypeId,
            sectorId: metadata.sectorId,
            alarmNoteDate: originalData.alarmNoteDate || metadata.alarmNoteDate,
            shortDescription: originalData.shortDescription || originalData.description,
            completeDescription: originalData.completeDescription || originalData.description,
            alarmNoteImg: data.alarmNoteImg || "",
            seen: data.seen ?? metadata.seen === "true",
            removedByUser: data.removedByUser ?? metadata.removedByUser === "true",
            deletedByOperation: metadata.deletedByOperation === "true",
            showToUser: metadata.showToUser === "true",
            alarmNoteId: metadata.alarmNoteId || originalData.alarmNoteId,
            clientName: metadata.clientName,
            sectorName: metadata.sectorName,
            alarmTypeName: metadata.alarmTypeName,
            clientObs: data.metadata.clientObs || metadata.clientObs || []
        };
    };

    const handleUpdateAlarm = async (updatedAlarm) => {
        try {
            setLoading(true);
            const isSectorAlarm = updatedAlarm?.type === 'alarm_sector' ||
                updatedAlarm?.alarmType == 'sector' ||
                updatedAlarm?.originalData?.type === 'alarm_sector';
            if (isSectorAlarm) {
                const sectorPayload = prepareSectorAlarmPayload(updatedAlarm);
                await update_alarms_v2(sectorPayload);
            } else {
                const devicePayload = prepareDeviceAlarmPayload(updatedAlarm);

                if (!devicePayload.id) {
                    throw new Error("ID de dispositivo no encontrado");
                }

                await update_devices(devicePayload);
            }

            await fetchEvents(Math.floor(first / rowsPerPage) + 1, rowsPerPage);

            toast.current.show({
                severity: 'success',
                summary: intl.formatMessage({ id: 'alarm_updated' }),
                life: 3000
            });

            return true;
        } catch (error) {
            toast.current.show({
                severity: 'error',
                summary: intl.formatMessage({ id: 'error_updating_alarm' }),
                detail: error.message,
                life: 3000
            });
            return false;
        } finally {
            setLoading(false);
        }
    };

    const handleAlarmSeeChange = async (alarm, seen) => {
        try {
            const updatedAlarm = {
                ...alarm,
                seen: seen,
                type: alarm.alarmType === 'device' ||
                    alarm.type === 'alarm_device' ||
                    alarm.originalData?.type === 'alarm_device'
                    ? 'alarm_device'
                    : 'alarm_sector'
            };


            setFinalList(prevList => prevList.map(item => {
                if (item.id === alarm.id ||
                    (item.metadata?.alarmNoteId === alarm.alarmNoteId) ||
                    (item.id === alarm.originalData?.id)) {

                    return {
                        ...item,
                        seen: seen.toString(),
                        metadata: {
                            ...item.metadata,
                            seen: seen.toString()
                        }
                    };
                }
                return item;
            }));

            const success = await handleUpdateAlarm(updatedAlarm);

            if (!success) {
                setFinalList(prevList => prevList.map(item => {
                    if (item.id === alarm.id ||
                        (item.metadata?.alarmNoteId === alarm.alarmNoteId) ||
                        (item.id === alarm.originalData?.id)) {

                        return {
                            ...item,
                            seen: (!seen).toString(),
                            metadata: {
                                ...item.metadata,
                                seen: (!seen).toString()
                            }
                        };
                    }
                    return item;
                }));
            }

            if (success && filters.unseen && !filters.seen && seen) {

            } else if (success) {
                await fetchEvents(Math.floor(first / rowsPerPage) + 1, rowsPerPage, true, false);
            }

            return success;
        } catch (error) {
            toast.current.show({
                severity: 'error',
                summary: intl.formatMessage({ id: 'error_updating_alarm' }),
                detail: error.message,
                life: 3000
            });

            return false;
        }
    };

    const handleVerifyButtonClick = async (data) => {
        try {
            const isDeviceAlarm = data.type === 'alarm_device' ||
                data.alarmType === 'device'


            if (isDeviceAlarm) {
                const serialNumber = data.deviceSerial || data.metadata?.dev_serial;
                const response = await listCompleteDevices({ serial_number: serialNumber });

                if (response?.items?.length > 0) {
                    const deviceData = response.items[0];
                    const updatedData = {
                        ...data,
                        type: 'alarm_device',
                        createdAt: data.originalData.created_at,
                        updatedAt: data.originalData.updated_at,
                        deviceData: deviceData,
                        metadata: {
                            ...data.metadata,
                            deviceId: deviceData.id,
                            deviceName: deviceData.name,
                            dev_serial: serialNumber
                        }
                    };

                    setSelectedAlarm(updatedData);
                    setVisible(true);
                }
            } else {
                const response = await note_by_id_alarms({
                    clientId: data.clientId,
                    alarmNoteId: data.alarmNoteId || data.id,
                    ignoreDeleted: true,
                });

                if (response?.data) {

                    const updatedData = {
                        ...data,
                        createdAt: data.originalData.created_at,
                        updatedAt: data.originalData.updated_at,
                        type: 'alarm_sector',
                        alarmNoteImg: response.data.alarmNoteImg || data.alarmNoteImg || "",
                    };

                    let clientObsArray;
                    if (Array.isArray(data.clientObs)) {
                        clientObsArray = data.clientObs;
                    } else if (data.clientObs) {
                        clientObsArray = [{
                            userId: "",
                            userName: "",
                            obs: data.clientObs,
                            date: "xx-xx-xxxx"
                        }];
                    } else {
                        clientObsArray = [];
                    }

                    setSelectedAlarm(updatedData);
                    setOldObs(clientObsArray);
                    setObsTemp(clientObsArray);
                    setVisible(true);
                }
            }
        } catch (error) {
            toast.current.show({
                severity: 'error',
                summary: intl.formatMessage({ id: 'error_fetching_alarm_details' }),
                life: 3000
            });
        }
    };

    const handleFilterButtonFile = () => {
        setLoading(true);
        setShowCondition(true);
        setPage(1);
        setHasMore(true);
    };

    const handleCleanFilterButtonClick = () => {
        setLoading(true);
        setShowCondition(false);
        setFilters({
            seen: false,
            unseen: true,
            deviceAlarm: true,
            sectorAlarm: true
        });
        setPage(1);
        setHasMore(true);
    };

    const HandleRemove = (data) => {
        setConfirm(true);
        setRemoved(data);
    }

    const HandleAddBack = (data) => {
        setConfirmAddBack(true);
        setAddBack(data);
    }

    const handleButtonYes = async () => {
        let dataToUpdate = confirm ? removed : addBack;

        const isDeviceAlarm = dataToUpdate.alarmType === 'device' ||
            dataToUpdate.type === 'alarm_device' ||
            dataToUpdate.originalData?.type === 'alarm_device';

        const updatedData = {
            ...dataToUpdate,
            removedByUser: confirm,
            type: isDeviceAlarm ? 'alarm_device' : 'alarm_sector'
        };

        const success = await handleUpdateAlarm(updatedData);

        if (success) {
            setFinalList(prevList => {
                if ((showCondition && !confirm) || (!showCondition && confirm)) {
                    return prevList.filter(item =>
                        item.id !== dataToUpdate.id &&
                        item.metadata?.alarmNoteId !== dataToUpdate.alarmNoteId
                    );
                }

                return prevList.map(item => {
                    if (item.id === dataToUpdate.id ||
                        item.metadata?.alarmNoteId === dataToUpdate.alarmNoteId) {
                        return {
                            ...item,
                            metadata: {
                                ...item.metadata,
                                removedByUser: confirm.toString()
                            }
                        };
                    }
                    return item;
                });
            });
        }

        setConfirm(false);
        setConfirmAddBack(false);
        setVisible(false);
    };

    const handleButtonNo = () => {
        setConfirm(false);
        setConfirmAddBack(false);
    }

    const handleSaveObs = async (data, newObservation) => {
        try {
            if (!newObservation || !newObservation.trim()) {
                return;
            }

            const newObs = {
                userId: user.user_id,
                userName: user.name,
                obs: newObservation,
                date: moment().format('DD-MM-YYYY')
            };

            const existingObs = Array.isArray(data.metadata.clientObs) ? [...data.metadata.clientObs] : [];


            const updatedData = {
                ...data,
                metadata: {
                    ...data.metadata,
                    clientObs: [newObs, ...existingObs],
                },
            };
            await handleUpdateAlarm(updatedData);

            setSaveMsg(true);
            return newObs;
        } catch (error) {
            console.error("Observation error:", error);
            return null;
        }
    };



    const handleHide = () => {
        setVisible(false);
    };

    const handleTypeObs = (data) => {
        setObs(data);
    };

    const loadMoreAlarms = async () => {
        if (loading) return;

        try {
            const nextPage = page + 1;
            const newAlarms = await fetchEvents(nextPage, rowsPerPage);

            if (!newAlarms || newAlarms.length === 0) {
                setHasMore(false);
                return;
            }

            setFinalList(prevList => [...prevList, ...newAlarms]);
            setPage(nextPage);
        } catch (error) {
            toast.current.show({
                severity: 'error',
                summary: intl.formatMessage({ id: 'error_loading_alarms' }),
                life: 3000
            });
        }
    };

    const renderAlarm = (alarm, index) => (
        <AlarmCardList
            key={index}
            option={alarm}
            alarmSeeChange={handleAlarmSeeChange}
            remove={HandleRemove}
            addBack={HandleAddBack}
            verifyButtonClick={handleVerifyButtonClick}
        />
    );

    return (
        <>
            <div className="flex flex-column align-items-center gap-3">
                {!visible ? <Toast ref={toast} position="top-center" /> : <Toast ref={toast} position="center" style={{ display: 'none' }} />}
                <ConfirmModal show={confirm} onYesClick={handleButtonYes} onNoClick={handleButtonNo} onClose={handleButtonNo} message={intl.formatMessage({ id: "message_remove_alarm" })} />
                <ConfirmModal show={confirmAddBack} onYesClick={handleButtonYes} onNoClick={handleButtonNo} onClose={handleButtonNo} message={intl.formatMessage({ id: "message_add_back_alarm" })} />
                <CustomDropdown show={isOpen} onClose={() => setIsOpen(false)}>
                    <CustomToggle as={"a"} style={{ height: "auto" }} onClick={() => setIsOpen(!isOpen)}>
                        <Tooltip target=".custom-target-icon" />
                        <AlarmIcon data={colorAlarm?.toString()} className="fas fa-bell custom-target-icon"
                            data-pr-tooltip={intl.formatMessage({ id: "tooltip_alarms" })}
                            data-pr-position="left"
                        ><FaBell size={25} /></AlarmIcon>
                    </CustomToggle>
                    <CustomMenu>
                        <Dropdown.Header>
                            <ContainerCardOpt>
                                {showCondition ? (
                                    <>
                                        <Tooltip target=".custom-target-eraserr" />
                                        <AlarmCardIconReturn className="pi pi-arrow-circle-left custom-target-eraserr"
                                            data-pr-tooltip={intl.formatMessage({ id: "tooltip_clean" })}
                                            data-pr-position="left"
                                            onClick={handleCleanFilterButtonClick}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <AlarmLabelCheckBox>
                                            <Checkbox
                                                value="see"
                                                checked={filters.seen}
                                                onChange={(e) => handleFilterChange('seen', e.checked)}
                                                style={{ transform: 'scale(0.8)' }}
                                            />
                                            <FormattedMessage id="tooltip_seen" />
                                        </AlarmLabelCheckBox>

                                        <AlarmLabelCheckBox>
                                            <Checkbox
                                                value="unsee"
                                                checked={filters.unseen}
                                                onChange={(e) => handleFilterChange('unseen', e.checked)}
                                                style={{ transform: 'scale(0.8)' }}
                                            />
                                            <FormattedMessage id="tooltip_unseen" />
                                        </AlarmLabelCheckBox>
                                    </>
                                )}

                                {!showCondition ? <AlarmCardIconFile className="pi pi-file custom-target-file"
                                    onClick={handleFilterButtonFile}
                                > <FormattedMessage id="tooltip_archive" /></AlarmCardIconFile> : <AlarmCardIconFile className="pi pi-file custom-target-file"
                                > <FormattedMessage id="tooltip_archive" /></AlarmCardIconFile>}


                            </ContainerCardOpt>

                            {!showCondition && (
                                <>
                                    <div style={{
                                        height: '1px',
                                        backgroundColor: '#e0e0e0',
                                        margin: '10px 0',
                                        width: '100%'
                                    }} />

                                    {!disableDeviceAlarmsFeatures &&
                                        <div className="d-flex align-items-center gap-3" style={{ padding: '0 10px' }}>
                                            <div style={{
                                                fontWeight: '600',
                                                color: '#333',
                                                fontSize: '14px',
                                                paddingLeft: '0'
                                            }}>
                                                <FormattedMessage id="notification_type_alarm" />:
                                            </div>


                                            <AlarmLabelCheckBox>
                                                <Checkbox
                                                    value="sector_alarm"
                                                    checked={filters.sectorAlarm}
                                                    onChange={(e) => handleFilterChange('sectorAlarm', e.checked)}
                                                    style={{ transform: 'scale(0.8)' }}
                                                />
                                                <span><FormattedMessage id="notification_sector_alarm" /></span>
                                            </AlarmLabelCheckBox>
                                            <AlarmLabelCheckBox>
                                                <Checkbox
                                                    value="device_alarm"
                                                    checked={filters.deviceAlarm}
                                                    onChange={(e) => handleFilterChange('deviceAlarm', e.checked)}
                                                    style={{ transform: 'scale(0.8)' }}
                                                />
                                                <span><FormattedMessage id="notification_device_alarm" /></span>
                                            </AlarmLabelCheckBox>

                                        </div>
                                    }
                                </>
                            )}
                        </Dropdown.Header>

                        {loading ? (
                            <LoadingSpinner>
                                <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>
                                <p><FormattedMessage id="notification_loading_alarms" /></p>
                            </LoadingSpinner>
                        ) : (
                            <>
                                <AlarmListContainer>
                                    <InfiniteScroll
                                        items={finalList}
                                        renderItem={(alarm, index) => (
                                            <div key={index} className="alarm-item">
                                                {renderAlarm(alarm, index)}
                                            </div>
                                        )}
                                        fetchMore={loadMoreAlarms}
                                        hasMore={hasMore && totalRecords > finalList.length}
                                        loading={loading}
                                        height="60vh"
                                        threshold={150}
                                        className="alarm-list-scroll-container"
                                        emptyComponent={
                                            <ContainerMsgNoAlarm>
                                                <FormattedMessage id="no_alarm_message" />
                                            </ContainerMsgNoAlarm>
                                        }
                                        loadingComponent={
                                            <LoadingSpinner>
                                                <i className="pi pi-spin pi-spinner" style={{ fontSize: '1.5rem' }}></i>
                                                <p><FormattedMessage id="notification_loading_alarms" /></p>
                                            </LoadingSpinner>
                                        }
                                    />
                                </AlarmListContainer>
                            </>
                        )}
                    </CustomMenu>
                </CustomDropdown>
                <div className="flex justify-content-center">
                    <NotificationAlarmModal
                        visible={visible}
                        selectedSeeCheck={selectedSeeCheck}
                        onHide={handleHide}
                        selectedAlarm={selectedAlarm}
                        onUpdateAlarm={handleUpdateAlarm}
                        alarmSeeChange={handleAlarmSeeChange}
                        remove={HandleRemove}
                        addBack={HandleAddBack}
                        textObs={obs}
                        historyObs={oldObs}
                        typeObs={handleTypeObs}
                        saveObs={handleSaveObs}
                        showMsg={showMsgModal}
                        textAlert={msgModal}
                        alarmType={alarmType}
                    />
                </div>
            </div>
        </>
    );
}