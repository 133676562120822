import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import ImageUploader from '../../components/ManagerAlarms/ImageUploader/index';
import Header from '../../components/Header';
import DropdownCompRegion from '../../components/ManagerAlarms/Dropdown/Region/index';
import DropdownCompAlarm from '../../components/ManagerAlarms/Dropdown/Alarm/index';
import { Toast } from 'primereact/toast';
import { type_alarms, update_alarms_v2 } from '../../services/alarms';
import { allSectors as sectors } from '../../services/dashboard';
import { addLocale } from 'primereact/api';
import Container from 'react-bootstrap/Container';
import 'primereact/resources/themes/lara-light-indigo/theme.css'; // theme
import 'primereact/resources/primereact.css';

import { FormContainer, Title, Form, FormGroup, Label, ButtonGroup, Button } from './styles';

const EditFormWithImage = () => {

  const { id } = useParams();
  const location = useLocation();
  const toast = useRef(null);
  const overlayRef = useRef(null);
  const navigate = useNavigate();
  const [selectedAlarm, setSelectedAlarm] = useState(null);
  const [time, setTime] = useState(null);
  const [date, setDate] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [description, setDescription] = useState('');
  const [fullDescription, setFullDescription] = useState('');
  const [returnManagerAlarm, setReturnManagerAlarm] = useState(false);
  const [idClient, setIdClient] = useState(null);
  const [alarmType, setAlarmType] = useState(null);
  const [region, setRegion] = useState(null);
  const [alarmId, setAlarmId] = useState(null);
  const [image, setImage] = useState(null);
  const [newImage, setnewImage] = useState(false);

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'Hoje',
    clear: 'Limpar'
  });

  addLocale('pt-BR-CUS', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'Agora',
    clear: 'Limpar'
  });

  useEffect(() => {
    setIdClient(id);
    setReturnManagerAlarm(false);
  }, [returnManagerAlarm]);

  useEffect(() => {
    getAlarms();
    getSectors(location.state.data.clientId);
    setAlarmId(location.state.data.alarmNoteId);
    setDate(location.state.data.date);
    setTime(location.state.data.date);
    setSelectedAlarm(location.state.data.alarmTypeId)
    setSelectedRegion(location.state.data.sectorId)
    setDescription(location.state.data.shortDescription);
    setFullDescription(location.state.data.completeDescription);
    setImage(location.state.data.alarmNoteImg);
  }, [location])

  const handleSubmit = async (e) => {
    e.preventDefault();
    const combinedOutput = formatDate(date) + ' ' + formatTime(time);
    const payload = {
      clientId: location.state.data.clientId,
      alarmTypeId: selectedAlarm,
      alarmNoteId: alarmId,
      sectorId: selectedRegion,
      alarmNoteDate: combinedOutput,
      shortDescription: description,
      completeDescription: fullDescription,
      alarmNoteImg: image,
      seen: false,
      removedByUser: false,
      deletedByOperation: false,
      showToUser: true
    }
    try {
      await update_alarms_v2(payload);
      toast.current.show({ severity: 'success', summary: 'Alarme editado com sucesso.', life: 3000 });
      setTimeout(() => {
        setReturnManagerAlarm(true);
        navigate('/manager-alarm');
      }, 3000);
    } catch (error) {
      if (error.response) {
        const msg = error.response.data.message;
        toast.current.show({ severity: 'error', summary: 'Error', detail: msg, life: 3000 });
      } else if (error.request) {
        const message = error.message;
        toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
      }
    }
  };

  const handleDropdownChangeRegion = (e) => {
    setSelectedRegion(e.value);
  };

  const handleDropdownChangeAlarm = (e) => {
    setSelectedAlarm(e.value);
  };

  const callReturn = () => {
    setReturnManagerAlarm(true);
    navigate('/manager-alarm');
  };

  function formatDate(dateString) {
    const dateObj = new Date(dateString);
    const year = dateObj.getUTCFullYear();
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getUTCDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function formatTime(dateString) {
    const dateObj = new Date(dateString);
    dateObj.setHours(dateObj.getHours());
    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getUTCSeconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  }

  const getAlarms = async () => {
    try {
      const temp = await type_alarms();
      setAlarmType(temp.data);
    } catch (error) {
      if (error.response) {
        const msg = error.response.data.message;
        toast.current.show({ severity: 'error', summary: 'Error', detail: msg, life: 3000 });
      } else if (error.request) {
        const message = error.message;
        toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
      }
    }
  }

  const getSectors = async (data) => {
    const temp = {
      clientId: data
    };
    try {
      const resp = await sectors(temp);
      setRegion(resp.data);
    } catch (error) {
      if (error.response) {
        const msg = error.response.data.message;
        toast.current.show({ severity: 'error', summary: 'Error', detail: msg, life: 3000 });
      } else if (error.request) {
        const message = error.message;
        toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
      }
    }
  }

  const onChangeNewImage = () => {
    setImage(null)
  };

  const handleImageChange = (base64) => {
    setnewImage(true);
    setImage(base64);
  };

  const handleSelectDate = () => {
    return(
      <div type='button' style={{cursor: 'pointer', textAlign: 'center', fontWeight: 'bolder', border: '2px solid grey', padding: 3}} onClick={() => overlayRef.current.hide()}>Confirmar</div>
    )
  }

  return (
    <>
      <Header />
      <Container>
        <Title>Criar novo alarme</Title>
        <Toast ref={toast} />
        <div className="row">
          <div className="col-lg-6 col-md-12" style={{ marginTop: '6%', textAlign: 'center' }}>
            <ImageUploader onImageChange={handleImageChange} status={image} onNewImage={onChangeNewImage} style={{ width: '100%', height: '100vh', objectFit: 'cover' }} />
            {!newImage && <img src={image} style={{ width: '100%' }} />}
          </div>
          <div className="col-lg-6 col-md-12" style={{ display: 'flex', justifyContent: 'center' }}>
            <FormContainer>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  {selectedAlarm && <Label htmlFor="alarm">Tipo de alarme:</Label>}
                  <DropdownCompAlarm data={alarmType} handleDropdownChangeAlarm={handleDropdownChangeAlarm} value={selectedAlarm} />
                </FormGroup>

                <FormGroup>
                  <div style={{ display: 'flex' }}>
                    <div>
                      {time && <Label htmlFor="time">Hora:</Label>}
                      <Calendar
                        id="time"
                        ref={overlayRef}
                        value={time}
                        onChange={(e) => setTime(e.value)}
                        showTime
                        dateFormat="hh:mm tt"
                        className="p-inputtext-sm"
                        timeOnly
                        placeholder='Hora'
                        showButtonBar
                        locale="pt-BR-CUS"
                        rules={{ required: 'Date is required.' }}
                        footerTemplate={handleSelectDate}
                      />
                    </div>
                    <div style={{ marginLeft: 40 }}>
                      {date && <Label htmlFor="date">Data:</Label>}
                      <Calendar
                        id="date"
                        value={date}
                        onChange={(e) => setDate(e.value)}
                        dateFormat="dd/mm/yy"
                        className="p-inputtext-sm"
                        placeholder='Data'
                        showButtonBar
                        locale="pt-BR"
                        rules={{ required: 'Date is required.' }}
                      />
                    </div>
                  </div>
                </FormGroup>

                <FormGroup>
                  {selectedRegion && <Label htmlFor="region">Região:</Label>}
                  <DropdownCompRegion data={region} handleDropdownChangeRegion={handleDropdownChangeRegion} value={selectedRegion} />
                </FormGroup>

                <FormGroup>
                  {description && description.length > 0 && <Label htmlFor="description">Descrição resumida:</Label>}
                  <InputText
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="form-control"
                    placeholder='Descrição resumida'
                    style={{ backgroundColor: 'rgb(230, 230, 230)' }}
                  />
                </FormGroup>

                <FormGroup>
                  {fullDescription && fullDescription.length > 0 && <Label htmlFor="fullDescription">Descrição completa:</Label>}
                  <InputTextarea
                    id="fullDescription"
                    value={fullDescription}
                    onChange={(e) => setFullDescription(e.target.value)}
                    rows={5}
                    className="form-control"
                    placeholder='Descrição completa'
                    style={{ backgroundColor: 'rgb(230, 230, 230)' }}
                  />
                </FormGroup>

                <ButtonGroup>
                  <Button type="submit" className="btn btn-danger" onClick={callReturn}>Cancelar</Button>
                  <Button type="submit" className="btn" style={{ backgroundColor: '#6eff94' }}>Salvar</Button>
                </ButtonGroup>
              </Form>
            </FormContainer>
          </div>
        </div>
      </Container>
    </>
  );
};

export default EditFormWithImage;
