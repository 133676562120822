import axios from 'axios';
import { URL_KDI_API as BASE_URL, TIMEOUT_REQ } from '../config/config';

const api = axios.create({
  baseURL: BASE_URL,
  timeout: TIMEOUT_REQ,
});

api.interceptors.request.use(
  (config) => {
    try {
      const userData = sessionStorage.getItem('@ada-user');
      if (userData) {
        const parsedData = JSON.parse(userData);
        const token = parsedData?.state?.user?.token;
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }
    } catch (error) {
      console.error('Erro ao recuperar o token:', error);
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default api;
