import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    gap: 0.3rem;
  }
`;

export const CustomButton = styled.button`
  background-color: #696969;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #363636;
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }

  &:active {
    transform: translateY(0);
    box-shadow: none;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none;
  }
`;

export const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  width: auto;
  min-width: 200px;
  max-width: 100%;
  height: 40px;
  box-sizing: border-box;
  margin: 0;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    height: auto;
    min-width: 150px;
    gap: 0.5rem;
    
    label {
      font-size: 0.9rem;
      flex-basis: 100%;
    }

    input[type="range"] {
      flex: 1;
      min-width: 80px;
    }

    span {
      min-width: 30px;
    }
  }

  input[type="range"] {
    flex: 1;
    min-width: 100px;
    margin: 0;
  }

  label {
    white-space: nowrap;
    margin: 0;
  }

  span {
    min-width: 40px;
    text-align: right;
    margin: 0;
  }
`;

export const SizeSelector = styled.select`
  padding: 0.5rem;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #696969;
  background-color: white;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    border-color: #363636;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }

  &:focus {
    outline: none;
    border-color: #363636;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }
`;