import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import { StyledMultiSelect, LoadingIcon } from "./styles"; // Ajuste o caminho conforme necessário
import { MdClear } from "react-icons/md";
import { useIntl } from "react-intl";

const AlarmTypeFilterSerial = ({ options, devSerialOptions, isLoading, onLazyLoad, setSearchSerialNumber, isLoadingFilterMessage }) => {
  const intl = useIntl();
  const [targetElement, setTargetElement] = useState(null);

  useEffect(() => {
    const element = document.querySelector('.p-virtualscroller.p-multiselect-items-wrapper');
    if (element) {
      setTargetElement(element);
    }
  }, [onLazyLoad]);

  const handleFilter = (e) => {
    setSearchSerialNumber(e.filter);
  };

  return (
    <div>
      <StyledMultiSelect
        value={options.value}
        options={devSerialOptions}
        onChange={(e) => options.filterCallback(e.value)}
        onFilter={handleFilter}
        placeholder={intl.formatMessage({ id: 'manager_alarms_plus_filter_by_device' })}
        showClear
        filter
        filterPlaceholder={intl.formatMessage({ id: 'msg_input_filter' })}
        emptyFilterMessage={intl.formatMessage({ id: 'empty_filter_message' })}
        closeIcon={
          isLoading ? (
            <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <LoadingIcon />
            </span>
          ) : (
            <MdClear size={25} />
          )
        }
        virtualScrollerOptions={{
          lazy: true,
          onLazyLoad: onLazyLoad,
          itemSize: 40,
          showLoader: true,
          delay: 50,
          threshold: '50%',
        }}
      />

      {targetElement  && isLoadingFilterMessage &&
        ReactDOM.createPortal(
          <div className="loading-spinner" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignSelf: 'center', backgroundColor: 'rgba(0, 0, 0, 0.4)', height: '100%'}}>
               <LoadingIcon />
          </div>,
          targetElement
        )}
    </div>
  );
};

export default React.memo(AlarmTypeFilterSerial);