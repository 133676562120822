import styled from 'styled-components';
import { InputSwitch } from 'primereact/inputswitch';

export const ControlPanel = styled.div`
  position: absolute;
  left: 0px;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  top:80px;
  color: white;
  font-size: 14px;
`;

export const LegendContainer = styled.div`
  justify-items: center;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: #ffffffdd;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  z-index: 1000;
`;

export const ColorScale = styled.div`
  width: 20px;
  height: 100px;
  background: linear-gradient(
    to bottom, 
    red, orange, yellow, blue
  );
`;

export const LegendLabel = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: black;
  margin: 5px 0;
`;


export const CustomInputSwitch = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap:10px;
  background-color: #ffffffdd;
  border-radius: 5px;
  color: black;
  box-shadow: 0 2px 4px rgba(0,0,0,0.15);
  padding: 5px;
  font-size: 14px;
`;

export const ButtonDraw = styled.button`
  background: none padding-box rgb(255, 255, 255);
  display: table-cell;
  border: 0px;
  margin: 0px;
  padding: 0px 12px;
  text-transform: none;
  appearance: none;
  position: relative;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  text-align: center;
  height: 40px;
  vertical-align: middle;
  color: rgb(0, 0, 0);
  font-family: Roboto, Arial, sans-serif;
  font-size: 18px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  min-width: 45px;
  font-weight: 500;

  &:hover {

    background-color: rgba(0, 0, 0, 0.1); 
  }

  &:active {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px -1px;
    transform: translateY(1px); 
  }

  ${props => props.primary && `
    background-color: #007bff; 
    color: white;
  `}

  ${props => props.danger && `
    background-color: #dc3545; 
    color: white;
  `}
`;

export const StyledInputSwitch = styled(InputSwitch)`
/* Estilo para a trilha (fundo) do InputSwitch */
.p-inputswitch-slider {
  background-color: #cccccc; /* Cor da trilha quando desligado */
  transition: background-color 0.3s ease; /* Transição suave */

  &:before {
    background-color: #ffffff; /* Cor do thumb (bolinha) */
  }
}

/* Estilo para a trilha quando o InputSwitch está ligado */
&.p-inputswitch-checked .p-inputswitch-slider {
  background-color: ${props => props.theme.colors.primary}; /* Cor da trilha quando ligado */
}

/* Estilo para o thumb (bolinha) quando o InputSwitch está ligado */
&.p-inputswitch-checked .p-inputswitch-slider:before {
  background-color: #ffffff; /* Cor do thumb quando ligado */
}
`;

export const GearIcon = styled.div`
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

export const Input = styled.input`
  width: 100px;
  padding: 5px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
`;

export const Button = styled.button`
  padding: 5px 10px;
  font-size: 12px;
  background-color: #8086C0;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #8066C0;
  }
`;

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
`;

export const ToggleLabel = styled.span`
  font-size: 12px;
  color: black;
`;

export const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
`;

export const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;

  &:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

export const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${ToggleSlider} {
    background-color: #8086C0;
  }

  &:checked + ${ToggleSlider}:before {
    transform: translateX(20px);
  }
`;