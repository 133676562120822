import React, { useState, useEffect } from 'react'
import { Checkbox } from 'primereact/checkbox';
import { FormattedMessage, useIntl } from 'react-intl';
import deviceIcon from '../../../assets/img/icons/device_alarm.svg';
import sectorIcon from '../../../assets/img/icons/sector_alarm.svg';
import {
  TitleCardSeen,
  ContainerCardBottom,
  ContainerCardButtons,
  CustomButton,
  CustomCard,
  IconContainer,
  CardSection,
  CardSpacer,
  CardContentColumn,
  CardBoldText,
  CardRightColumn,
  CheckboxContainer
} from './styles'

const AlarmCardList = ({ option, alarmSeeChange, remove, addBack, verifyButtonClick }) => {
  const intl = useIntl();
  const [isChecked, setIsChecked] = useState(false);

  const adaptAlarmData = (option) => {
    const isDeviceAlarm = option.type === 'alarm_device';
    
    const toBool = (value) => {
      if (typeof value === 'boolean') return value;
      if (value === 'true') return true;
      if (value === 'false') return false;
      return !!value;
    };
    
    const isSeen = toBool(option.seen) || toBool(option.metadata?.seen);
    return {
      id: option.id,
      description: option.description || option.shortDescription,
      clientId: option.client_id || option.metadata?.clientId || option.clientId,
      alarmTypeName: option.metadata?.alarmTypeName || option.alarmTypeName || (isDeviceAlarm ? 'Alarma de dispositivo' : 'Alarma de sector'),
      sectorName: option.metadata?.sectorName || option.sectorName,
      deviceSerial: option.metadata?.dev_serial || option.dev_serial || option.deviceId,
      deviceName: option.metadata?.deviceName || option.deviceName,
      seen: isSeen,
      removedByUser: toBool(option.metadata?.removedByUser || option.removedByUser),
      createdAt: option.created_at,
      updatedAt: option.updated_at,
      alarmNoteDate: option.metadata?.alarmNoteDate || option.alarmNoteDate,
      alarmNoteId: option.metadata?.alarmNoteId || option.id,
      alarmType: option.type === 'alarm_device' ? 'device' : 'sector',
      originalData: option,
      metadata: option.metadata || {}
    };
  };

  const adaptedAlarm = adaptAlarmData(option);
  
  const { alarmTypeName, sectorName, deviceSerial, seen, removedByUser, createdAt, alarmNoteId, alarmType, alarmNoteDate } = adaptedAlarm;

  const [dateDevice,hourDevice] = new Date(createdAt).toLocaleString().split(',');
  const [dateSector,hourSector] = new Date(alarmNoteDate).toLocaleString().split(',');



  useEffect(() => {
    setIsChecked(seen);
  }, [option, seen]);

  const handleCheckboxChange = async (e) => {
    const newCheckedValue = e.target.checked;
    setIsChecked(newCheckedValue);
    
    try {
      await alarmSeeChange(adaptedAlarm, newCheckedValue);
    } catch (error) {
      setIsChecked(!newCheckedValue);
    }
  };

  return <CustomCard>
    <IconContainer>
      <img src={alarmType === 'sector' ? sectorIcon : deviceIcon} alt="alarm" />
    </IconContainer>

    <CardSection>
      <CardSpacer></CardSpacer>
      <CardContentColumn>
        <CardBoldText>
          {alarmTypeName ? alarmTypeName : ''}
        </CardBoldText>
        <CardBoldText>
          {alarmType === 'sector' ? sectorName : deviceSerial}
        </CardBoldText>
        <CardBoldText>
          {alarmNoteId ? alarmNoteId : ''}
        </CardBoldText>

      </CardContentColumn>
      <CardRightColumn>
        <CheckboxContainer>
          <CardBoldText><FormattedMessage id="seen" /></CardBoldText>
          <Checkbox
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
        </CheckboxContainer>
        <CardBoldText>
          {alarmType === 'sector' ? dateSector : dateDevice}
        </CardBoldText>
        <CardBoldText>
          {alarmType === 'sector' ? hourSector : hourDevice}
        </CardBoldText>
      </CardRightColumn>
    </CardSection>
    <ContainerCardBottom>
      <ContainerCardButtons>
        {removedByUser === false ?
          <CustomButton label={intl.formatMessage({ id: "remove" })} icon="pi pi-folder" iconPos="left" rounded size="small" severity="secondary" onClick={() => remove(adaptedAlarm)} />
          : <CustomButton label={intl.formatMessage({ id: "add_back" })} icon="pi pi-folder" iconPos="left" rounded size="small" severity="secondary" onClick={() => addBack(adaptedAlarm)} />
        }
      </ContainerCardButtons>

      <ContainerCardButtons>
        <CustomButton label={intl.formatMessage({ id: "verify" })} icon="pi pi-external-link" iconPos="left" rounded size="small" severity="info" onClick={() => verifyButtonClick(adaptedAlarm)} />
      </ContainerCardButtons>
    </ContainerCardBottom>
  </CustomCard>
}

export default AlarmCardList