import React, { useState } from "react";
import { Slider } from "primereact/slider";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { useIntl, FormattedMessage } from "react-intl";
import { Dialog } from "primereact/dialog";
import { DISABLE_DEVICE_ALARMS_FEATURES } from "../../../../config/config";

const ConfigModal = ({
  visible,
  onHide,
  valueSlider,
  setValueSlider,
  selectedTag,
  setSelectedTag,
  tagList,
  devices,
  selectedRows,
  sendConfigDevices,
  setVisibleModalConfirm,
}) => {
  const intl = useIntl();

  const disableDeviceAlarmsFeatures = DISABLE_DEVICE_ALARMS_FEATURES == 'true'

  return (
    <div className="card flex justify-content-center">
      <Dialog
        visible={visible}
        onHide={onHide}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        draggable={false}
        resizable={false}
      >
        <div className="p-grid" style={{ display: "flex", flexDirection: "row", justifyContent: disableDeviceAlarmsFeatures ? "center" : 'start' }}>
          {!disableDeviceAlarmsFeatures && <>
            <div className="p-col-3" style={{ marginRight: "2rem" }}>
              <Slider
                value={valueSlider}
                onChange={(e) => setValueSlider(e.value)}
                className="flex-auto"
                range
                orientation="vertical"
                style={{ height: "300px" }}
              />
            </div>
            <div
              className="p-col-1"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                marginRight: "8rem",
              }}
            >
              <div style={{ fontSize: "16px", fontWeight: "bold", userSelect: "none" }}>
                {Array.isArray(valueSlider) ? `${valueSlider[1]}` : valueSlider} M.C.A
              </div>
              <div style={{ fontSize: "16px", fontWeight: "bold", userSelect: "none" }}>
                {Array.isArray(valueSlider) ? `${valueSlider[0]}` : valueSlider} M.C.A
              </div>
            </div>
          </>}
          <div className="p-col-9">
            <div
              className="flex-auto"
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: disableDeviceAlarmsFeatures ? "3rem" : "1rem",
                width: "100%",
                marginTop: "6rem",
              }}
            >
              <span style={{ minWidth: "120px" }} className="p-col-3">
                <FormattedMessage id="config_modal_intall_points_vincule_tags" />
              </span>
              <MultiSelect
                value={selectedTag}
                onChange={(e) => setSelectedTag(e.value)}
                options={tagList}
                optionLabel="name"
                placeholder={intl.formatMessage({ id: "config_modal_intall_points_select_tags" })}
                maxSelectedLabels={10}
                display="chip"
                className="p-col-9"
                style={{ width: "24vw" }}
              />
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1rem" }}>
          <Button
            onClick={onHide}
            label={intl.formatMessage({ id: "cancel_button" })}
            severity="danger"
          />
          <Button
            onClick={() => {
              if (devices.filter((item) => selectedRows.includes(item.id)).length > 1) {
                setVisibleModalConfirm(true);
              } else {
                sendConfigDevices([]);
              }
            }}
            label={intl.formatMessage({ id: "confirm_button" })}
            severity="success"
          />
        </div>
      </Dialog>
    </div>
  );
};

export default ConfigModal;