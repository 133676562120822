import styled from 'styled-components';
import { FormCheck } from 'react-bootstrap';

export const Container = styled.div`
  position: absolute;
  background: #000000dd;
  color: white;
  width: ${props => props.$isExpanded ? '135px' : '27px'}; 
  bottom: calc(31.6vh - 119px);
  font-size: 11px;
  padding: 5px;
  transition: width 0.3s ease; 
`;

export const Title = styled.p`
  font-size: 10px;
  font-weight: bold;
  color: ${props => props.$isExpanded ? '#fff' : 'transparent'};
  transition: width 0.3s ease;
`;


export const CustomFormCheck = styled(FormCheck)`
  label {
    font-size: 10px;
    display: ${props => (props.$isExpanded ? 'block' : 'none')};
    opacity: ${props => (props.$isExpanded ? '1' : '0')};
    transition: opacity 0.3s ease, visibility 0.3s ease;
    visibility: ${props => (props.$isExpanded ? 'visible' : 'hidden')};
  }
`;

export const ExpandButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  background: #333;
  color: white;
  border: none;
  padding: 2px 5px;
  cursor: pointer;
  font-size: 10px;
  z-index: 10;
`;

