import styled, { css, keyframes } from "styled-components";
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';

const loadingAnimation = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

const loadingStyles = css`
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${loadingAnimation} 1.5s infinite;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: ${({ $isSidebarVisible, $isFirstOptionSelected }) =>
    $isFirstOptionSelected
      ? $isSidebarVisible
        ? '250px 1fr'
        : '50px 1fr'
      : $isSidebarVisible
        ? '250px repeat(4, 1fr)'
        : '50px repeat(4, 1fr)'};
  grid-template-rows: ${({ $isFirstOptionSelected }) =>
    $isFirstOptionSelected ? '1fr' : '0.3fr repeat(6, 1fr)'};
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  height: 93vh;
  padding: 15px;
`;

export const SidebarContainer = styled.div`
  grid-area: 1 / 1 / 8 / 2;
  background-color: #f4f4f4;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1vh;
`;

export const ToggleButton = styled.button`
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #8086C0;
  color: white;
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1;

  &:hover {
    background-color: #8086C0;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px;
  text-align: center;
  font-weight: bold;
  grid-area: 1 / 2 / 2 / 4;
`;

export const Title2 = styled.div`
   padding: 0px;
  text-align: center;
  font-weight: bold;
  grid-area: 1 / 4 / 2 / 6;
  display: ${({ $isFirstOptionSelected }) => ($isFirstOptionSelected ? 'none' : 'block')};
`;

export const MapContainer = styled.div`
  ${({ $skeleton }) => $skeleton ? loadingStyles : 'background-color: rgb(230, 230, 230);'};
  grid-area: 2 / 2 / 8 / 4;
  display: ${({ $isFirstOptionSelected }) => ($isFirstOptionSelected ? 'none' : 'block')};
  border: 1px solid #ccc;
`;

export const Map2Container = styled.div`
  background-color: #d0d0d0;
  grid-area: ${({ $isFirstOptionSelected }) =>
    $isFirstOptionSelected ? '1 / 2 / 8 / 6' : '2 / 2 / 8 / 4'};
`;

export const ChartContainer = styled.div`
  background-color: #d0d0d0;
  display: ${({ $isFirstOptionSelected }) => ($isFirstOptionSelected ? 'none' : 'block')};
`;

export const Chart1 = styled(ChartContainer)`
  grid-area: 2 / 4 / 5 / 6;
   ${({ $skeleton }) => $skeleton ? loadingStyles : 'background-color: rgb(230, 230, 230);'};
  height: 100%;
  margin-top: 0;
  border: 1px solid rgb(230, 230, 230);
  &:nth-child(1) {
    margin-top: 0;
  }
`;

export const Chart2 = styled(ChartContainer)`
  grid-area: 5 / 4 / 8 / 6;
  ${({ $skeleton }) => $skeleton ? loadingStyles : 'background-color: rgb(230, 230, 230);'};
  height: 100%;
  margin-top: 0;
  border: 1px solid rgb(230, 230, 230);
  &:nth-child(1) {
    margin-top: 0;
  }
`;

export const DropdownGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const DropdownLabel = styled.span`
  font-weight: bold;
  font-size: 14px;
`;

export const FiltersTitleContainer = styled.div`
  margin-bottom: 8vh; 
`;

export const FiltersTitle = styled.h2`
  font-size: 1.5rem; /* Tamanho da fonte */
  font-weight: bold; /* Negrito */
  color: #333; /* Cor do texto */
  margin: 0; /* Remove margens padrão do h2 */
`;

export const CleanFilterButton = styled.button`
  background-color: transparent;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  align-self: center; /* Centraliza horizontalmente */
  margin-top: auto; /* Coloca o botão no final da sidebar */
  color: #000000;
  &:hover {
    background-color: #fafafa;
    color:rgb(32, 31, 31);
  }
`;

export const ApplyFilterButton = styled.button`
  background-color: #8086C0;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  align-self: center; /* Centraliza horizontalmente */

  &:hover {
    background-color: #8086C0;
  }
`;

export const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoadingIcon = styled.span`
display: inline-block;
width: 16px;
height: 16px;
border: 2px solid #ccc;
border-top: 2px solid #007bff;
border-radius: 50%;
animation: ${spin} 1s linear infinite;
margin-left: 8px; /* Espaço entre o texto e o ícone */
`;

export const StyledMultiSelect = styled(MultiSelect)`
    width: 100%;
    border: 2px solid #8086C0;
    border-radius: 4px;
    background-color: #f4f4f4;
    padding: 8px;

    .p-multiselect-label {
        color: #333;
        font-size: 14px;
    }

    .p-multiselect-trigger {
        color: #8086C0;
    }
`;

// Estilizando o painel dropdown
export const StyledPanel = styled.div`
    background-color: #ffffff;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    .p-multiselect-item {
        padding: 8px 12px;
        color: #333;
    }

    .p-multiselect-item:hover {
        background-color: #f0f0f0;
    }

    .p-multiselect-header {
        background-color: #f8f9fa;
        border-bottom: 1px solid #ddd;
        padding: 8px 12px;
    }

    .p-multiselect-filter-container {
        margin-bottom: 8px;
    }

    .p-multiselect-filter {
        width: 100%;
        padding: 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
    }
`;

export const TitleContainer = styled.div`
    background: linear-gradient(
        to right,
        #cfcfcf 50%,
        ${props => (props.$isActive ? props.theme.colors.success : "transparent")} 50%
    );
    width: 70%;
    height: 100%;
    display: flex; 
    padding: 0 5px; 
`;

export const CompanyText = styled.span`
    color: ${props => props.theme.colors.black}; 
    align-self: center;
    font-size: 14px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    width: 50%;
    text-align: center;
`;

export const SectorText = styled.span`
    color: ${props => props.theme.colors.black}; 
    align-self: center;
    font-size: 14px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    width: 50%;
    text-align: center;
`;

export const StyledCalendar = styled(Calendar)`
.p-datepicker-trigger {
  background-color: ${props => props.theme.colors.primary};
  color: #ffffff; 
  border: none; 
  transition: background-color 0.3s ease; 

  &:hover {
    background-color: #6366F1;
  }

  &:active {
    background-color: ${props => props.theme.colors.primary};
  }
}
`;

