import React, { createContext, useContext, useState, useRef } from "react";
import dologin from "../services/login";
import dologinKeyclaok from "../services/login_keycloak";
import useUserStore from "../store/useUserStore";
import useUserRolesStore from "../store/useUserRolesStore";
import { jwtDecode } from "jwt-decode";

import { Toast } from "primereact/toast";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const toast = useRef(null);
  const { user, setUser } = useUserStore();
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const isLogged = user;
    return !!isLogged.user_id;
  });

  const login = async (email, password, token) => {
    return new Promise(async (resolve) => {
      try {
        const { setUserRoles } = useUserRolesStore.getState();

        const payload = { email, password };
        const response = password
          ? await dologin(payload)
          : await dologinKeyclaok(email, token);

        if (response) {
          if (password) {
            setUser(response.data);
          } else {
            let dataUser = response.data;
            dataUser.token = token;
            dataUser.user_id = dataUser.id;
            setUser(dataUser);
          }

          if (token) {
            const decoded = jwtDecode(token);
            const userRoles = decoded.roles || decoded.realm_access?.roles || [];
            const hasAdaPlus = userRoles.includes("ada-plus");
            setUserRoles([{ ada_plus: hasAdaPlus }]);
          }

          setIsAuthenticated(true);
          resolve(true);
        }
      } catch (error) {
        showError(error);
        setIsAuthenticated(false);
        resolve(false);
      }
    });
  };

  const logout = () => {
    sessionStorage.removeItem("@ada-user");
    sessionStorage.removeItem("@ada-company");
    localStorage.removeItem("isKcAda");
    setIsAuthenticated(false);
  };

  const showError = (error) => {
    console.log("error", error);
    const msg = error?.response.data.message;
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: msg,
      life: 3000,
    });
  };

  return (
    <>
      <Toast ref={toast} />
      <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
        {children}
      </AuthContext.Provider>
    </>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth deve ser usado dentro de um AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
