import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Paragraph, Item, Icon, ToggleButton } from './styles';
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import pinConnGreen from '../../assets/img/pins/pinConnGreen.png';
import pinConnYellow from '../../assets/img/pins/pinConnYellow.png';
import pinConnRed from '../../assets/img/pins/pinConnRed.png';

const LegendItem = ({ icon, text, bgColor = "#e6e6e6", textColor = "#000" }) => (
  <Item $bgColor={bgColor} $textColor={textColor}>
    {icon && <Icon src={icon} alt="" />}
    <FormattedMessage id={text} />
  </Item>
);

const Legend = ({ kindPin, connectivity }) => {
  const [isVisible, setIsVisible] = useState(true);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  if (!connectivity && kindPin === null) {
    return null;
  }

  return (
    <Container $isVisible={isVisible}>
      <ToggleButton onClick={toggleVisibility}>
        {isVisible ? <MdArrowForwardIos size={14} /> : <MdArrowBackIosNew size={14} />}
      </ToggleButton>

      {isVisible && (
        <>
          {kindPin === "value" && (
            <>
              <Paragraph><FormattedMessage id="subtitle" /></Paragraph>
              <LegendItem text="subtitle_hydraulic_load" />
              <LegendItem text="subtitle_pressure" />
              <LegendItem text="subtitle_delta" />
              <LegendItem text="data_unavailable" bgColor="#000" textColor="#fff" />
            </>
          )}

          {kindPin === "pressure" && (
            <>
              <Paragraph><FormattedMessage id="subtitle" /></Paragraph>
              <LegendItem text="subtitle_pressure" />
              <LegendItem text="data_unavailable" bgColor="#000" textColor="#fff" />
            </>
          )}

          {connectivity && kindPin === null && (
            <>
              <Paragraph><FormattedMessage id="subtitle" /></Paragraph>
              <LegendItem icon={pinConnGreen} text="subtitle_connectivity_msg_last_24hs" />
              <LegendItem icon={pinConnYellow} text="subtitle_connectivity_msg_last_7days" />
              <LegendItem icon={pinConnRed} text="subtitle_connectivity_msg_more_7days" />
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default Legend;