import Keycloak from 'keycloak-js';
import { URL_BASE_KEYCLOAK, CLIENT_ID } from './config/config';
import { fetchDataFromAPIRealms } from './realms/realms';

let realms = [];
let defaultRealm = 'default';

const initializeRealms = async () => {
  const listLocalStorage = localStorage.getItem('realms-list-stt4');
  const last_client = localStorage.getItem('last_client');

  if (!listLocalStorage || !last_client) {
    try {
      const data = await fetchDataFromAPIRealms();

      if (typeof data === 'string') {
        realms = JSON.parse(data);
      } else if (Array.isArray(data)) {
        realms = data;
      } else {
        console.error('Data fetched from API is not a valid JSON string or array:', data);
        realms = []; 
      }

      localStorage.setItem('realms-list-stt4', JSON.stringify(realms));
    } catch (error) {
      console.error('Failed to fetch or parse realms data:', error);
      realms = []; 
    }
  } else {
    try {
      realms = JSON.parse(listLocalStorage);
      if (!Array.isArray(realms)) {
        console.error('Data in localStorage is not an array:', realms);
        realms = []; 
      }
    } catch (error) {
      console.error('Failed to parse localStorage data:', error);
      realms = [];
    }
  }


  if (last_client && last_client.length > 0) {
    const lastClientResult = realms.find(item => item.client === last_client);
    if (lastClientResult) {
      defaultRealm = lastClientResult.realm;
    }
  }

const path = window.location.pathname;
const partPath = path.split('/');
const client = String(partPath[partPath.length - 1]);
const result = realms?.find(item => item.client === client);
const realm = result ? result.realm : defaultRealm;

  return {
    url: URL_BASE_KEYCLOAK,
    realm: realm,
    clientId: CLIENT_ID,
  };
};

let keycloak = null;

const initKeycloak = async () => {
  if (keycloak) {
    return Promise.resolve(keycloak);
  }

  try {
    const keycloakConfig = await initializeRealms();
    keycloak = new Keycloak(keycloakConfig);

    const authenticated = await keycloak.init({ onLoad: 'check-sso' });
    return keycloak;
  } catch (error) {
    console.error('Failed to initialize Keycloak:', error);
  }
};

export { initKeycloak, keycloak };