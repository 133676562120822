import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useHeaderStore = create(persist(
  (set) => ({
    isHeaderVisible: true, // Estado inicial
    showHeader: () => set({ isHeaderVisible: true }), // Mostra o header
    hideHeader: () => set({ isHeaderVisible: false }), // Esconde o header
  }),
  {
    name: 'header-store', // Nome do item no localStorage
    getStorage: () => localStorage, // Define o armazenamento (localStorage é o padrão)
  }
));

export default useHeaderStore;
