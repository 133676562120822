import React, { useEffect, useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { ModalContainer, SectionTitle, InfoRow, InfoRowObs, CheckboxRow, CheckboxLabel, ContentContainer, InfoColumn, ImageColumn, AlarmImage, InputTextareaMsg, ButtonMsg, InputContainer, ZoomedImage, Overlay, ObservationContainer, ObservationText, StyledChip, MapContainer, CheckboxContainer } from './styles';
import { listCompleteDevices } from '../../../services/install_points_plus';
import { note_by_id_alarms, update_alarms_v2 } from '../../../services/alarms';
import { update_devices } from '../../../services/alarms';
import useUserStore from '../../../store/useUserStore';
import { useIntl, FormattedMessage } from 'react-intl';
import { Toast } from 'primereact/toast';
import { getDescriptionAlarms } from '../../../utils/descriptionAlarms';

const AlarmModalPlus = ({ visible, onHide, rowData, onLoad }) => {

  const intl = useIntl();
  const toast = useRef(null);
  const { user } = useUserStore();
  const [deviceData, setDeviceData] = useState(null);
  const [sectorData, setSectorData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newObservation, setNewObservation] = useState('');
  const [isZoomed, setIsZoomed] = useState(false);
  const [seenChecked, setSeenChecked] = useState(false);
  const [removedByUserChecked, setRemovedByUserChecked] = useState(false);

  useEffect(() => {
    if (visible && rowData) {
      setSeenChecked(getMetadataField('seen') === "true" || getMetadataField('seen') === true);
      setRemovedByUserChecked(getMetadataField('removedByUser') === "true" || getMetadataField('removedByUser') === true);

      if (rowData.type === 'alarm_device') {
        const serialNumber = getMetadataField('dev_serial');
        if (serialNumber) {
          fetchDeviceData(true, serialNumber);
        }
      }

      if (rowData.type === 'alarm_sector') {
        const alarmNoteId = getMetadataField('alarmNoteId');
        if (alarmNoteId) {
          fetchDeviceData(false, alarmNoteId);
        }
      }
    }
  }, [visible, rowData]);

  const fetchDeviceData = async (flag, data) => {
    setLoading(true);
    try {
      if (flag) {
        const response = await listCompleteDevices({ serial_number: data });
        if (response && response.items && response.items.length > 0) {
          setDeviceData(response.items[0]);
        }
      }
      if (!flag) {
        const { client_id } = rowData;
        const response = await note_by_id_alarms({
          'clientId': client_id,
          'alarmNoteId': data
        });
        setSectorData(response.data);
      }
    } catch (error) {
      console.error('Erro na requisição:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddObservation = async () => {
    if (!newObservation.trim()) return;

    try {
      const { name, user_id } = user;
      const newObs = {
        obs: newObservation,
        date: new Date().toLocaleString(),
        userName: name,
        userId: user_id,
      };

      const updatedSectorData = sectorData ? { ...sectorData } : { clientObs: [] };

      if (!updatedSectorData.clientObs) {
        updatedSectorData.clientObs = [];
      }

      updatedSectorData.clientObs = [...updatedSectorData.clientObs, newObs];

      setSectorData(updatedSectorData);

      setNewObservation('');

      await update_alarms_v2(updatedSectorData);

      onLoad();
    } catch (error) {
      console.error('Erro ao adicionar observação:', error);
    }
  };

  const handleCheckboxChange = async (field, checked) => {

    try {
      rowData.metadata[field] = checked;


      if (field === 'seen') {
        setSeenChecked(checked);
      } else if (field === 'removedByUser') {
        setRemovedByUserChecked(checked);
      }

      if (rowData.type === "alarm_sector") {
        await update_alarms_v2(rowData.metadata);
      }

      if (rowData.type === "alarm_device") {
        delete rowData.updated_at;

        await update_devices(rowData);
      }

      onLoad();
    } catch (error) {
      console.error('Erro ao atualizar checkbox:', error);
    }
  };

  if (!rowData) return null;

  const isAlarmDevice = rowData.type === 'alarm_device';
  const isAlarmSector = rowData.type === 'alarm_sector';

  const getMetadataField = (field, defaultValue = 'N/A') => {
    return rowData.metadata ? rowData.metadata[field] || defaultValue : defaultValue;
  };

  const getDeviceField = (field, defaultValue = 'N/A') => {
    return deviceData ? deviceData[field] || defaultValue : defaultValue;
  };

  const getDeviceFieldRow = (field, defaultValue = 'N/A') => {
    return rowData ? rowData[field] || defaultValue : defaultValue;
  };

  const getSectorField = (field, defaultValue = 'N/A') => {
    return sectorData ? sectorData[field] || defaultValue : defaultValue;
  };

  const renderAlarmDeviceContent = () => {
    if (loading) {
      return <p><FormattedMessage id="loading" /></p>;
    }

    if (!deviceData) {
      return <p><FormattedMessage id="title_modal_manager_alarm_plus_no_data_available" /></p>;
    }

    const address = `${getDeviceField('address')}, ${getDeviceField('number')}, ${getDeviceField('neighborhood')}, ${getDeviceField('city')} - ${getDeviceField('state')}, ${getDeviceField('country')}, ${getDeviceField('zipcode')}`;
    const fullAddress = address;

    return (
      <ContentContainer>
        <InfoColumn>
          <SectionTitle><FormattedMessage id="title_heat_map_sector" />: {getMetadataField('sectorName')}</SectionTitle>
          <InfoRow>
            <strong><FormattedMessage id="alarm" />:</strong> {getMetadataField('alarmTypeName')}
          </InfoRow>
          <InfoRow>
            <strong><FormattedMessage id="description" /></strong>: {getDeviceFieldRow('description')}
          </InfoRow>
          <InfoRow>
            <strong><FormattedMessage id="title_modal_manager_alarm_plus_date_hour" />:</strong> {new Date(rowData.created_at).toLocaleString()}
          </InfoRow>
          <InfoRow>
            <strong><FormattedMessage id="title_modal_manager_alarm_plus_serie_number" />:</strong> {getMetadataField('dev_serial')}
          </InfoRow>
          <InfoRow>
            <strong><FormattedMessage id="title_modal_manager_alarm_plus_install_point" />:</strong> {getDeviceField('name')}
          </InfoRow>
          <InfoRow>
            <strong><FormattedMessage id="alarm" />:</strong> {fullAddress}
          </InfoRow>
          <InfoRow>
            <strong>Tags:</strong>
            {deviceData.tags && deviceData.tags.map((tag, index) => (
              <StyledChip
                key={`${tag.id}-${index}`}
                label={tag.name}
                $backgroundColor={tag.desc || '#007ad9'}
                $color={tag.desc === 'yellow' ? 'black' : 'white'}
              />
            ))}
          </InfoRow>
          <CheckboxRow>
            <input
              type="checkbox"
              checked={seenChecked}
              onChange={(e) => handleCheckboxChange('seen', e.target.checked)}
            />
            <CheckboxLabel><FormattedMessage id="seen" /></CheckboxLabel>
          </CheckboxRow>
          <CheckboxRow>
            <input
              type="checkbox"
              checked={removedByUserChecked}
              onChange={(e) => handleCheckboxChange('removedByUser', e.target.checked)}
            />
            <CheckboxLabel><FormattedMessage id="tooltip_archive" /></CheckboxLabel>
          </CheckboxRow>
        </InfoColumn>
      </ContentContainer>
    );
  };

  const renderAlarmSectorContent = () => {
    const imageUrl = getSectorField('alarmNoteImg');
    const userObs = getSectorField('clientObs');

    if (loading) {
      return <p><FormattedMessage id="loading" /></p>;
    }

    if (!sectorData) {
      return <p><FormattedMessage id="title_modal_manager_alarm_plus_no_data_available" /></p>;
    }

    return (
      <ContentContainer>
        <InfoColumn>
          <SectionTitle><FormattedMessage id="title_heat_map_sector" />: {getMetadataField('sectorName')}</SectionTitle>
          <InfoRow><strong><FormattedMessage id="alarm" /></strong>: {getMetadataField('alarmTypeName')}</InfoRow>
          <InfoRow><strong><FormattedMessage id="description" /></strong>: {getDescriptionAlarms(getMetadataField('alarmTypeName')) || getMetadataField('completeDescription')}</InfoRow>
          <InfoRow><strong><FormattedMessage id="title_modal_manager_alarm_plus_date_hour" /></strong>: {new Date(getMetadataField('alarmNoteDate')).toLocaleString()}</InfoRow>
          <InfoRow><strong><FormattedMessage id="title_modal_manager_alarm_plus_main_device" /></strong>: {getMetadataField('dev_serial')}</InfoRow>
          <label><FormattedMessage id="title_modal_manager_alarm_plus_obs" />:</label>
          <InfoRowObs>
            {userObs && Array.isArray(userObs) ? (
              userObs.slice().reverse().map((obs, index) => (
                <ObservationContainer key={index}>
                  <ObservationText><strong><FormattedMessage id="manager_alarms_plus_date" />:</strong> {obs.date}</ObservationText>
                  <ObservationText><strong><FormattedMessage id="title_modal_manager_alarm_plus_user_msg" />:</strong> {obs.userName}</ObservationText>
                  <ObservationText><strong><FormattedMessage id="title_modal_manager_alarm_plus_obs" />:</strong> {obs.obs}</ObservationText>
                </ObservationContainer>
              ))
            ) : (
              <div>N/A</div>
            )}
          </InfoRowObs>
          <InputContainer>
            <InputTextareaMsg
              value={newObservation}
              onChange={(e) => setNewObservation(e.target.value)}
              rows={3}
              cols={50}
              placeholder={intl.formatMessage({ id: 'title_modal_manager_alarm_plus_add_new_obs' })}
            />
            <ButtonMsg onClick={handleAddObservation}>+</ButtonMsg>
          </InputContainer>
         
        </InfoColumn>
        <ImageColumn>
          <MapContainer>
            {imageUrl && (
              <>
                <AlarmImage
                  src={imageUrl}
                  alt="Imagen del Alarme"
                  onClick={() => setIsZoomed(true)}
                  style={{ cursor: 'pointer' }}
                />
                {isZoomed && (
                  <Overlay onClick={() => setIsZoomed(false)}>
                    <ZoomedImage src={imageUrl} alt="Imagen del Alarme en Zoom" />
                  </Overlay>
                )}
              </>
            )}
            {!imageUrl && (
              <p>
                <FormattedMessage id="title_modal_manager_alarm_plus_no_img_available" />
              </p>
            )}
          </MapContainer>
          
          <CheckboxContainer>
            <CheckboxRow>
              <input
                type="checkbox"
                checked={seenChecked}
                onChange={(e) => handleCheckboxChange('seen', e.target.checked)}
              />
              <CheckboxLabel>
                <FormattedMessage id="seen" />
              </CheckboxLabel>
            </CheckboxRow>
            
            <CheckboxRow>
              <input
                type="checkbox"
                checked={removedByUserChecked}
                onChange={(e) => handleCheckboxChange('removedByUser', e.target.checked)}
              />
              <CheckboxLabel>
                <FormattedMessage id="tooltip_archive" />
              </CheckboxLabel>
            </CheckboxRow>
          </CheckboxContainer>
        </ImageColumn>
      </ContentContainer>
    );
  };

  return (
    <Dialog header={getMetadataField('alarmNoteId', 'Detalhes do Alarme')} visible={visible} style={{ width: '80vw', height: '75vh'}} onHide={onHide}>
      <ModalContainer>
        {isAlarmDevice && renderAlarmDeviceContent()}
        {isAlarmSector && renderAlarmSectorContent()}
      </ModalContainer>
    </Dialog>
  );
};

export default AlarmModalPlus;