import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Chip } from 'primereact/chip';
import { MdPinDrop } from 'react-icons/md';
import { useIntl, FormattedMessage } from 'react-intl';
import { listCompleteDevices } from '../../services/install_points_plus';
import { FiltersTitleContainer, FiltersTitle, DropdownWrapper, DropdownLabel, ApplyFilterButton, CleanFilterButton, ButtonWrapper, DropdownGroup, DropdownItem } from './styles';
import macroOffPurpple from '../../assets/img/icons/macroOffPurpple.png';
import DateRangePicker from '../CalendarHomePlus';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import _debounce from "lodash/debounce";
import useCompanySectorStore from '../../store/useCompanySectorStore';

const endDate = new Date();
const startDate = new Date();
startDate.setDate(endDate.getDate() - 30);

const FilterComponent = ({
    onFilter,
    hideTimerFilter,
    onIsLoading,
    onClearFilter,
    installPointsList,
    sectorsList,
    devicesList,
    tagList,
    selectedSector,
    setSelectedSector,
    selectedDevices,
    setSelectedDevices,
    selectedTags,
    setSelectedTags,
    selectedDates,
    setSelectedDates,
    selectedTypeSensor,
    setSelectedTypeSensor,
    devices,
    setDevices,
    onShowAda = () => {},
}) => {
    const intl = useIntl();
    const [isLoading, setIsLoading] = useState(false);
    const toast = React.useRef(null);
    const [hideSomeFilter, setHideSomeFilter] = useState(true);
    const { setSectorNameSelected } = useCompanySectorStore();
    useEffect(() => {
        setHideSomeFilter(hideTimerFilter);
    }, [hideTimerFilter]);

    useEffect(() => {
        if (onIsLoading) {
            onIsLoading(isLoading);
        }
    }, [isLoading]);

    const handleSectorChange = (e) => {
        setSelectedSector(e.value);
        if (e.value) {
            setSectorNameSelected(e.value.sector_name);
        }
        updateDevicesAndTags(e.value, selectedTags);
    };

    const handleTagsChange = (e) => {
        setSelectedTags(e.value);
        updateDevicesAndTags(selectedSector, e.value);
    };

    const updateDevicesAndTags = (sector, tags) => {
        let filteredPoints = installPointsList;

        if (sector) {
            filteredPoints = filteredPoints.filter(point =>
                point.sectors.some(s => s.id === sector.sector_id)
            );
        }

        if (tags && tags.length > 0) {
            const tagIds = tags.map(tag => tag.id);
            filteredPoints = filteredPoints.filter(point =>
                point.tags.some(tag => tagIds.includes(tag.id))
            );
        }

        const updatedDevices = filteredPoints.flatMap(point =>
            point.devices.map(device => ({
                serial_number: device.serial_number,
                macrometer: device.macrometer
            }))
        );

        setDevices([...new Set(updatedDevices)]);

        const updatedDevicesSelecteds = filteredPoints.flatMap(point =>
            point.devices.map(device => device.serial_number)
        );

        setSelectedDevices(updatedDevicesSelecteds);
    };

    function findDeviceValues(groupedDevices, selectedSector) {
        if (!selectedSector || !selectedSector.sector_name) {
            return [];
        }

        const sectorName = selectedSector.sector_name;

        if (groupedDevices[sectorName]) {
            return groupedDevices[sectorName].map(device => device.value);
        }

        for (const devices of Object.values(groupedDevices)) {
            const matchingDevices = devices.filter(device => device.sector_name === sectorName);
            if (matchingDevices.length > 0) {
                return matchingDevices.map(device => device.value);
            }
        }

        return [];
    }

    function areArraysEqual(arr1, arr2) {
        if (arr1.length !== arr2.length) return false;

        const sortedArr1 = [...arr1].sort();
        const sortedArr2 = [...arr2].sort();

        return sortedArr1.every((item, index) => item === sortedArr2[index]);
    }

    const handleFilter = async () => {
        if (!selectedSector && selectedTags && selectedTags.length === 0 && selectedDevices && selectedDevices.length === 0) {
            toast.current.show({
                severity: 'warn',
                summary: intl.formatMessage({ id: "config_modal_intall_points_attention" }),
                detail: intl.formatMessage({ id: "home_plus_filter_apply_no_option" }),
                life: 3000,
            });
            return;
        }

        setIsLoading(true);

        try {
            const filters = {};
            const result = findDeviceValues(groupedDevices, selectedSector);
            const shouldShowAda = Boolean(selectedSector && areArraysEqual(result, selectedDevices));
            onShowAda(shouldShowAda);

            if (!areArraysEqual(result, selectedDevices)) {
                setSelectedSector(null);
                setSectorNameSelected(null);
            }

            if (selectedSector && areArraysEqual(result, selectedDevices)) {
                filters.sector_name = JSON.stringify({
                    criteria: "in",
                    values: [selectedSector.sector_name],
                });
            }

            if (selectedTags && selectedTags.length > 0) {
                filters.tag = JSON.stringify({
                    criteria: "in",
                    values: selectedTags.map(tag => tag.name.toLowerCase()),
                });
            }

            if (selectedDevices.length > 0) {
                filters.serial_number = JSON.stringify({
                    criteria: "in",
                    values: selectedDevices,
                });
            }

            if (selectedTypeSensor) {
                filters.type = JSON.stringify({
                    criteria: "equals",
                    values: selectedTypeSensor,
                });
            }

            const payload = {
                page: 1,
                per_page: 5000,
                ...filters,
            };

            const response = await listCompleteDevices(payload);
            if (onFilter) {
                onFilter(response.items);
            }
        } catch (error) {
            console.error('Erro ao filtrar dados:', error);
            toast.current.show({
                severity: 'error',
                summary: intl.formatMessage({ id: "config_modal_intall_points_attention" }),
                detail: intl.formatMessage({ id: "home_plus_filter_apply_title_error" }),
                life: 3000,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleClearFilters = () => {
        onClearFilter();
        setSelectedSector(null);
        setSectorNameSelected(null);
        setSelectedTags([]);
        setSelectedDevices([]);
        setSelectedDates([startDate, endDate]);
        const allDevices = installPointsList.flatMap(point =>
            point.devices.map(device => ({
                serial_number: device.serial_number,
                macrometer: device.macrometer
            }))
        );
        setDevices(allDevices);
    };

    const itemTemplateSector = (option) => {
        if (!option) {
            return null;
        }
        return (
            <div style={{ marginRight: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>{option.sector_name}</div>
                {option.hasMacrometer ? (
                    <img src={macroOffPurpple} height="25px" alt="Macrometer" />
                ) : (
                    <MdPinDrop key={option.sector_uid} size={23} color="#8086C0" alt="Macrometer" />
                )}
            </div>
        );
    };

    const selectedItemTemplateSector = (option, props) => {
        if (option) {
            return (
                <div style={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: 30 }}>{option.sector_name}</div>
                    {option.hasMacrometer ? (
                        <img src={macroOffPurpple} height="24px" alt="Macrometer" />
                    ) : (
                        <MdPinDrop key={option.sector_uid} size={24} color="#8086C0" alt="Macrometer" />
                    )}
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const selectedItemTemplateSerialNumber = (option) => {
        if (option) {
            return (
                <div style={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: 30 }}>{option?.value}</div>
                    {option.macrometer && (
                        <img src={macroOffPurpple} height="24px" alt="Macrometer" />
                    )}
                </div>
            );
        }

        return <span>{option?.value}</span>;
    };

    const itemTemplate = (option) => {
        if (!option) {
            return null;
        }

        const textColor = option?.desc.toLowerCase() === 'yellow' ? '#000' : '#fff';

        return (
            <div style={{ marginRight: 20 }}>
                <Chip label={option.name} style={{ backgroundColor: option.desc, color: textColor }} />
            </div>
        );
    };

    const selectedItemTemplate = (option) => {
        if (!option) {
            return null;
        }

        const textColor = option.desc.toLowerCase() === 'yellow' ? '#000' : '#fff';

        return (
            <>
                <Chip label={option.name} style={{ backgroundColor: option.desc, color: textColor, marginLeft: 10 }} />
            </>
        );
    };

    const handleDateChange = (dates) => {
        setSelectedDates(dates);
    };

    const typeSensor = [
        { label: intl.formatMessage({ id: "pressure" }), value: "pressure" },
        { label: intl.formatMessage({ id: "flow_title" }), value: "flow" },
    ];

    const groupedDevices = devicesList.reduce((acc, device) => {

        if (device.sectors.length === 0) {
            const noSectorKey = intl.formatMessage({ id: 'msg_input_filter_no_sector' });
            if (!acc[noSectorKey]) {
                acc[noSectorKey] = [];
            }
            acc[noSectorKey].push({
                label: device.serial_number,
                value: device.serial_number,
                macrometer: device.macrometer,
                sector_name: null,
                sector_id: null,
            });
        } else {

            device.sectors.forEach(sector => {
                const sectorKey = `${sector.sector_name}`;
                if (!acc[sectorKey]) {
                    acc[sectorKey] = [];
                }
                acc[sectorKey].push({
                    label: device.serial_number,
                    value: device.serial_number,
                    macrometer: device.macrometer,
                    sector_name: sector.sector_name,
                    sector_id: sector.sector_id,
                });
            });
        }
        return acc;
    }, {});

    const optionsListDevices = Object.keys(groupedDevices).map(sector => ({
        label: sector,
        items: groupedDevices[sector]
    }));

    return (
        <div className="p-grid p-fluid">
            <Toast ref={toast} />

            <FiltersTitleContainer>
                <FiltersTitle><FormattedMessage id="title_heat_map_filter" /></FiltersTitle>
            </FiltersTitleContainer>
            <DropdownGroup>
                <DropdownWrapper>
                    <DropdownLabel><FormattedMessage id="home_plus_filter_apply_title_sector" /></DropdownLabel>
                    <Dropdown
                        value={selectedSector || null}
                        options={sectorsList}
                        onChange={handleSectorChange}
                        placeholder={intl.formatMessage({ id: 'manager_alarms_plus_filter_by_sector' })}
                        filter
                        showClear
                        emptyFilterMessage={intl.formatMessage({ id: 'empty_filter_message' })}
                        filterPlaceholder={intl.formatMessage({ id: 'msg_input_filter_sector' })}
                        emptyMessage={intl.formatMessage({ id: 'empty_filter_message' })}
                        valueTemplate={selectedItemTemplateSector}
                        itemTemplate={itemTemplateSector}
                        optionLabel="sector_name"
                    />
                </DropdownWrapper>

                <DropdownWrapper>
                    <DropdownLabel><FormattedMessage id="home_plus_filter_apply_title_devices" /></DropdownLabel>
                    <MultiSelect
                        value={selectedDevices || []}
                        options={optionsListDevices}
                        onChange={(e) => setSelectedDevices(e.value || [])}
                        optionLabel="label"
                        optionGroupLabel="label"
                        optionGroupChildren="items"
                        filter
                        showClear
                        filterPlaceholder={intl.formatMessage({ id: 'msg_input_filter' })}
                        emptyFilterMessage={intl.formatMessage({ id: 'empty_filter_message' })}
                        placeholder={intl.formatMessage({ id: 'manager_alarms_plus_filter_by_device' })}
                        maxSelectedLabels={2}
                        className="w-full md:w-14rem"
                        itemTemplate={selectedItemTemplateSerialNumber}
                        selectedItemsLabel={`${selectedDevices?.length} ${intl.formatMessage({ id: 'msg_input_filter_id_device_items_selected' })}`}
                    /* virtualScrollerOptions={{
                        itemSize: 38
                    }} */
                    />
                </DropdownWrapper>

                <DropdownWrapper>
                    <DropdownLabel><FormattedMessage id="home_plus_filter_apply_title_tags" /></DropdownLabel>
                    <MultiSelect
                        value={selectedTags}
                        onChange={handleTagsChange}
                        options={tagList}
                        optionLabel="name"
                        filter
                        showClear
                        filterPlaceholder={intl.formatMessage({ id: 'msg_input_filter_tag' })}
                        emptyFilterMessage={intl.formatMessage({ id: 'empty_filter_message' })}
                        placeholder={intl.formatMessage({ id: 'manager_alarms_plus_filter_by_tag' })}
                        maxSelectedLabels={2}
                        display="chip"
                        itemTemplate={itemTemplate}
                        selectedItemTemplate={selectedItemTemplate}
                        selectedItemsLabel={`${selectedTags?.length} ${intl.formatMessage({ id: 'msg_input_filter_id_device_items_selected' })}`}
                        virtualScrollerOptions={{ itemSize: 43 }}
                    />
                </DropdownWrapper>

                {!hideSomeFilter && <DropdownWrapper>
                    <DropdownLabel><FormattedMessage id="home_plus_filter_apply_title_date_time_readings" /></DropdownLabel>
                    <DateRangePicker onDateChange={handleDateChange} />
                </DropdownWrapper>}

                {!hideSomeFilter && <DropdownWrapper>
                    <DropdownLabel><FormattedMessage id="home_plus_filter_apply_title_date_type_device" /></DropdownLabel>
                    <Dropdown
                        value={selectedTypeSensor}
                        onChange={(e) => setSelectedTypeSensor(e.value)}
                        options={typeSensor}
                        optionLabel="label"
                        placeholder={intl.formatMessage({ id: "manager_alarms_plus_select_alarm_type" })}
                        className="w-full md:w-14rem"
                        showClear
                    />
                </DropdownWrapper>}
            </DropdownGroup>
            <ButtonWrapper>
                <CleanFilterButton onClick={handleClearFilters}>
                    <FormattedMessage id="home_plus_filter_apply_title_clear_filter" />
                </CleanFilterButton>

                {isLoading ? (
                    <ApplyFilterButton style={{ padding: '10px 52px', display: 'flex', justifyContent: 'center' }}>
                        <ProgressSpinner style={{ width: '20px', height: '20px' }} />
                    </ApplyFilterButton>
                ) : (
                    <ApplyFilterButton onClick={handleFilter}>
                        <FormattedMessage id="home_plus_filter_apply_title_apply_filter" />
                    </ApplyFilterButton>
                )}
            </ButtonWrapper>
        </div>
    );
};

export default FilterComponent;