import styled from 'styled-components';
import { Dialog } from 'primereact/dialog';

export const CancelButton = styled.button`
  background-color: tomato;
  border-color: tomato;
  color: black !important;
  margin: 1rem 1rem !important;
  width: 12% !important;
  font-weight: 400;
  height: 42px;
  border-radius: 3px;

  &:hover {
    background-color: #ff0000;
  }
`;

export const CreateButton = styled.button`
  background-color: #6eff94;
  border-color: #6eff94;
  color: black !important;
  margin: 1rem 1rem !important;
  width: 12% !important;
  font-weight: 400;
  height: 42px;
  border-radius: 3px;

  &:hover {
    background-color: #45a049;
  }
`;

export const StyledDialog = styled(Dialog)`
  width: 50vw;
`;

export const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
  margin-bottom: 40px;
  min-height: 50px;
  padding: 10px;
`;

export const TagItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 15px;
  background-color: ${(props) => props.color};
  color: ${(props) => props.color === 'yellow' ? 'black' : 'white'};
  font-weight: bold;
`;

export const RemoveTagButton = styled.span`
  margin-left: 10px;
  cursor: pointer;
  font-weight: bold;
`;

export const InputContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 40px;
`;

export const ColorPickerContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;

export const ColorOption = styled.div`
  width: 60px;
  height: 30px;
  background-color: ${(props) => props.color};
  cursor: pointer;
  border: ${(props) => (props.selected ? '3px solid black' : 'none')};
`;

export const ButtonContainer = styled.div`
  margin-top: 50px;
  display: flex;
  align-content: center;
  justify-content: space-around;
`;