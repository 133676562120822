import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Title, CustomFormCheck, ExpandButton } from './styles';
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";

const CommandBar = ({
  kindPin,
  setKindPin,
  delta,
  setDelta,
  isolinhas,
  setIsolinhas,
  connectivity,
  setConnectivity,
  adaPlus,
  isTuring
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [showTitle, setShowTitle] = useState(false);


  useEffect(() => {
    if (isTuring) {
      setKindPin("pressure");
      setDelta(false);
      setConnectivity(false);
    }

    if (!isTuring) {
      setKindPin("value");
      setDelta(false);
      setConnectivity(false);
    }
  }, [isTuring, setKindPin, setDelta, setConnectivity]);

  useEffect(() => {
    let timeoutId;

    if (isExpanded) {
      timeoutId = setTimeout(() => {
        setShowTitle(true);
      }, 200);
    } else {
      setShowTitle(false);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isExpanded]);

  const handleRadioChange = (selectedKind) => {
    if (selectedKind === "hydraulic_load") {
      setKindPin("value");
      setDelta(false);
      setConnectivity(false);
    } else if (selectedKind === "delta_mnf") {
      setKindPin(null);
      setDelta(true);
      setConnectivity(false);
    } else if (selectedKind === "connectivity") {
      setKindPin(null);
      setDelta(false);
      setConnectivity(true);
    } else if (selectedKind === "pressure") {
      setKindPin("pressure");
      setDelta(false);
      setConnectivity(false);
    }
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Container $isExpanded={isExpanded}>
      <ExpandButton onClick={toggleExpand}>
        {isExpanded ? <MdArrowBackIosNew size={14} /> : <MdArrowForwardIos size={14} />}
      </ExpandButton>

      <Title $isExpanded={showTitle}>{<FormattedMessage id="settings" />}</Title>

      {adaPlus && !isTuring && (
        <CustomFormCheck
          type={'radio'}
          name={'kind'}
          label={<small>{showTitle && <FormattedMessage id="subtitle_connectivity" />}</small>}
          onClick={() => handleRadioChange("connectivity")}
          checked={connectivity}
          readOnly
          $isExpanded={isExpanded}
          tabIndex="-1"
        />
      )}

      {!isTuring && (
        <CustomFormCheck
          type={'radio'}
          name={'kind'}
          label={<small>{showTitle && <FormattedMessage id="hydraulic_load_title" />}</small>}
          onClick={() => handleRadioChange("hydraulic_load")}
          checked={kindPin === "value"}
          readOnly
          $isExpanded={isExpanded}
        />
      )}

      {!isTuring && (
        <CustomFormCheck
          type={'radio'}
          name={'kind'}
          label={<small>{showTitle && <FormattedMessage id="delta_mnf" />}</small>}
          onClick={() => handleRadioChange("delta_mnf")}
          checked={delta}
          readOnly
          $isExpanded={isExpanded}
        />
      )}

      {isTuring && (
        <CustomFormCheck
          type={'radio'}
          name={'kind'}
          label={<small>{showTitle && <FormattedMessage id="subtitle_connectivity" />}</small>}
          onClick={() => handleRadioChange("connectivity")}
          checked={connectivity}
          readOnly
          $isExpanded={isExpanded}
          tabIndex="-1"
        />
      )}

      {isTuring && (
        <CustomFormCheck
          type={'radio'}
          name={'kind'}
          label={<small>{showTitle && <FormattedMessage id="pressure_subtitle" />}</small>}
          onClick={() => handleRadioChange("pressure")}
          checked={kindPin === "pressure"} // Verifica se o estado é "pressure"
          readOnly
          $isExpanded={isExpanded}
        />
      )}

      {!isTuring && (
        <CustomFormCheck
          type={'checkbox'}
          name={'isolinhas'}
          label={<small>{showTitle && <FormattedMessage id="isolines" />}</small>}
          onClick={() => setIsolinhas(!isolinhas)}
          checked={isolinhas}
          readOnly
          $isExpanded={isExpanded}
        />
      )}
    </Container>
  );
};

export default CommandBar;